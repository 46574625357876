<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="main-bg">
          <div class="bg1">
              <h1 class="main-title">A / B 測試 - 網站轉化率</h1>
              <h3>項目背景</h3>
              <h6>PROJECT BACKGROUND</h6>
              <p class="bg1-p">公司非常注重自己的落地頁設計，希望通過改進設計來提高轉化率。以往該公司全年轉化率平均在13左右%，現在希望設計的新頁面能夠帶來更高的轉化率，希望新頁面的轉化率能有2%的提升，達到15%。在正式推出新頁面之前，AB Test在小范圍的用戶中進行測試，以確保新頁面的效果能夠達到預期目標。</p>
              <div class="bg1-image"></div>
              <br>
          </div>
          <div class="bg2">
              <h3>數據說明</h3>
              <h6>DATA DESCRIPTION</h6>
              <p><span class="Pbold">數據來源：</span> — —</p>
              <p><span class="Pbold">數據大小：</span> 294478條數據</p>
              <p><span class="Pbold">字段名稱描述：</span></p>
              <el-table
                :data="tableData"
                stripe
                border
                style="font-size: 18px;"
                class="tableStyle">
                <el-table-column
                  prop="name"
                  label="列名稱"
                  width="400">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="說明">
                </el-table-column>
              </el-table>
          </div>
          <div class="bg3">
              <h3>A / B 測試過程</h3>
              <h6>TEST PROCEDURE</h6>
              <h4>一、提出假設</h4>
              <div class="bg3-image"></div>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">原假設H0：</span>
                                          <span class="Pbold pbold-font">P</span>
                                          <span class="smallFontSize">new</span> &nbsp;=&nbsp; 
                                          <span class="Pbold pbold-font">P</span>
                                          <span class="smallFontSize">original</span>
              </p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">備擇假設H1：</span>
                                          <span class="Pbold pbold-font">P</span>
                                          <span class="smallFontSize">new</span> &nbsp;≠&nbsp; 
                                          <span class="Pbold pbold-font">P</span>
                                          <span class="smallFontSize">original</span>
              </p>
              <br>
              <p style="color:#666">其中， Pnew 代表新版落地頁的轉化率，Poriginal 代表新版落地頁的轉化率。</p>
              <br>
              <p>在本次的測試中，我們希望新頁面能帶來至少2%的轉化率提升。基於這個目標，<span class="Pbold">原則上應選擇單尾檢驗，更具體來說，是選擇右尾單尾檢驗，因為我們想證明新頁面的轉化率超過舊頁面。</span>
              <br><br>
                然而，考慮到我們無法完全確定新頁面的效果確實會優於現有頁面，因此，<span class="Pbold">作為一種謹慎的做法，我們決定先進行雙尾檢驗</span>，以綜合評估新頁面效果的可能性。</p>
              <br>
              <div class="p-bg">
                <p style="margin-bottom: 20px">在進行假設檢驗時，單尾檢驗和雙尾檢驗是兩種核心方法，它們主要的區別在於對假設方向性的不同處理方式：</p>
                <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">單尾檢驗：</span>此方法適用於研究者對假設有明確的預期方向，例如預測"新設計的落地頁轉化率超過原有設計"或"將按鈕顏色從灰色改為紅色會增加點擊次數"。單尾檢驗只關注一個方向上的統計顯著性，因此被稱為單側檢驗。</p>
                <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">雙尾檢驗：</span>當研究者對假設的具體方向沒有預設時，選擇雙尾檢驗。例如，當探索"新設計的落地頁與原設計是否有差異"或"改變頁面布局是否影響用戶滿意度"。這種方法需要同時考慮兩個方向的統計顯著性，因此被稱為雙側檢驗。</p>
              <p style="margin-top: 20px">在實施AB測試時，應根據實驗的目的、數據分布情況以及統計分析方法等多種因素來決定使用單尾檢驗還是雙尾檢驗。例如，如果目的是證明新設計的落地頁轉化率明顯高於舊設計，則適合選擇單尾檢驗。但如果不確定兩種設計之間是否存在顯著差異，則應該選擇雙尾檢驗。</p>
              </div>
              <br>
              <br>
              <br>
              <br>
              <h4>二、確定實驗分組</h4>
              <p>在本次的AB測試中，我們將參與者分為兩個組別：對照組與實驗組。</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">對照組 (control組)：</span>這一組用戶將看到舊版落地頁。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">實驗組 (treatment組)：</span>這一組用戶將看到新版落地頁。</p>
              <br>
              <br>
              <br>
              <br>
              <div class="bg3-image2"></div>
              <br>
              <br>
              <br>
              <br>
              <h4>三、計算實驗樣本量、試驗周期</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 导入需要的库
              import pandas as pd
              import numpy as np
              import scipy.stats as stats
              import statsmodels.stats.api as sms
              import seaborn as sns
              import matplotlib.pyplot as plt


              // 设置matplotlib的字体
              plt.rcParams['font.sans-serif'] = ['Microsoft YaHei']  # 微软雅黑的字体名
              plt.rcParams['axes.unicode_minus'] = False  # 用来正常显示负号

              import warnings
              warnings.filterwarnings('ignore')
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 根據我們的預期比率計算效果量
              effect_size = sms.proportion_effectsize(0.13, 0.15)
              effect_size

              // 計算所需最小樣本量
              required_n = sms.NormalIndPower().solve_power(
                  effect_size,
                  power=0.8, //統計功效 (1-beta)
                  alpha=0.05,
                  ratio=1, 
                  )

              // 打印結果
              required_n
                  </code>
              </pre>
              <br>
              <p>4719.4740575998185</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">試驗周期的確定</span><br><br>
                根據以上結果顯示，我們知道這次AB 測試至少需要9440個用戶參與測試，假如該落地頁以往每天的平均瀏覽量為1000，則實驗週期至少需要天數為：
              <br>
              <br>
              試驗周期 = 4720 x 2 / 1000 = 9.4 ≈ 10天
              </p>
          </div>
          <div class="bg4">
              <h3>數據分析及假設檢驗</h3>
              <h6>DATA ANALYSIS & HYPOTHESIS TESTING</h6>
              <h4>一、導入數據集</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 導入數據集
              df = pd.read_csv('./ab_data.csv')

              // 查看數據
              df.head()
                  </code>
              </pre>
              <br>
              <div class="bg4-image"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看數據整體信息
              df.info()
                  </code>
              </pre>
              <br>
              <pre>
              'pandas.core.frame.DataFrame'>
              RangeIndex: 294478 entries, 0 to 294477
              Data columns (total 5 columns):
              #    Column                        Non-Null Count          Dtype 
              ---  ----------                        ----------------------        ---------- 
              0   user_id                         294478 non-null          int64 
              1   timestamp                  294478 non-null          object 
              2   group                            294478 non-null          object
              3   landing_page            294478 non-null          object 
              4   converted                   94478 non-null             int64 
              dtypes: int64(2), object(3)
              memory usage: 11.2+ MB
              </pre>
              <br>
              <br>
              <br>
              <h4>二、數據清洗</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 檢查缺失值並處理
              df.isnull().sum()
                  </code>
              </pre>
              <pre>
              user_id                       0
              timestamp                0
              group                          0
              landing_page          0
              converted                 0
              dtype: int64
              </pre>
              <br>
              <p>可以看到，沒有任何缺失值。</p>
              <br>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 檢查重複值並處理
              df.duplicated().sum()
                  </code>
              </pre>
              <br>
              <p>0</p>
              <br>
              <p>可以看到，沒有任何重複的數據需要處理。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 檢查用戶ID是否有重復值
              df[df['user_id'].duplicated()]
                  </code>
              </pre>
              <br>
              <div class="bg4-image2"></div>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看其中一位重復用戶
              df[df['user_id']==899953]
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image3"></div>
              <br>
              <p>通過以上兩個表格，可以發現共有3894條數據出現重複，隨機抽查重複的用戶可以看到同一個用戶被分派到2個不同的組，這些重複的數據會干擾後續的判斷，所以會進行刪除。</p>
              <br>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 儲存所有的重複用戶ID
              del_id = df[df['user_id'].duplicated()]['user_id'].values

              // 刪除重複用戶ID，並創建新的數據集
              df_new = df[~df['user_id'].isin(del_id)]

              //打印新的數據集
              df_new
                  </code>
              </pre>
              <br>
              <div class="bg4-image4"></div>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 處理時間格式,並只保留年-月-日
              df_new['timestamp'] = pd.to_datetime(df_new['timestamp'], format='%Y-%m-%d').dt.strftime('%Y-%m-%d')
              df_new
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image5"></div>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看一共有多少天的數據
              len(df_new['timestamp'].unique())
                  </code>
              </pre>
              <br>
              <p>23</p>
              <br>
              <p>共有23天的測試數據，符合實驗週期至少10天的數據需求。</p>
              <br>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 確保control組每個用戶看到的都是舊頁面，treatment組看到的是新頁面
              pd.crosstab(df_new['group'],df_new['landing_page'])
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image6"></div>
              <br>
              <p>到這裡，所有的數據清洗工作已經完成。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>三、假設檢驗</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 導入統計庫
              from statsmodels.stats.proportion import proportions_ztest, proportion_confint
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 分別獲取對照組和實驗組的轉化結果
              control_results = df_new[df_new['group'] == 'control']['converted']
              treatment_results = df_new[df_new['group'] == 'treatment']['converted']

              // 獲取每一組的數量
              n_con = control_results.count()
              n_treat = treatment_results.count()
              successes = [control_results.sum(),treatment_results.sum()]
              nobs = [n_con, n_treat]
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 分別計算 Z-value 和 P-value
              z_stat, p_val = proportions_ztest(successes, nobs=nobs)
              (lower_con, lower_treat), (upper_con, upper_treat) = proportion_confint(successes, nobs=nobs, alpha=0.05)

              print(f'Z分數：{z_stat:.2f}')
              print(f'P-Value：{p_val:.3f}')
              print(f'對照組的95%置信區間：[ {lower_con:.3f},{upper_con:.3f} ]')
              
              print(f'實驗組的95%置信區間：[ {lower_treat:.3f},{upper_treat:.3f} ]')
                  </code>
              </pre>
              <br>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">Z分數：</span>1.19</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">P-Value：</span>0.232</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">對照組的95%置信區間：</span>[ 0.118,   0.122 ]</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">實驗組的95%置信區間：</span>[ 0.117,   0.120 ]</p>
          </div>
          <div class="bg5">
            <h3>四、分析結果及建議</h3>
            <div class="bg5-image"></div>
            <br>
            <p>Z分數為1.19，而P值為0.232。由於P值遠高於顯著水平α=0.05，我們無法拒絕原假設H0。這意味著在統計學意義上，新版與舊版落地頁之間沒有顯著的差異。</p>
            <br>
            <p>另外，通過看實驗組（新版落地頁）的95%置信區間為 [ 0.117,   0.120 ]，可以看出：</p>
            <br>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;它包括我們的轉化率基准線13%</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;它不包活我們的轉化目標值15%</p>
            <br>
            <p>這一區間不僅未達到我們設定的轉化率目標15%，甚至也低於我們的轉化率基準線13%。這表明，相對於原始假設，新版落地頁的實際效果可能不如預期，轉化率提升的目標未能達成。</p>
            <br>
            <p>綜上所述，從這次的數據分析來看，新版落地頁的效果並不明顯，未能證實其對轉化率有正面提升。此結果提示我們，新版設計在目前形式下不足以達到預期的商業效益增長目標。因此，建議對新版落地頁進行進一步的優化和測試，或者探索其他提升轉化率的策略。同时，重要的是要進行更多的測試和數據收集，以更準確地評估不同變量對轉化率的影響。</p>
          </div>
      </div>
      <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
        tableData: [{
          name: 'user_id',
          Describe: '用戶ID'
        }, {
          name: 'timestamp',
          Describe: '時間'
        }, {
          name: 'group',
          Describe: '用戶分組情況（新版落地頁為treatment組，舊版落地頁為control組)'
        }, {
          name: 'landing_page',
          Describe: '每位用戶看到的落地頁（分為新舊兩版落地頁）)'
        }, {
          name: 'converted',
          Describe: '是否成功轉換，（1代表成功轉化，0代表未轉化）'
        }],
      };
    },
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 28px;
        margin-top: 40px;
        font-weight: 500;
        }
      h4{
        font-family: 'HuaKangFangYuan';
        font-size: 26px;
        font-weight: 500;
        color: #444;
        margin-bottom: 70px;
        }
      h5{
        font-family: 'HuaKangFangYuan';
        font-size: 24px;
        font-weight: 500;
        color: #444;
        margin-bottom: 50px;
        }
      h6{
        font-family: 'HuaKangFangYuan';
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;
        color: #666;
        font-weight: 500;
      }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p, pre {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin:10px 0;
        line-height: 32px;
        white-space: pre-wrap; /* 保持换行和空格，同时允许自动换行 */
        color: #444444;
      }
      .p8d{
        color: #8d8d8d;
      }
      .p16 {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        color: #333;
        padding: 20px;
        background-color: #F8F8F8;
        margin: 20px 0 ;
      }
      .Pbold{
            font-weight: 900;
      }
      .pbold-font{
        font-family: 'AaErZhiYuan';
      }
      .smallFontSize{
        font-size: 12px;
        color: #666;
      }
      .bg-p{
              margin-top: 30px;
              width: 100%;
      }
      .p-bg {
          position:relative;
          background-color: #fff; /* 主要背景颜色 */
          margin-top: 40px;
          margin-left: 50px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #e0dfe1;
            }
          }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      margin: 0 auto;
    }
.bg1{
  .bg1-image{
      width: 100%;
      height: 400px;
      margin: 120px 0;
      background-image: url('./assets/bg1-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
}
.bg2{
    margin-top: 120px;
    .tableStyle{
      width: 80%;
      margin-top:30px;
      font-size: 16px;
    }
}
.bg3{
    margin-top: 120px;
    .bg3-image{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 400px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
    .bg3-image2{
      width: 100%;
      height: 500px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
    
}
.bg4{
    margin-top: 120px;
    .bg4-image{
      width: 100%;
      height: 190px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image2{
      width: 100%;
      height: 520px;
      margin-bottom: 30px;
      background-image: url('./assets/bg4-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image3{
      width: 100%;
      height: 125px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image4{
      width: 100%;
      height: 516px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image4.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image5{
      width: 100%;
      height: 544px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image5.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image6{
      width: 100%;
      height: 160px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image6.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg5{
  margin-top: 120px;
  .bg5-image{
      width: 100%;
      height: 300px;
      margin-top: 100px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
}

  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>