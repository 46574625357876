<template>
    <div class="bodyContainer">
        <back :showButton="true" />
        <div class="main-bg">
            <div class="bg1">
                <h1 class="main-title">淘寶用戶行為分析</h1>
                <h3>項目背景</h3>
                <h6>PROJECT BACKGROUND</h6>
                <p class="bg1-p">在當前成熟的移動互聯網市場中，粗放型的增長策略已不再有效。我們需採用更精細化的市場營銷策略。這包括深入研究市場趨勢和消費者偏好，進行用戶行為數據分析，並以此為基礎來設計更加個性化的用戶互動和推廣活動。所以，我使用Python對淘寶的用戶數據進行分析，目的是從中發現可行的業務洞察，進一步形成符合用戶需求的營銷策略，以提升用戶體驗並增強客戶忠誠度。</p>
                <div class="bg1-image"></div>
                <br>
                <br>
                <br>
                <br>
                <h3>分析步驟</h3>
                <h6>STEPS</h6>
                <div class="bg1-image2"></div>
            </div>
            <div class="bg2">
                <h3>提出問題</h3>
                <h6>QUESTIONS</h6>
                <h3 class="bg2-title2">一、分析業務問題</h3>
                <p> 1.&nbsp;&nbsp;分析客戶從瀏覽到購買的轉化過程，識別中途流失的關鍵環節，以制定策略提升轉化率。<br>
                    2.&nbsp;&nbsp;調查特定時間段內用戶活躍的日子和時段，以了解其購物習慣，並進行針對性的推廣。<br>
                    3.&nbsp;&nbsp;分析哪些商品類別和單品的銷量最好，從而優化庫存和銷售策略。<br>
                    4.&nbsp;&nbsp;確定核心重複購買用戶群，分析他們的購買偏好，並提供個性化的商品推薦。</p>
                <br>
                <h3 class="bg2-title2">二、基於AARRR漏斗模型分析總結用戶行為</h3>
                <p>採用AARRR模型深入分析用戶在應用各階段的行為和流失點，從而優化用戶體驗。透過這種方式，可以精確地掌握用戶從進入應用到最終行動的全過程，幫助改善各個階段的用戶留存。</p>
                <br>
                <br>
                <br>
                <br>
                <h3>數據說明</h3>
                <h6>DATA DESCRIPTION</h6>
                <p><span class="Pbold">數據來源：</span>阿里雲天池</p>
                <p><span class="Pbold">數據大小：</span>約一億條數據（接近一百萬隨機用戶的行為）</p>
                <p><span class="Pbold">行為類型：</span>點擊、喜歡、加購物車、購買</p>
                <p><span class="Pbold">數據格式：</span>用戶ID、商品ID、商品類目ID、行為類型和時間戳</p>
                <p><span class="Pbold">字段名稱描述：</span></p>
                <br>
                <el-table
                  :data="tableData1"
                  stripe
                  border
                  style="font-size: 18px;"
                  class="tableStyle">
                  <el-table-column
                    prop="name"
                    label="列名稱"
                    width="400">
                  </el-table-column>
                  <el-table-column
                    prop="Describe"
                    label="說明">
                  </el-table-column>
                </el-table>
                <br>
                <br>
                <p>請注意，用戶行為類型共有4種，他們分別是：</p>
                <br>
                <br>
                <el-table
                  :data="tableData2"
                  stripe
                  border
                  style="font-size: 18px;"
                  class="tableStyle">
                  <el-table-column
                    prop="name"
                    label="列名稱"
                    width="400">
                  </el-table-column>
                  <el-table-column
                    prop="Describe"
                    label="說明">
                  </el-table-column>
                </el-table>
                <br>
                <br>
                <p>關於數據集大小的一些說明如下：</p>
                <br>
                <br>
                <el-table
                  :data="tableData3"
                  stripe
                  border
                  style="font-size: 18px;"
                  class="tableStyle">
                  <el-table-column
                    prop="name"
                    label="列名稱"
                    width="400">
                  </el-table-column>
                  <el-table-column
                    prop="Describe"
                    label="說明">
                  </el-table-column>
                </el-table>
                <br>
                <br>
            </div>
            <div class="bg3">
                <br>
                <br>
                <h3>數據清洗</h3>
                <h6>Data Cleansing</h6>
                <h5 class="bg3-title">1. 讀取數據</h5>
                <p>讀取數據集一億條數據：</p>
                <br>
                <br>
                <pre v-highlight>
                  <code class="lang-javascript">
                // 導入所需的庫
                import pandas as pd

                // 讀取數據集並修改列名
                df = pd.read_csv('./UserBehavior.csv',header=None)
                df = df.sample(n=100000000, random_state=8)
                df.columns = ['用戶ID', '商品ID', '商品類目ID', '行為類型', '時間']
                df['時間'] = pd.to_datetime(df['時間'], unit='s') 

                // 展示數據
                df
                  </code>
              </pre>
                <div class="bg2-step3-image1"></div>
                <h5 class="bg3-title title2Style">2. 新增字段</h5>
                <p>新增2個時間有關的字段（日期、小時），方便之後進行分析：</p>
                <br>
                <br>
                <pre v-highlight>
                  <code class="lang-javascript">
                df['日期'] = df['時間'].dt.date
                df['小時'] = df['時間'].dt.hour
                // 首先确保'日期'列是datetime类型
                df['日期'] = pd.to_datetime(df['日期'])
                df
                  </code>
              </pre>
                <div class="bg2-step3-image2"></div>
                <h5 class="bg3-title title2Style">3. 異常值處理</h5>
                <p>檢查數據是否在規定的日期內（2017年11-25至2017年12月03），和每一個字段中是否包含na缺失值：</p>
                <div class="bg2-step3-image3"></div>
                <p class="bg3-p3">發現有異常日期，共刪除1,234,371條數據：</p>
                <br>
                <br>
                <pre v-highlight>
                  <code class="lang-javascript">
                df = df[(df['日期']>='2017-11-25') & (df['日期'] &lt='2017-12-03')]
                df
                  </code>
              </pre>
                <div class="bg2-step3-image4"></div>
            </div>
            <div class="bg4">
                <h5 class="bg3-title">4. 構建模型</h5>
                <h5 >分析業務問題</h5>
                <P class="p-bg"><span class="Pbold">4.1 解決問題：</span>分析客戶從瀏覽到購買的轉化過程，識別中途流失的關鍵環節，以制定策略提升轉化率。</p>
                <p class="Pbold bg4-p1">4.1.1）訪客數UV、訪問量PV、平均訪問量PV/UV</p>
                <br>
                <pre v-highlight>
                  <code class="lang-javascript">
                // 計算UV，即獨立訪客數，使用nunique()函數來計算不同的使用者ID數量
                UV = df['用戶ID'].nunique()
                // 計算PV，即行為類型為'pv'的記錄數量
                PV = df['行為類型'].value_counts()['pv']
                // 計算PV/UV，即每位獨立訪客的平均訪客量
                PV_per_UV = round(PV / UV,2)

                // 輸出結果
                print(f"訪客數UV: {UV}")
                print(f"訪問量PV: {PV}")
                print(f"平均訪問量PV/UV: {PV_per_UV}")
                    </code>
                </pre>
                <br>
                <pre>
                訪客數UV: 987984
                訪問量PV: 88463340
                平均訪問量PV/UV: 89.54
                </pre>
                <p class="Pbold">4.1.2）跳失率（只有點擊行為的用戶/總用戶數）</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                // 按'用戶ID'分組，並收集每個用戶的所有行為類型
                user_behaviors = df.groupby('用戶ID')['行為類型'].apply(set).reset_index(name='行為類型')
                // 篩選出行為類型只包含'pv'的用戶
                only_pv_users = user_behaviors[user_behaviors['行為類型'] == {'pv'}]
                // 計算只有'pv'行為的使用者個數
                only_pv_user_count = len(only_pv_users)

                // 計算UV，即獨立訪客數，使用nunique()函數來計算不同的使用者ID數量
                UV = df['用戶ID'].nunique()
                //跳失率
                PVUV = round(only_pv_user_count / UV*100,2)

                // 輸出結果
                print(f"只有點擊行為用戶數: {only_pv_user_count}")
                print(f"總用戶數: {UV}")
                print(f"跳失率: {PVUV}%")
                    </code>
                </pre>
                <br>
                <pre>
                只有點擊行為用戶數: 60553
                總用戶數: 987984
                跳失率: 6.13%
                </pre>
                <p  class="bg4-p1">結果顯示只有點擊行為卻沒有收藏、加入購物車、購買行為的用戶數是60553，除以總用戶數987984，則跳失率為6.13%。</p>
                <p class="Pbold bg4-p1">4.1.3）用戶總行為漏斗</p>
                <div class="bg2-step3-image7"></div>
                <p  class="bg4-p1">因為收藏和加購物車這兩招其實都是用戶在看了一眼之後，心裏有點動心想買的表現，而且這兩個動作做先做後其實也沒啥固定順序，所以咱們就把它們算在同一階段好了。從數據來看，從隨便逛逛到心動想買的轉化率只有9.38%，當然也不是說所有人都得先收藏或加購物車才買，有的人是看一眼就直接買了。但這還是顯示了大部分用戶其實逛得挺勤，就是用收藏或加購物車這兩個功能的比較少。再來，真正從收藏或加購物車到買單的比例大概在23.81%左右，這說明了從逛到真的動心，然後再到決定收藏或加購物車，這一階段是我們提高指標的關鍵環節。</p>
                <br>
                <br>
                <br>
                <P class="p-bg"> <span class="Pbold">4.2 解決問題：</span>調查特定時間段內用戶活躍的日子和時段，以了解其購物習慣，並進行針對性的推廣。</p>
                <br>
                  <p class="Pbold bg4-p1">4.2.1） 每日活躍點擊量</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                // 分別查看每一天的數量
                daily_pv = df.groupby('日期').size().reset_index(name='pv量')

                // 格式化日期数据，只保留年月日
                daily_pv['日期'] = daily_pv['日期'].dt.strftime('%Y-%m-%d')
                    </code>
                </pre>

                <div class="bg2-step3-image9"></div>
                <p  class="bg4-p1">從圖上我們可以看到，從11月25日到12月1日，點擊量基本保持平穩。但到了12月2日，點擊量就突然上去了，增長了大概25%。這可能是因為上班族平時忙工作，逛淘寶的時間少，等到了周末（12月2日到12月3日），有了充裕的時間和精力，就多上淘寶逛逛。所以，如果平時要搞活動的話，把重頭戲安排在周末可能會更有效。</p>
                <p class="Pbold bg4-p1">4.2.2） 每小時活躍點擊量</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                      hour_pv = df.groupby('小時').size().reset_index(name="pv量")
                    </code>
                </pre>

                <div class="bg2-step3-image10"></div>
                <p  class="bg4-p1">通過上圖，我們可以觀察到從11點開始，點擊量有顯著的上升趨勢，並在13點到14點達到了一天中的最高值，這可能表示用戶喜歡在午餐休息時逛淘寶。<br><br>至於晚上的活動，從21點開始點擊量逐漸上升，直到2點前後形成一個次高點。這樣的趨勢顯示了除了中午的活躍期之外，晚上下班後的時間段也是用戶活躍的高峰期。因此，應該在這兩個時間段內進行重點推廣和營銷活動，以吸引更多用戶的注意力。</p>
                <br>
                <br>
                <br>
                <P class="p-bg"> <span class="Pbold">4.3 解決問題：</span>分析哪些商品類別和單品的銷量最好，從而優化庫存和銷售策略。</p>
                <p class="Pbold bg4-p1">4.3.1） 瀏覽次數、收藏次數、加入購物車次數，以及購買次數最多的商品：</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                df_pv = df[df['行為類型']=="pv"].groupby('商品ID').size().reset_index(name="pv量")
                sort_pv = df_pv.sort_values(by="pv量", ascending=False) //按照從大至小排序
                top20_pv = sort_pv.head(20) // 顯示Top20商品ID
                top20_pv = top20_pv.sort_values(by="pv量", ascending=True)
                    </code>
                </pre>
                <div class="bg2-step3-image12"></div>
                <p  class="bg4-p1">在賣得最好的商品榜上，我們沒看到那些瀏覽量排名靠前的商品。這就說明，雖然這些商品很會吸引人，但它們並沒有很好地轉換成實際的銷量，而只是多被加進了收藏夾裡（那些瀏覽量排在前面的商品，也都在收藏量的前幾名，說明瀏覽量跟收藏量之間的關係更直接一些）。</p>
                <p class="Pbold bg4-p1">4.3.2） 查看特別受歡迎的商品類別：</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                df_buy = df[df['行為類型'] == "buy"]
                df_purchase = df_buy.groupby('商品類目ID').size().reset_index(name="購買次數")
                df_purchase_sort = df_purchase.sort_values(by="購買次數", ascending=False)
                df_purchase_top20 = df_purchase_sort .head(20).sort_values(by="購買次數", ascending=True)
                    </code>
                </pre>

                <div class="bg2-step3-image14"></div>
                <p  class="bg4-p1">1. 根據左圖趨勢來看：購買次數從第一個商品類目開始逐漸下降。這表明前幾個商品類目的購買次數遠高於其他類目，也就是說，在這7090個商品類目中，有一小部分類目非常熱門，它們的購買次數顯著高於其他類目。數據遵循長尾分佈。<br><br>
                                    2. TOP20的商品類目顯示出不同程度的銷售集中趨勢，這些數據反映了消費者對這些類目的強烈偏好。<br><br>
                                    <br>
                                    <br>
                                    <span class="Pbold">策略建議：</span><br>
                                        1. 對於銷量最高的類目，進行庫存和促銷優化，保證供應並最大化銷售潛力。<br>
                                        2. 分析其他熱門類目的消費者購買行為和偏好，定制個性化營銷活動。<br>
                                        3. 推行交叉推銷和捆綁銷售策略，提高每筆交易的價值。<br>
                                        4. 關注顧客反饋，持續改進產品質量和用戶體驗。<br>
                                        5. 定期分析銷售數據，把握市場趨勢，快速響應市場變化。</p>   
                                    <br>         
                                    <br>         
                <P class="p-bg"><span class="Pbold">4.4 解決問題：</span>確定核心重複購買用戶群，分析他們的購買偏好，並提供個性化的商品推薦。</p>
                <br>
                  <p class="Pbold bg4-p1">4.4.1） 查看整體回購率情況：</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                count_userid = df_buy.groupby('用戶ID').size().reset_index(name="購買次數")
                Repurchase_rate = count_userid.groupby('購買次數').size().reset_index(name="用戶數")
                Repurchase_rate.head(20)
                Repurchase_rate
                    </code>
                </pre>
                <div class="bg2-step3-image16"></div>
                <p  class="bg4-p1 YBG">從上圖我們可以看出，回購率大概為（670049-229327）/ 670049 = 65.77%，意思就是在所有買過東西的用戶裡面，有差不多65.77%的人會再次回來購買。不過，這張圖也顯示了大部分的買家其實只買一次。</p>
                <br>
                <p class="Pbold bg4-p1">4.4.2） 產品種類的累計銷售情況：</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                // 統計商品類目ID佔比
                df_purchase_sort['商品類目個數'] = 1
                df_purchase_sort['商品類目累計個數'] = df_purchase_sort['商品類目個數'].cumsum()
                total_category_count = df_purchase_sort['商品類目個數'].sum()
                df_purchase_sort['累計類目個數佔比'] = round(100 * df_purchase_sort['商品類目累計個數'] / total_category_count,1)

                // 統計商品ID佔比
                df_purchase_sort['累計購買次數'] = df_purchase_sort['購買次數'].cumsum()
                total_count = df_purchase_sort['購買次數'].sum()
                df_purchase_sort['累計購買次數佔比'] = round(100 * df_purchase_sort['累計購買次數'] / total_count,1)
                    </code>
                </pre>
                <div class="bg2-step3-image18"></div>
                <p  class="bg4-p1 YBG">我們可以看到一個明顯的趨勢：20%的商品類目貢獻了近90%的銷售量。這種現象在經濟學中被稱為帕累托原則，或者更常見的說法是"二八法則"，意味著少數的類目（20%）佔了大部分（90%）的銷售量。這表明平台上有一小部分非常熱門的商品類目，這些類目非常受消費者歡迎，對總銷售額貢獻巨大。<br><br>從圖表上的分佈來看，隨著商品類目百分比的增加，銷售量的增加速度逐漸減慢，直至達到一個穩定的狀態。這顯示出在剩餘的80%的商品類目中，每個類目的貢獻相對較小，這些類目可能只是各自小範圍內的熱門商品，或者是針對特定小眾市場的商品。</p>
                <br>
                <p class="Pbold bg4-p1">4.4.3） 找出回購率最高的用戶，以及他們購買的產品：</p>
                <br>
                <pre v-highlight>
                    <code class="lang-javascript">
                vip_user = count_userid.sort_values(by="購買次數",ascending=False)
                vip_user.head(10)
                    </code>
                </pre>
                <div class="bg2-step3-image20"></div>
                <p  class="bg4-p1 YBG bg4-pRight">從Python查詢的結果來看，用戶ID為486458的這位朋友買得最多，總共買了262次。接下來我們就拿這個買得最多的用戶來做個例子，深入研究一下。</p>
                <pre v-highlight>
                    <code class="lang-javascript">
                top1_user = df_buy[df_buy['用戶ID'] == 486458]  // 找出用戶ID=486458的用戶資料
                count_items = top1_user.groupby('商品類目ID').size().reset_index(name="次數")  // 統計各商品類目購買次數
                sort_counts = count_items.sort_values(by="次數",ascending=False)
                sort_counts
                    </code>
                </pre>
                <div class="bg2-step3-image21"></div>
                <p  class="bg4-p1 YBG bg4-p1Right">從數據來看，回購率最高的用戶，也就是用戶ID為337305的這位，他買的東西主要是表格裡排名前三的那幾類商品。我們可以根據這些商品類目的ID來了解他更傾向於買哪些類型的商品。<br><br>這樣對特定用戶深挖一番，能幫助我們更好地認識和發現那些真正值錢的顧客。如果數據裡面還提供了商品價格的信息，那我們就能更容易地找出哪些是花錢多的高價值用戶。瞭解這些高價值用戶的購買習慣，像是他們什麼時候買、買什麼產品、喜歡哪些品類，就能推出更對症下藥的產品推薦，用個性化的推薦來提升銷售成績。</p>
            </div>
            <div class="bg5">
                <h3 class="bg4-p1 bg5-maginAdjyst">總結與建議</h3>
                <h6>CONCLUSION AND SUGGESTION</h6>
                <p>在這次的分析中，我們利用Python處理了一億條淘寶用戶行為數據，並運用AARRR漏斗模型深入剖析了用戶行為。根據前面分析出來的結果，現在有以下一些建議：</p>
                <h5 class="bg4-p1">1. 獲取客戶（ Acquisition ）：市場匹配和渠道產品匹配。</h5>
                <p>每天11：00 到 14：00 點，是用戶上淘寶特別頻繁訪問的時間，這時段也正是拉攏新顧客的好機會，做活動招新顧客，就應該選在這個時候。<br><br>淘寶用戶那麼多，完全可以用用戶自己轉發的方式來吸引新的顧客。比如說中午搞個促銷活動，讓人叫上朋友一起來拼團買東西，這樣可以增加顧客量，用口碑來吸引新顧客是挺合適的。還可以搞搞小遊戲、找網紅推一推，或者和那些熱門的社交平台和短視頻平台合作，通過淘寶app上的賣家推送功能來推廣。</p>
                <h5 class="bg4-p1">2. 激活用戶（ Activation ）：先要弄懂用戶心裡那個“啊哈”的瞬間。用戶從逛逛到最後掏錢的過程中，到底哪裡容易流失，找出那些關鍵點，再來想想怎麼提升成交率。</h5>
                <p>用戶的動作包括點擊、加購物車、收藏，以及最終購買。點擊的動作佔了總行為的89.6%，而加購物車和收藏才6%，真正買的只有2%。問題就出在加購物車和收藏這兩塊，用戶可能是花了不少時間找不到真正喜歡的東西。根據這些分析，我們可以提出以下建議來提高成交率：<br><br>（1）改善淘寶平台的篩選功能，提升關鍵詞搜索的準確性，讓用戶更容易找到他們想要的商品；<br>（2）提供商品比較功能，這樣用戶就不用來回點來點去，比來比去，更直接地選出他們想要的商品；<br>（3）簡化下單的步驟，做到一鍵下單，比如整個流程就三步：點擊、買單、支付，縮短購買流程，提升用戶體驗。</p>
                <h5 class="bg4-p1">3. 留住用戶（ Retention ）：讓用戶養成常來淘寶逛逛的習慣。</h5>
                <p>要讓用戶保持使用淘寶的習慣是提高留存率的關鍵，可以考慮的方案有：<br><br>（1）設個積分系統，用戶依照他們上線的頻率和購買的次數來累積積分。比如說，每天上淘寶點擊的次數達到一定數量，就能自動拿到積分，月底還能用積分換購物券。<br>（2）對那些一年買的次數和金額達標的客戶，提供VIP服務，比如全場商品隨時享受9.5折。買的越多，享受的優惠越大，這樣就能讓那些花錢多的高價值用戶更粘你的平台，提升他們的忠誠度。</p>
                <h5 class="bg4-p1">4. 獲取收入（ Revenue ）：提升成交率、回購率和商品類別的銷售量。</h5>
                <p>在有過購買的用戶中，大約有65.77%的人會再次購買。在所有用戶中，回購次數最多的達到262次。我們可以通過分析回購率和購買金額（這次數據沒有提供這個）來找出那些真正值錢的顧客，然後根據他們的購買喜好來做個性化的商品推薦，提升用戶體驗和平台的銷售業績。<br><br>
                20%的商品類目貢獻了近90%的銷售量。這種現象被稱為"二八法則"，意味著少數的類目（20%）佔了大部分（90%）的銷售量。這表明平台上有一小部分非常熱門的商品類目，這些類目非常受消費者歡迎，對總銷售額貢獻巨大。<br><br>
                從這些數據來看，淘寶平台最大的優勢就是商品種類和類別特別豐富，能滿足各種不同類型顧客的需求，所以我們要繼續保持這個優勢。提升方案可以考慮：<br><br>
                （1）內容營銷：用“淘寶上沒有找不到的商品，只有你想不到的”來宣傳，讓用戶養成“買東西就上淘寶”的思維習慣；<br>（2）針對前面確定的高價值用戶，提供他們最關心的商品類別和種類的個性化推薦，比如新品上市就及時通知他們；<br>（3）為了提升回購率，可以出個三個月內回購有優惠的活動，激勵顧客保持購買頻率。</p>
                <h5 class="bg4-p1">5. 推薦（Refer）：讓用戶向他人推薦。</h5>
                <p>針對淘寶平台，我們可以採取以下方案：<br><br>
                （1）在購買產品時提供拼團服務，鼓勵用戶主動推薦給其他人；<br>（2）每當推出新功能，例如前述的一鍵下單，讓已體驗的用戶分享並獲得優惠券，快速推廣新功能；<br>（3）當用戶使用優惠券購物或通過某些行為獲得積分購物後，提供朋友圈打卡功能，分享給好友，實現傳播效應。</p>
            </div>
        </div>
        <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/MLBook/icon1.png'),
                name:'客戶流失預測',
                content:'透過分析客戶互動、購買歷史和行為模式，識別可能離開的客戶。',
              },
              {
                id: 2,
                icon:require('@/assets/MLBook/icon2.png'),
                name:'銷售額預測',
                content:'基於歷史數據和市場趨勢，預測未來的銷售額。',
              },
              {
                id: 3,
                icon:require('@/assets/MLBook/icon3.png'),
                name:'價格預測',
                content:'透過分析競爭對手定價、市場需求和成本變化來預測產品的最佳價格點。',
              },
              {
                id: 4,
                icon:require('@/assets/MLBook/icon4.png'),
                name:'風險預測',
                content:'評估潛在的市場風險、信貸風險或運營風險，減少損失的可能性，確保企業穩健運營。',
              },
            ],
            tableData1: [{
              name: '用戶ID',
              Describe: '整數類型，序列化後的用戶ID'
            }, {
              name: '商品ID',
              Describe: '整數類型，序列化後的商品ID'
            }, {
              name: '商品類目ID',
              Describe: '整數類型，序列化後的商品所屬類目ID'
            }, {
              name: '行為類型',
              Describe: '字符串，枚舉類型，包括（pv, buy, cart, fav)'
            }, {
              name: '時間戳',
              Describe: '行為發生的時間戳'
            }],
            tableData2: [{
              name: 'pv',
              Describe: '商品詳情頁pv，等價於點擊'
            }, {
              name: 'buy',
              Describe: '商品購買'
            }, {
              name: 'cart',
              Describe: '將商品加入購物車'
            }, {
              name: 'fav',
              Describe: '收藏商品'
            }],
            tableData3: [{
              name: '用戶數量',
              Describe: '987,994'
            }, {
              name: '商品數量',
              Describe: '4,162,024'
            }, {
              name: '用戶數量',
              Describe: '987,994'
            }, {
              name: '商品類目數量',
              Describe: '9439'
            }, {
              name: '所有行為數量',
              Describe: '100,150,807'
            }],
      }
  }
  }
  </script>
  <style lang="less" scoped>
    * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 28px;
        margin-top: 40px;
        font-weight: 500;
        }
      h4{
        font-family: 'HuaKangFangYuan';
        font-size: 26px;
        font-weight: 500;
        color: #444;
        margin-bottom: 70px;
        }
      h5{
        font-family: 'HuaKangFangYuan';
        font-size: 24px;
        font-weight: 500;
        color: #444;
        margin-bottom: 50px;
        }
      h6{
        font-family: 'HuaKangFangYuan';
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;
        color: #666;
        font-weight: 500;
      }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p, pre {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin:10px 0;
        line-height: 32px;
        white-space: pre-wrap; /* 保持换行和空格，同时允许自动换行 */
        color: #444444;
      }
      .p8d{
        color: #8d8d8d;
      }
      .p16 {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        color: #333;
        padding: 20px;
        background-color: #F8F8F8;
        margin: 20px 0 ;
      }
      .Pbold{
            font-weight: 900;
      }
      .bg-p{
              margin-top: 30px;
              width: 100%;
      }
      .p-bg {
          position:relative;
          background-color: #f4fcff; /* 主要背景颜色 */
          margin-top: 40px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #5fa7e4;
            }
          }
  }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }
  
    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .main-bg{
        width: 1200px;
        margin: 0 auto;
        .bg1{
            .bg1-p{
                margin-top: 30px;
                width: 100%;
            }
            .bg1-image{
              width: 100%;
              height: 400px;
              margin: 120px 0;
              background-image: url('./assets/bg1-image.jpg');
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
            .bg1-image2{
                height:300px;
                width: 100%;
                margin-top: 80px;
                background-image: url('./assets/bg1-image2.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
  .bg2{
    margin-top: 120px;
      .bg2-title2{
        font-size: 22px;
        margin: 30px 0 ;
      }
  }
  .bg3{
      .bg3-title{
        margin-top: 60px;
      }
      .title2Style{
        margin-top: 120px;
      }
      .bg2-step3-image1{
          height: 500px;
          width: 100%;
          margin-top: 50px;
          background-image: url('./assets/bg2-step3-image1.png');
          background-repeat: no-repeat;
          background-size: contain;
      }
      .bg2-step3-image2{
          height: 500px;
          width: 100%;
          margin-top: 50px;
          background-image: url('./assets/bg2-step3-image2.png');
          background-repeat: no-repeat;
          background-size: contain;
      }
      .bg2-step3-image3{
          height: 450px;
          width: 100%;
          margin-top: 80px;
          margin-bottom: 80px;
          background-image: url('./assets/bg2-step3-image3.png');
          background-repeat: no-repeat;
          background-size: contain;
      }
      .bg2-step3-image4{
          height: 500px;
          width: 100%;
          margin-top: 50px;
          background-image: url('./assets/bg2-step3-image4.png');
          background-repeat: no-repeat;
          background-size: contain;
      }

  }
  .bg4{
    margin-top: 120px;
      .bg2-step3-image7{
        height: 500px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image7.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image9{
        height: 500px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image9.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image10{
        height: 560px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image10.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image12{
        height: 750px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image12.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image14{
        height: 350px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image14.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image16{
        height: 500px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image16.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image18{
        height: 550px;
        width: 100%;
        margin: 60px 0;
        background-image: url('./assets/bg2-step3-image18.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image20{
        height: 450px;
        width: 100%;
        margin: 60px 0;
        margin-left: 130px;
        background-image: url('./assets/bg2-step3-image20.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bg2-step3-image21{
        height: 550px;
        width: 100%;
        margin-top: 60px;
        background-image: url('./assets/bg2-step3-image21.png');
        background-repeat: no-repeat;
        background-size: contain;
      }

      .bg4-pRight{
        width: 50%;
        position: relative;
        top: -350px;
        left: 500px;
      }
      .bg4-p1Right{
        width: 50%;
        position: relative;
        top: -480px;
        left: 500px;
      }
  }
    .bg4-p1{
        margin-top: 60px;
    }
    .bg5-maginAdjyst{
        margin-top: -200px;
    }
}
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>