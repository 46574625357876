<template>
    <div class="bodyContainer">
        <back :showButton="true" />
          <div class="main-bg">
              <h1 class="main-title">產品策劃</h1>
              <h3>前言</h3>
              <p class="p-h3">Introduction</p>
              <p class="p-bg">產品策劃涉及從用戶需求到產品功能，從市場分析到商業模式，從原型設計到項目管理，從測試優化到營運推廣等等各個方面的工作。 目標是創造出能夠解決用戶問題，滿足用戶需求，符合市場趨勢，並實現商業價值的優秀產品。</p>
          <div class="bg-magin-top">
              <h3>個人技能</h3>
              <p class="p-h3">Personal Skill</p>
              <p class="p-bg">
                <span class="icon">❶</span>熟練使用原型設計工具如PhotoShop、Mockplus、藍湖、Figma等，能快速建置高保真原型，並進行迭代最佳化。<br>
                <span class="icon">❷</span>擅長使用思維導圖工具，如ProcessOn 、GitMind 或 XMind，整合與展示複雜資訊，提升團隊溝通效率。<br>
                <span class="icon">❸</span>精通流程圖和用戶介面（UI）設計，能清晰呈現用戶旅程，優化產品的用戶體驗（UX），透過細緻的交互設計提升用戶滿意度。<br>
                <span class="icon">❹</span>具備卓越的需求文件編寫能力，能準確記錄產品需求，製定明確的開發指南和案例，確保開發團隊能夠高效進行。<br>
                <span class="icon">❺</span>具備紮實的技術背景與產品視角，善於運用創新思維策劃，推動產品從概念到市場的全過程，並維持產品的競爭力。<br>
                <span class="icon">❻</span>卓越的溝通能力和團隊協作精神，能夠與跨職能團隊有效協作，清晰傳達產品願景和設計理念，推動項目順暢推進並確保各方利益一致。<br>
              </p>
          </div>
          <div class="bg-magin-top">
              <h3>日常工作中的應用</h3>
              <p class="p-h3">Application</p>
              <p class="p-magin-top">在日常工作中，可以有效做到：</p>
              <div class="cardContainer">
                <div v-for="card in cards" :key="card.id">
                  <div class="card">
                    <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                    <h4 class="cardName">{{ card.name }}</h4>
                    <p class="cardContent">{{ card.content }}</p>
                  </div>
                </div>

              </div>
          </div>
          <div class="bg-magin-top">
            <br>
            <br>
            <br>
              <h3>項目案例展示</h3>
              <p class="p-h3">Project Display</p>
              <div class="projectContainer">
                <p class="title-bar">案例一：交易神器</p>
                <div class="mainContainer">
                  <div class="pj-photo1"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>我們致力於打造一個直觀、快捷且高效的用戶操作體驗。從簡潔的界面設計到流暢的交易執行，每一個環節都經過精心設計，以減少用戶的操作負擔，提升整體交易效率。讓用戶在這個多變的市場中，以最少的操作，達到最大的交易效益。</p>
                    <router-link to="/ProjectMenu/tradingSystem" class="pj-btn" >查看項目詳情</router-link>
                  </div>
                </div>
              </div>
              <div class="projectContainer">
                <p class="title-bar">案例二：推廣工具</p>
                <div class="mainContainer">
                  <div class="pj-photo2"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>過往，僅通過郵件方式向代理發送其個人的推廣編號和推廣連結，這一做法雖保證了基本的功能需求，卻在日常使用中顯得不夠便捷。許多代理反映，每當需要進行推廣時，必須翻閱大量郵件以尋找相關信息，十分低效。通過收集及評估用戶需求，我們開發了一個全新的推廣工具頁面，解決客戶需求，提升用戶體驗。</p>
                    <router-link to="/ProjectMenu/promotionTools" class="pj-btn" >查看項目詳情</router-link>
                  </div>
                </div>
              </div>
          </div>
        </div>
             <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/ProductPlanningBook/icon1.png'),
                name:'市場分析能力',
                content:'通過市場環境和競爭態勢，了解使用者需求和痛點，發現產品改進或創新的機會。',
              },
              {
                id: 2,
                icon:require('@/assets/ProductPlanningBook/icon2.png'),
                name:'產品設計能力',
                content:'根據市場分析的結果，制定產品目標和策略，並將其轉化為具體的功能需求和設計方案。',
              },
              {
                id: 3,
                icon:require('@/assets/ProductPlanningBook/icon3.png'),
                name:'項目管理能力',
                content:'透過個性化內容、客戶獎勵計劃、定期的用戶反饋循環，以及優化用戶體驗來增強用戶的忠誠度。',
              },
              {
                id: 4,
                icon:require('@/assets/ProductPlanningBook/icon4.png'),
                name:'產品運營能力',
                content:'能夠在產品上線後，持續關注產品的運作狀況和使用者反饋，並根據數據分析結果，進行產品優化或迭代。',
              },
            ],
      }
  }
}
  </script>
  <style lang="less" scoped>
    * {
        font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
        h1{
          font-family: 'AaErZhiYuan';
          font-weight: 500;
          position: relative;
          top: 100px;
          }
        h3{
          font-family: 'HuaKangFangYuan';
          font-size: 32px;
          margin-bottom: 10px;
          font-weight: 500;
          }
        h4{
          font-family: 'SourceSansPro-Regular';
          font-size: 22px;
          font-weight: 500;
          }
        .p-h3{
          font-family: 'HuaKangFangYuan';
          font-size: 16px;
          color: #666;
          font-weight: 500;
        }
        p {
          font-family: 'SourceSansPro-Regular';
          font-size: 18px;
          line-height: 32px;
          color: #444444;
          }
        .p-bg {
          position:relative;
          background-color: #f4fcff; /* 主要背景颜色 */
          margin-top: 40px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #5fa7e4;
            }
          }
          .icon {
            color: #81ACCF; /* 设置图标的颜色 */
            font-size: 18px; /* 设置图标的字号，可根据需要调整 */
            margin-right: 10px;
          }
          .Pbold{
              font-weight: 900;
          }
    }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }

    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .main-bg{
        width: 1200px;
        // background-color: #81ACCF;
        margin: 0 auto;
        .bg-magin-top{
          margin-top:120px
        }
        .p-magin-top{
          margin-top:30px
        }
        .cardContainer{
            margin-top: 80px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card{
                width: 300px;
                height: 400px;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .cardIcon{
                    width: 150px;
                    height: 150px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .cardName{
                    margin-top: 40px;
                }
                .cardContent{
                    padding: 0 40px;
                    margin-top: 30px;
                    color: #666;
                }
        }
        }
      .projectContainer{
        margin-bottom: 130px;
        .title-bar{
          margin-top: 60px;
          font-size: 24px;
          color: #fff;
          padding: 10px 0 10px 50px;
          border-radius: 50px;
          border: 3px solid #FFF;
          background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
        }
        .mainContainer{
          width: 100%;
          margin-top: 120px;
          display: flex;
          justify-content: space-between;
          .pj-photo1{
            width: 580px;
            height: 380px;
            margin-left: 80px;
            background-image: url('./assets/project1.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
          .pj-photo2{
            width: 580px;
            height: 380px;
            margin-left: 80px;
            background-image: url('./assets/project2.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
        .introContainer{
          .intro{
            width: 400px;
          }
          .pj-btn{
            text-decoration: none;
            color: #fff;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #FFB901;
            transition: background-color 0.3s;
            &:hover{
              background-color: rgba(255, 185, 1, 0.8);
            }
          }
        }
        }
      }
    }
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>