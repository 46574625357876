<template>
  <div id="app">
    <!-- 启动画面 -->
    <div v-if="loading" class="splash-screen" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <div class="loading-overlay">
        <div class="loading-text">Loading...</div>
        <div class="progress-bar-background">
          <div class="progress-bar-fill" :style="{ width: progress + '%' }">{{ progress }}%</div>
        </div>
      </div>
    </div>
    <!-- 主要内容 -->
    <router-view v-if="!loading"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      progress: 0,
      backgroundImage: require('@/assets/LoadingEffect.png'), // 替换为背景图片的路径
    };
  },
  mounted() {
    this.loadWebsite();
  },
  methods: {
    loadWebsite() {
      const interval = setInterval(() => {
        this.progress += 10;
        if (this.progress >= 100) {
          clearInterval(interval);
          this.loading = false;
          this.$router.push('/'); // 跳转到首页
        }
      }, 150); // 每150毫秒更新一次进度
    }
  }
};
</script>

<style>
.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: 10000;
}

.loading-overlay {
  background-color: rgba(255, 255, 255, 0.85); /* 稍微透明的覆盖层 */
  padding: 2rem;
  border-radius: 15px;
  position: relative; /* 为进度条定位提供基准 */
  top: 30vh; /* 向下移动至视口高度的一半 */
  transform: translateY(-50%); /* 向上移动自身高度的一半以居中 */
}

.loading-text {
  font-size: 1.5em;
  margin-bottom: 1rem;
  text-align: center; /* 文本居中 */
}

.progress-bar-background {
  width: 20vw; /* 调整以适合您的设计 */
  height: 20px; /* 调整进度条的厚度 */
  background-color: #f0f0f0;
  border-radius: 10px; /* 高度的一半，使其呈药丸形状 */
  overflow: hidden;
  margin-top: 2rem; /* 在文本和进度条之间提供空间 */
  position: relative; /* 相对定位 */
}

.progress-bar-fill {
  height: 100%;
  background-color: #3498db;
  text-align: center;
  line-height: 20px; /* 与进度条高度相同，用于垂直居中 */
  color: white;
  font-weight: bold;
  border-radius: 10px; /* 与进度条背景相同，以保持一致的样式 */
  transition: width 0.2s ease;
}
</style>
