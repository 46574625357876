<template>
    <div v-if="isMobile" class="Mobile-banner">
      <div class="mobile-bg"></div>
    </div>
    <section v-else class="banner">
      <!-- /左边内容 -->
      <div class="banner-content" >
        <div class="NamePng"></div>
        <div class="subtitle"></div>
        <!-- 四個圖標 -->
        <div class="features">
          <div class="feature-item">
            <div class="iconContainer">
              <img src="@/assets/icons/DongCha.svg" alt="Icon" class="feature-icon" />
            </div>
            <span>市場洞察</span>
          </div>
          <div class="feature-item">
            <div class="iconContainer">
              <img src="@/assets/icons/ChuangXin.svg" alt="Icon" class="feature-icon" />
            </div>
            <span>創新思維</span>
          </div>
          <div class="feature-item">
            <div class="iconContainer">
              <img src="@/assets/icons/HuoDong.svg" alt="Icon" class="feature-icon" />
            </div>
            <span>活動策劃</span>
          </div>
          <div class="feature-item">
            <div class="iconContainer">
              <img src="@/assets/icons/FenXi.svg" alt="Icon" class="feature-icon" />
            </div>
            <span>數據分析</span>
          </div>
        </div>
      </div>
      <!-- 左下内容 -->
      <!-- 按钮 -->
      <div class="btn-container"  >
        <div class="button-image-wrapper">
          <router-link to="/BriefMenu" class="fancy-button briefButton">個人簡介<p class="english-p">Brief</p>
            <div class="briefIcon"></div>
          </router-link>
          <div class="briefPng imgContainer"></div>

          <router-link to="/SkillMenu/" class="fancy-button workButton" >專業技能<p class="english-p">Skill</p>
            <div class="skillIcon"></div>
          </router-link>
          <div class="SkillPng imgContainer"></div>

          <router-link to="ProjectMenu" class="fancy-button SkillButton" >項目成果<p class="english-p">Project</p>
            <div class="projectIcon"></div>
          </router-link>
          <div class="projectPng imgContainer"></div>

          <router-link to="/ContactMenu" class="fancy-button contactButton" >聯繫方式<p class="english-p">Contact</p>
            <div class="contactIcon"></div>
          </router-link>
          <div class="contactPng imgContainer"></div>
        </div>
      </div>
      <!-- 右边图片 -->
      <div class="banner-image">
        <!-- 图片内容，在此处放置图像文件 -->
        <div class="dialog-box" :class="{ visible: showdialogBox }">
            <span class="bot"></span>
            <span class="top"></span>
            歡迎~ <br/>
            這是我的作品集網站 
        </div>
        <!-- 時鐘 -->
        <div class="clock">
          <div class="numbers">
            <span style="--i:0;"><b>12</b></span>
            <span style="--i:1;"><b>3</b></span>
            <span style="--i:2;"><b>6</b></span>
            <span style="--i:3;"><b>9</b></span>
            <div class="circle" :style="{ transform: hourHandStyle }" id="hr"><i></i></div>
            <div class="circle" :style="{ transform: minuteHandStyle }" id="mn"><i></i></div>
            <div class="circle" :style="{ transform: secondHandStyle }" id="sc"><i></i></div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  
 export default {

 
  
  setup() {
    const hourHandStyle = ref('');
    const minuteHandStyle = ref('');
    const secondHandStyle = ref('');

    const updateClock = () => {
      const day = new Date();
      const hh = day.getHours() * 30;
      const mm = day.getMinutes() * 6;
      const ss = day.getSeconds() * 6;

      hourHandStyle.value = `rotateZ(${hh + mm / 12}deg)`;
      minuteHandStyle.value = `rotateZ(${mm}deg)`;
      secondHandStyle.value = `rotateZ(${ss}deg)`;
    };

    let intervalId;

    onMounted(() => {
      updateClock();
      intervalId = setInterval(updateClock, 1000);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return {
      hourHandStyle,
      minuteHandStyle,
      secondHandStyle,
    };
  },
  
  data() {
    return {
      isMobile:false,
      showdialogBox: false,
      animationTimeout: null, // 用于存储延迟的ID
    };
  },
  mounted() {
    setTimeout(() => {
      this.showdialogBox = true; // 显示對話框

      setTimeout(() => {
        this.showdialogBox = false; // 4秒后隐藏對話框
      }, 4000); // 总时长4秒（包含1秒顶部停留时间）
    }, 2000); // 页面加载后2秒开始动画

    this.updateImageVisibility();
    window.addEventListener('resize', this.updateImageVisibility);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateImageVisibility);
    },

    methods: {
      updateImageVisibility() {
      const isMobile = /phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone/i.test(navigator.userAgent);
      this.isMobile = isMobile;
      }
    },
}
  </script>

<style lang="less" scoped>
@primary-color: #ff5252; // 确保这个变量在使用之前已经定义
.banner {
  display: flex;
  justify-content: space-between;
  width: 100vw; // 设置为视口宽度
  min-height: 100vh; /* 使用min-height而非height确保至少100%视口高度 */
  padding: 0;
  background-image: url("@/assets/bg.png");
  background-size: cover; // 保证背景图像全屏且覆盖整个元素
  background-position: center; // 图片居中
  background-repeat: no-repeat; // 不重复图片
  overflow: hidden; /* 隐藏溢出的内容 */
}
.Mobile-banner{
  width: 100vw; // 设置为视口宽度
  min-height: 100vh; /* 使用min-height而非height确保至少100%视口高度 */
  background-color:#fceee7;
  display: flex;
  justify-content: center;
  align-items: center;
  .mobile-bg {
    width: 80vw;
    height: 80vh; /* 高度自动，保持原始宽高比 */
    background-image: url("../assets/mobile-bg.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    background-position: center;
  }
}

.banner-content {
  position:absolute;
  z-index: 1; // 确保内容显示在背景图像之上
  left: 0; /* 距离左侧的距离，根据需要调整 */
  padding: 8.5vh 10vh;
  // margin-left: 13vh;
  // width: 35vh; /* 设置内容区域的宽度，根据需要调整 */
  // margin-top: -400px;

  .NamePng{
    position: relative;
    top: 0vh;
    height: 13vh;
    width: 80vh;
    background-image: url("@/assets/Name.png");
    background-size: contain;
    background-repeat: no-repeat; // 不重复图片
  }

  .subtitle {
    position: relative;
    height: 10vh;
    width: 90vh;
    background-image: url("@/assets/Subtitle.png");
    background-size: contain;
    background-repeat: no-repeat; // 不重复图片
  }
  // 四個圖標
  .features {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between; /* 或者 space-between */
    // flex-wrap: wrap; /* 允许项目换行 */
}

.feature-item {
  display: flex;
  flex-direction: column; /* 使图标和文本垂直排列 */
  align-items: center; /* 水平居中对齐 */
  text-align: center; /* 文本居中对齐 */
  margin: 0 2vh; /* 为每个特性项提供一些外边距 */
  .iconContainer{
    margin-top: 3vh;
    display: flex;
    width: 9vh;
    height: 9vh;
    background-color: #fff;
    align-items: center; /* 水平居中对齐 */
    justify-content: center; /* 水平居中 */
    border-radius: 100px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
    margin-bottom: 20px;
  }
  span{
    margin-top: 2vh;
    font-size: 2.2vh;
  }
}

.feature-icon {
  width: 4vh; /* 或根据您的实际图标大小进行调整 */
  height: 4vh; /* 或根据您的实际图标大小进行调整 */
}
}

.banner-image {
  width: 85vh;
  height: 60vh; /* 高度自动，保持原始宽高比 */
  /* 使用absolute定位来精确放置图片 */
  position: absolute;
  top: 34%;  /* 从顶部开始50%的位置，与屏幕中心对齐 */
  right: 12.5%; /* 从右侧开始10%的位置 */
  background-image: url("@/assets/boy.png");
  background-size: contain; /* 使用contain以确保图片内容完整显示 */
  background-repeat: no-repeat; // 不重复图片
  }

  .dialog-box {
  position: relative;
  background: #beceeb;
  top: -6vh;
  right: -20vh;
  border-radius: 10px;
  padding: 20px;
  width: 28vh;
  font-size: 2.5vh;
  opacity: 0; /* 开始时不可见 */
  transform: translateY(10vh);
  transition: opacity 1.5s, transform 2s; /* 过渡效果 */
  // box-shadow:  18px 18px 30px rgba(0,0,0,0.2),
  //                 -8px -8px 30px rgba(255, 255, 255, 1);
  z-index: 20;
  /* 更多样式设置 */
}

/* 尖角样式 */
.dialog-box:after {
  content: "";
  position: absolute;
  bottom: -12%; /* 尖角的垂直位置，根据需要调整 */
  right: 10%; /* 尖角的水平位置，现在是向右边调整 */
  border-width: 10px 20px 10px 0; /* 调整边框宽度以形成向左的尖角 */
  border-style: solid;
  border-color: transparent #beceeb transparent transparent; /* 调整边框颜色，右边颜色与对话框背景相同 */
  transform: skew(-20deg); /* 修改倾斜角度为负值，使尖角向右倾斜 */
  transform-origin: top left; /* 设置变形的基点为左上角 */
}
/* 当.showHiPng为true时应用这些样式 */
.visible {
    opacity: 1; /* 完全可见 */
    transform: translateY(0); /* 移动到原位置 */
  }

// 左下角4個按鈕
.btn-container{
  position: absolute;
  left: 5vh;
  top: 55vh;
  padding: 0 10vh;
  display: flex;
  flex-direction: column;
  z-index: 1; // 确保内容显示在背景图像之上

  .fancy-button {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #333;
    text-decoration: none;
    background-color: #f0f0f0;
    // align-items: center;
    font-size: 2.5vh; /* 调整字体大小 */
    padding: 1.3vh 0 1vh 5vh; /* 内边距，根据实际情况调整 */
    margin-top: 2.5vh;
    margin-bottom: 0.5rem; /* 按钮之间的间距 */
    border: none;
    border-radius: 16px;
    backdrop-filter: blur(5px); /* 毛玻璃效果 */
    box-shadow:  12px 12px 24px rgba(0,0,0,0.2), /* 外陰影 */
               -8px -8px 16px rgba(255, 255, 255, 0.5), /* 內陰影 */
               inset 0 0 4px rgba(0,0,0,0.1), /* 內部淺色陰影 */
               inset 0 0 4px rgba(255, 255, 255, 0.7); /* 內部深色陰影 */
  transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    // transition: all 0.3s ease-in-out;
    text-align: left; /* 文本居中 */
    width: 25vh; /* 宽度根据内容自动调整 */
    height: 7.5vh;
    outline: none; /* 去除点击时的轮廓线 */
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s; /* 过渡效果 */
    .english-p{
      font-size: 2.2vh;
      margin-top: 0.6vh;
      color: #666;
    }
    .briefIcon{
      position: absolute;
      top: 1.5vh;
      left: 18vh;
      width: 5vh;
      height: 5vh;
      background-image: url('@/assets/briefIcon.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .skillIcon{
      position: absolute;
      top: 1.5vh;
      left: 17.5vh;
      width: 5vh;
      height: 5vh;
      background-image: url('@/assets/skillIcon.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .projectIcon{
      position: absolute;
      top: 1.5vh;
      left: 17.5vh;
      width: 5vh;
      height: 5vh;
      background-image: url('@/assets/projectIcon.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .contactIcon{
      position: absolute;
      top: 1.5vh;
      left: 17.5vh;
      width: 5vh;
      height: 5vh;
      background-image: url('@/assets/contactIcon.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .fancy-button:hover {
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  0 0 0 rgba(0,0,0,0.2),
                0 0 0 rgba(255, 255, 255, 0.8),
                inset 18px 18px 30px rgba(0,0,0,0.1),
                inset -18px -18px 30px rgba(255, 255, 255, 1);
  font-size: 2.4vh;
  transition: all 0.2s ease-in-out;
    .english-p{
      font-size: 2.12vh;
    }
    .briefIcon {
      width: 4.9vh;
      height: 4.9vh;
    }
    .skillIcon{
      width: 4.9vh;
      height: 4.9vh;
    }
    .projectIcon{
      width: 4.9vh;
      height: 4.9vh;
    }
    .contactIcon{
      width: 4.9vh;
      height: 4.9vh;
    }
  }

  .fancy-button:active {
    background: linear-gradient(145deg, #d1d1d1, #f8f8f8);
    box-shadow: inset 5px 5px 10px #bebebe, inset -5px -5px 10px #ffffff;
    transform: translateY(2px);
  }
  .fancy-button:hover + .imgContainer {
    display: block;
  }
}
// 4按鈕hover圖片
.button-image-wrapper{
    height: auto;
    width: auto;
    position: relative;

    .imgContainer{
      width: 29vh;
      height: 29vh;
      position: fixed;
      top: 9%;
      right: 8%;
      display: none;
    }
    .briefPng{
    background-image: url("@/assets/brief.png");
    background-size: contain;
    background-repeat: no-repeat;
    }
    .projectPng{
    background-image: url("@/assets/work.png");
    background-size: contain;
    background-repeat: no-repeat;
    }

    .SkillPng{
    background-image: url("@/assets/Skill.png");
    background-size: contain;
    background-repeat: no-repeat;
    }
    .contactPng{
    background-image: url("@/assets/contact.png");
    background-size: contain;
    background-repeat: no-repeat;
    }
  }
  
  // 時鐘
  .clock {
  position: relative;
  width: 15vh;
  height: 15vh;
  top: -50%;
  left:30%;
  background-color: #c9d5e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vh; /* 50px / 300px * 20vh */
  filter: blur(1px); // 添加模糊效果
  transform-origin: center; // 设置变换的原点为中心
  box-shadow: 2vh 2vh 2vh -0.667vh rgba(0, 0, 0, 0.15),
              inset 0.5vh 0.5vh 0.667vh rgba(255, 255, 255, 0.75),
              -3vh -3vh 4.333vh rgba(255, 255, 255, 0.55),
              inset -0.067vh -0.067vh 0.667vh rgba(0, 0, 0, 0.2);
}

// 最中心的點
.clock::before {
  content: '';
  position: absolute;
  width: 0.267vh; /* 4px / 300px * 20vh */
  height: 0.267vh; /* 4px / 300px * 20vh */
  background-color: #e91e63;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0 0 0 0.067vh #e91e63,
              0 0 0 0.2vh #fff,
              0 0 0.333vh 0.333vh rgba(0, 0, 0, 0.15);
}
// 鐘的內圈
.numbers {
  position: absolute;
  inset: 2vh; /* 35px / 300px * 20vh */
  background-color: #152b4a;
  border-radius: 50%;
  box-shadow: 0.333vh 0.333vh 1vh #152b4a66,
              inset 0.333vh 0.333vh 0.333vh rgba(255, 255, 255, 0.75),
              -0.4vh -0.4vh 0.667vh rgba(255, 255, 255, 1);
}

// 數字的間距
.numbers span {
  position: absolute;
  inset: 0.6vh; /* 5px / 300px * 20vh */
  text-align: center;
  color: #fff;
  font-size: 1.2vh; /* 字体大小通常不需要调整 */
  transform: rotate(calc(90deg * var(--i)));
}

.numbers span b{
  font-weight: 600;
  display: inline-block;
  transform: rotate(calc(-90deg * var(--i)));
}
// 顏色圈圈的厚度
.numbers::before{
  content: '';
  position: absolute;
  inset: 2vh;
  background: linear-gradient(#2196f3, #6AC13B);
  border-radius: 50%;
  animation: animate 2s linear infinite;
}

@keyframes animate {

  0%{
    transform: rotate(360deg);
  }
  100%{
    transform: rotate(0);
  }
}
// 顏色圈圈的填滿範圍
.numbers::after{
  content: '';
  position: absolute;
  inset: 2.2vh;
  background-color: #152b4a;
  border-radius: 50%;
}

.circle{
  position: absolute;
  inset: 1vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.circle i {
  position: absolute;
  width: 0.5vh; /* 3px / 300px * 20vh */
  height: 50%; /* 百分比保持不变 */
  background-color: #fff;
  transform-origin: bottom;
  transform: scaleY(0.55);
}

.circle#hr i {
  width: 0.3vh; /* 4px / 300px * 20vh */
  transform: scaleY(0.32);
}

.circle#mn i {
  width: 0.3vh;
  transform: scaleY(0.48);
}

.circle#sc i {
  width: 0.1vh; /* 2px / 300px * 20vh */
  height: 5.9vh;
  transform: scaleY(0.5);
  background-color: #e91e63;
  box-shadow:0 30px 0 #e91e63;
}

/* 默认不显示提示容器 */
.mobile-warning {
  // display: block;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // // background: url('path/to/your/image.jpg') no-repeat center center;
  // background-color: #FDF0E9;
  // background-size: cover;
  // z-index: 1000; /* 确保提示覆盖其他内容 */
}
</style>