<template>
  <div class="bodyContainer">
      <back :showButton="true" />
        <div class="main-bg">
            <h1 class="main-title">數據分析</h1>
            <h3>前言</h3>
            <p class="p-h3">Introduction</p>
            <p class="p-bg">學習能更準確地捕捉市場和用戶需求，從而創造出更具吸引力的產品。這樣的能力使得產品決策更加數據驅動，降低風險，並有效地指導產品迭代，最終推動業務成長和用戶價值的提升。透過數據分析，可以在競爭激烈的市場中為公司贏得關鍵優勢。</p>
        <div class="bg-magin-top">
            <h3>個人技能</h3>
            <p class="p-h3">Personal Skill</p>
            <p class="p-bg">
              <span class="icon">❶</span><span class="Pbold">統計分析：</span>大數定律、推斷統計學、T檢驗、迴歸、預測<br>
              <span class="icon">❷</span><span class="Pbold">視覺化輔助工具：</span>Excel、Python<br>
              <span class="icon">❸</span><span class="Pbold">資料庫：</span>SQL<br>
              <span class="icon">❹</span><span class="Pbold">人工智能：</span>機器學習<br>
              <span class="icon">❺</span><span class="Pbold">數據挖掘工具：</span>Matlab、Python、MySQL<br>
              <span class="icon">❻</span><span class="Pbold">編程語言：</span>Python<br>
            </p>
        </div>
        <div class="bg-magin-top">
            <h3>日常工作中的應用</h3>
            <p class="p-h3">Application</p>
            <p class="p-magin-top">在日常工作中，可以通過數據分析有效做到：</p>
            <div class="cardContainer">
              <div v-for="card in cards" :key="card.id">
                <div class="card">
                  <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                  <h4 class="cardName">{{ card.name }}</h4>
                  <p class="cardContent">{{ card.content }}</p>
                </div>
              </div>

            </div>
        </div>
        <div class="bg-magin-top">
          <br>
          <br>
          <br>
            <h3>項目案例展示</h3>
            <p class="p-h3">Project Display</p>
            <div class="projectContainer">
              <p class="title-bar">案例一：淘寶用戶購物行為分析</p>
              <div class="mainContainer">
                <div class="pj-photo1"></div>
                <div class="introContainer">
                  <p class="intro"><span class="Pbold">簡介：</span>在當前成熟的移動互聯網市場中，粗放型的增長策略已不再有效。我們需採用更精細化的市場營銷策略。這包括深入研究市場趨勢和消費者偏好，進行用戶行為數據分析，並以此為基礎來設計更加個性化的用戶互動和推廣活動。所以，我使用Python對淘寶的用戶數據進行分析，目的是從中發現可行的業務洞察，進一步形成符合用戶需求的營銷策略，以提升用戶體驗並增強客戶忠誠度。</p>
                  <router-link to="/ProjectMenu/taobaoAnalysis" class="pj-btn" >查看項目詳情</router-link>
                </div>
              </div>
            </div>
            <div class="projectContainer">
              <p class="title-bar">案例二：創富PK爭霸賽</p>
              <div class="mainContainer">
                <div class="pj-photo2"></div>
                <div class="introContainer">
                  <p class="intro"><span class="Pbold">簡介：</span>評估活動對於平台用戶交易量的影響，探索活動對提高用戶參與度、交易意願等方面的成效。此外，分析還將深入挖掘活動中可能存在的問題和改進空間，如參與用戶的行為模式、活動吸引力和激勵機制的有效性，以便為未來的活動提供數據支撐和策略優化建議。</p>
                  <router-link to="/ProjectMenu/battleActivity" class="pj-btn" >查看項目詳情</router-link>
                </div>
              </div>
            </div>
        </div>
      </div>
           <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
          cards:[
            {
              id: 1,
              icon:require('@/assets/DataAnalysisBook/icon1.png'),
              name:'決策支持',
              content:'通過數據分析的結果提高決策的精確性和效率。',
            },
            {
              id: 2,
              icon:require('@/assets/DataAnalysisBook/icon2.png'),
              name:'洞察發現',
              content:'通過數據發掘市場和客戶行為的深層趨勢。',
            },
            {
              id: 3,
              icon:require('@/assets/DataAnalysisBook/icon3.png'),
              name:'風險管理',
              content:'通過數據預測風險，減少損失。',
            },
            {
              id: 4,
              icon:require('@/assets/DataAnalysisBook/icon4.png'),
              name:'個性化服務',
              content:'通過大量的客戶行為數據，了解客戶需求，提供定制服務。',
            },
          ],
    }
}
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        }
      h4{
        font-family: 'SourceSansPro-Regular';
        font-size: 22px;
        font-weight: 500;
        }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        line-height: 32px;
        color: #444444;
        }
      .p-bg {
        position:relative;
        background-color: #f4fcff; /* 主要背景颜色 */
        margin-top: 40px;
        padding: 30px 30px 30px 40px;
        border-radius: 5px;
        overflow: hidden;
        br{
          content: '';
          margin-bottom: 10px;
          display: block;
        }
        &::before{
            content:'';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 10px;
            background-color: #5fa7e4;
          }
        }
        .icon {
          color: #81ACCF; /* 设置图标的颜色 */
          font-size: 18px; /* 设置图标的字号，可根据需要调整 */
          margin-right: 10px;
        }
        .Pbold{
            font-weight: 900;
        }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      // background-color: #81ACCF;
      margin: 0 auto;
      .bg-magin-top{
        margin-top:120px
      }
      .p-magin-top{
        margin-top:30px
      }
      .cardContainer{
          margin-top: 80px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .card{
              width: 300px;
              height: 400px;
              margin-top: 80px;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              .cardIcon{
                  width: 150px;
                  height: 150px;
                  background-repeat: no-repeat;
                  background-size: contain;
              }
              .cardName{
                  margin-top: 40px;
              }
              .cardContent{
                  padding: 0 40px;
                  margin-top: 30px;
                  color: #666;
              }
      }
    }
    .projectContainer{
      margin-bottom: 130px;
      .title-bar{
        margin-top: 60px;
        font-size: 24px;
        color: #fff;
        padding: 10px 0 10px 50px;
        border-radius: 50px;
        border: 3px solid #FFF;
        background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
      }
      .mainContainer{
        width: 100%;
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        .pj-photo1{
          width: 613px;
          height: 403px;
          margin-left: 80px;
          background-image: url('./assets/project1.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .pj-photo2{
          width: 613px;
          height: 403px;
          margin-left: 80px;
          background-image: url('./assets/project2.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
      .introContainer{
        .intro{
          width: 400px;
        }
        .pj-btn{
          text-decoration: none;
          color: #fff;
          font-family: 'HuaKangFangYuan';
          font-size: 22px;
          width: 200px;
          height: 55px;
          margin-top: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          background-color: #FFB901;
          transition: background-color 0.3s;
          &:hover{
            background-color: rgba(255, 185, 1, 0.8);
          }
        }
      }
      }
    }
  }
  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>