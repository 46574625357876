<template>
    <div class="bodyContainer">
        <back :showButton="true" />
          <div class="main-bg">
              <h1 class="main-title">運營管理</h1>
              <h3>前言</h3>
              <p class="p-h3">Introduction</p>
              <p class="p-bg">了解運營管理對於優化業務流程和提升組織效率至關重要。它涉及計劃、指導和協調公司的日常活動，這些都是實現長期戰略目標的基礎。精通運營知識使我們能夠透徹分析各業務部門的交互作用，並掌握如何通過高效的資源管理來實現最大的產出。它讓我們能夠預見市場變化，調整內部流程，以支持快速決策和靈活應變。這不僅鞏固了內部運營的穩定性，也為客戶提供了持續的價值，並確保了企業在激烈的市場競爭中保持優勢。</p>
          <div class="bg-magin-top">
              <h3>個人技能</h3>
              <p class="p-h3">Personal Skill</p>
              <p class="p-bg">
                <span class="icon">❶</span><span class="Pbold">AARRR模型：</span>擅長運用AARRR模型（獲取、激活、留存、收入、推薦）進行用戶生命周期管理，驅動業務成長。<br>
                <span class="icon">❷</span><span class="Pbold">RFM模型：</span>擅長使用RFM模型深度挖掘用戶行為，實現精細化客戶管理，根據不同類型客戶進行個性化溝通與營銷策略定制，有效提高轉化率和用戶忠誠度。<br>
                <span class="icon">❸</span><span class="Pbold">A/B Test：</span>擅長進行A/B測試，基於數據分析優化產品決策，提升轉化效率。<br>
                <span class="icon">❹</span><span class="Pbold">用戶畫像分析：</span>深入分析和構建用戶畫像，透過數據洞察理解目標用戶群的需求和行為模式，為精準營銷提供依據。<br>
                <span class="icon">❺</span><span class="Pbold">內容營銷策略：</span>設計並實施內容營銷策略，通過高質量和高關聯性的內容提升品牌知名度，吸引並留存用戶。<br>
                <span class="icon">❻</span><span class="Pbold">數據驅動優化：</span>持續追蹤和分析產品運營數據，基於用戶反饋和行為數據優化產品功能和用戶體驗，推動產品持續進化。<br>
              </p>
          </div>
          <div class="bg-magin-top">
              <h3>日常工作中的應用</h3>
              <p class="p-h3">Application</p>
              <p class="p-magin-top">在日常工作中，可以有效做到：</p>
              <div class="cardContainer">
                <div v-for="card in cards" :key="card.id">
                  <div class="card">
                    <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                    <h4 class="cardName">{{ card.name }}</h4>
                    <p class="cardContent">{{ card.content }}</p>
                  </div>
                </div>

              </div>
          </div>
          <div class="bg-magin-top">
            <br>
            <br>
            <br>
              <h3>項目案例展示</h3>
              <p class="p-h3">Project Display</p>
              <div class="projectContainer">
                <p class="title-bar">案例一：RFM模型客戶價值分類及數據分析</p>
                <div class="mainContainer">
                  <div class="pj-photo1"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>本項目將隨機抽取部份數據，通過構建RFM模型，將消費者分類為不同價值層次的客戶群。對於各種價值層次的消費者，我們將採取差異化的策略。透過這些模型，能夠針對不同的消費者群體實施具體的營銷策略，最大限度地留住高價值客戶。同時，對於那些低價值客戶，我們可以相應減少投入成本，從而達到資源的最優配置。</p>
                    <router-link to="/ProjectMenu/RFM" class="pj-btn" >查看項目詳情</router-link>
                  </div>
                </div>
              </div>
              <div class="projectContainer">
                <p class="title-bar">案例二：A / B 測試 - 網站轉化率</p>
                <div class="mainContainer">
                  <div class="pj-photo2"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>公司非常注重自己的落地頁設計，希望通過改進設計來提高轉化率。以往該公司全年轉化率平均在13左右%，現在希望設計的新頁面能夠帶來更高的轉化率，希望新頁面的轉化率能有2%的提升，達到15%。在正式推出新頁面之前，AB Test在小范圍的用戶中進行測試，以確保新頁面的效果能夠達到預期目標。</p>
                    <router-link to="/ProjectMenu/ABTest" class="pj-btn" >查看項目詳情</router-link>
                  </div>
                </div>
              </div>
          </div>
        </div>
             <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/OperationBook/icon1.png'),
                name:'拉新引流',
                content:'通過多渠道營銷、社群營銷、合夥人計劃等方式吸引新用戶，使用病毒式傳播、口碑推廣或線上活動等策略來增加網站或應用的流量。',
              },
              {
                id: 2,
                icon:require('@/assets/OperationBook/icon2.png'),
                name:'刺激交易量',
                content:'運用限時優惠、月度、節日等活動來促進用戶購買行為，從而提高交易量。',
              },
              {
                id: 3,
                icon:require('@/assets/OperationBook/icon3.png'),
                name:'提升用戶留存率',
                content:'透過個性化內容、客戶獎勵計劃、定期的用戶反饋循環，以及優化用戶體驗來增強用戶的忠誠度。',
              },
              {
                id: 4,
                icon:require('@/assets/OperationBook/icon4.png'),
                name:'優化產品轉化率',
                content:'利用A/B測試、用戶體驗設計和轉化率最佳化（CRO）技術來提高產品的轉化率，從而提升收益。',
              },
            ],
      }
  }
}
  </script>
  <style lang="less" scoped>
    * {
        font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
        h1{
          font-family: 'AaErZhiYuan';
          font-weight: 500;
          position: relative;
          top: 100px;
          }
        h3{
          font-family: 'HuaKangFangYuan';
          font-size: 32px;
          margin-bottom: 10px;
          font-weight: 500;
          }
        h4{
          font-family: 'SourceSansPro-Regular';
          font-size: 22px;
          font-weight: 500;
          }
        .p-h3{
          font-family: 'HuaKangFangYuan';
          font-size: 16px;
          color: #666;
          font-weight: 500;
        }
        p {
          font-family: 'SourceSansPro-Regular';
          font-size: 18px;
          line-height: 32px;
          color: #444444;
          }
        .p-bg {
          position:relative;
          background-color: #f4fcff; /* 主要背景颜色 */
          margin-top: 40px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #5fa7e4;
            }
          }
          .icon {
            color: #81ACCF; /* 设置图标的颜色 */
            font-size: 18px; /* 设置图标的字号，可根据需要调整 */
            margin-right: 10px;
          }
          .Pbold{
              font-weight: 900;
          }
    }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }

    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .main-bg{
        width: 1200px;
        // background-color: #81ACCF;
        margin: 0 auto;
        .bg-magin-top{
          margin-top:120px
        }
        .p-magin-top{
          margin-top:30px
        }
        .cardContainer{
            margin-top: 80px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card{
                width: 300px;
                height: 400px;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .cardIcon{
                    width: 150px;
                    height: 150px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .cardName{
                    margin-top: 40px;
                }
                .cardContent{
                    padding: 0 30px;
                    margin-top: 30px;
                    color: #666;
                }
        }
      }
      .projectContainer{
        margin-bottom: 130px;
        .title-bar{
          margin-top: 60px;
          font-size: 24px;
          color: #fff;
          padding: 10px 0 10px 50px;
          border-radius: 50px;
          border: 3px solid #FFF;
          background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
        }
        .mainContainer{
          width: 100%;
          margin-top: 120px;
          display: flex;
          justify-content: space-between;
          .pj-photo1{
          width: 580px;
          height: 380px;
          margin-left: 80px;
          background-image: url('./assets/project1.png');
          background-repeat: no-repeat;
          background-size: contain;
          }
          .pj-photo2{
          width: 580px;
          height: 380px;
          margin-left: 80px;
          background-image: url('./assets/project2.png');
          background-repeat: no-repeat;
          background-size: contain;
          }
        .introContainer{
          .intro{
            width: 400px;
          }
          .pj-btn{
            text-decoration: none;
            color: #fff;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #FFB901;
            transition: background-color 0.3s;
            &:hover{
              background-color: rgba(255, 185, 1, 0.8);
            }
          }
        }
        }
      }
    }
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>