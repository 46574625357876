<template>
    <div class="bodyContainer">
        <back :showButton="true" />
          <div class="main-bg">
              <h1 class="main-title">前端開發知識</h1>
              <h3>前言</h3>
              <p class="p-h3">Introduction</p>
              <p class="p-bg">學習前端開發技術不僅有助於深入理解產品，更能精準界定產品需求與目標。這項技能讓我們能夠直觀地將想法原型化，與設計和開發團隊有效溝通，保證構想的準確執行。同時，這也強化了與市場行銷、銷售及客戶服務等其他部門的協作。透過前端技術，我們能清楚展示產品特色及預期的用戶體驗，提升跨部門的共識，加速決策流程，推動項目有效進展。</p>
          <div class="bg-magin-top">
              <h3>個人技能</h3>
              <p class="p-h3">Personal Skill</p>
              <p class="p-bg">
                <span class="icon">❶</span>熟練 HTML、CSS、JavaScript 等 Web 前端開發技術。<br>
                <span class="icon">❷</span>了解並使用 JavaScript 框架（如 Vue）。<br>
                <span class="icon">❸</span>了解並使用前端建置工具（如 Webpack、Babel）。<br>
                <span class="icon">❹</span>掌握大型網站頁面架構和佈局，以及後台搭建。<br>
              </p>
          </div>
          <div class="bg-magin-top">
              <h3>日常工作中的應用</h3>
              <p class="p-h3">Application</p>
              <p class="p-magin-top">通過所學知識，有效促進日常與開發團隊的有效溝通，理解並評估功能實現的可行性，確保項目進度和品質。</p>
              <div class="cardContainer">
                <div v-for="card in cards" :key="card.id">
                  <div class="card">
                    <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                    <h4 class="cardName">{{ card.name }}</h4>
                    <p class="cardContent">{{ card.content }}</p>
                  </div>
                </div>

              </div>
          </div>
          <div class="bg-magin-top">
            <br>
            <br>
            <br>
              <h3>項目案例展示</h3>
              <p class="p-h3">Project Display</p>
              <div class="projectContainer">
                <p class="title-bar">案例一：購物商城</p>
                <div class="mainContainer">
                  <div class="pj-photo1"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>購物商場項目是我深入學習前端開發技術的里程碑。在這個項目中，我運用HTML和CSS搭建網站結構和樣式，並通過JavaScript增強了用戶交互體驗。從商品展示到用戶操作流程，實現一個流暢且互動性強的購物平台。</p>
                    <a to="" class="disabled-link" >暫未發佈</a>
                  </div>
                </div>
              </div>
              <div class="projectContainer">
                <p class="title-bar">案例二：後台管理系統</p>
                <div class="mainContainer">
                  <div class="pj-photo2"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>後台管理系統項目是我在掌握Vue框架和Element UI庫方面專業技能時的項目。這個項目涵蓋了數據可視化、即時分析和複雜界面交互，透過現代化的前端技術，為用戶提供了一個直觀、高效的數據操作平台。</p>
                    <a to="" class="disabled-link" >暫未發佈</a>
                  </div>
                </div>
              </div>
          </div>
        </div>
             <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/DevelopBook/icon1.png'),
                name:'加速開發週期',
                content:'掌握前端技術能加快從構思到實現的開發週期。',
              },
              {
                id: 2,
                icon:require('@/assets/DevelopBook/icon2.png'),
                name:'項目可行性評估',
                content:'掌握前端知識使能夠在項目早期階段有效評估技術實施的可行性，這有助於節約資源並確保項目成功推進。',
              },
              {
                id: 3,
                icon:require('@/assets/DevelopBook/icon3.png'),
                name:'準確反饋問題',
                content:'通過理解前端開發的細節，能夠更精確地識別並描述遇到的技術問題，快速定位錯誤。',
              },
              {
                id: 4,
                icon:require('@/assets/DevelopBook/icon4.png'),
                name:'增強團隊協作',
                content:'了解前端開發能有效地與團隊成員協作，尤其是在跨功能團隊中。',
              },
            ],
      }
  }
}
  </script>
  <style lang="less" scoped>
    * {
        font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
        h1{
          font-family: 'AaErZhiYuan';
          font-weight: 500;
          position: relative;
          top: 100px;
          }
        h3{
          font-family: 'HuaKangFangYuan';
          font-size: 32px;
          margin-bottom: 10px;
          font-weight: 500;
          }
        h4{
          font-family: 'SourceSansPro-Regular';
          font-size: 22px;
          font-weight: 500;
          }
        .p-h3{
          font-family: 'HuaKangFangYuan';
          font-size: 16px;
          color: #666;
          font-weight: 500;
        }
        p {
          font-family: 'SourceSansPro-Regular';
          font-size: 18px;
          line-height: 32px;
          color: #444444;
          }
        .p-bg {
          position:relative;
          background-color: #f4fcff; /* 主要背景颜色 */
          margin-top: 40px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #5fa7e4;
            }
          }
          .icon {
            color: #81ACCF; /* 设置图标的颜色 */
            font-size: 18px; /* 设置图标的字号，可根据需要调整 */
            margin-right: 10px;
          }
          .Pbold{
              font-weight: 900;
          }
    }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }

    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .main-bg{
        width: 1200px;
        // background-color: #81ACCF;
        margin: 0 auto;
        .bg-magin-top{
          margin-top:120px
        }
        .p-magin-top{
          margin-top:30px
        }
        .cardContainer{
            margin-top: 80px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card{
                width: 300px;
                height: 400px;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .cardIcon{
                    width: 150px;
                    height: 150px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .cardName{
                    margin-top: 40px;
                }
                .cardContent{
                    padding: 0 30px;
                    margin-top: 30px;
                    color: #666;
                }
        }
      }
      .projectContainer{
        margin-bottom: 130px;
        .title-bar{
          margin-top: 60px;
          font-size: 24px;
          color: #fff;
          padding: 10px 0 10px 50px;
          border-radius: 50px;
          border: 3px solid #FFF;
          background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
        }
        .mainContainer{
          width: 100%;
          margin-top: 120px;
          display: flex;
          justify-content: space-between;
          .pj-photo1{
            width: 613px;
            height: 325px;
            margin-left: 80px;
            background-image: url('./assets/project1.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
          .pj-photo2{
            width: 613px;
            height: 325px;
            margin-left: 80px;
            background-image: url('./assets/project2.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
        .introContainer{
          .intro{
            width: 400px;
          }
          .pj-btn{
            text-decoration: none;
            color: #fff;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #FFB901;
            transition: background-color 0.3s;
            &:hover{
              background-color: rgba(255, 185, 1, 0.8);
            }
          }
        }
        .disabled-link {
            text-decoration: none;
            color: #fff;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #c8c9cc;
            transition: background-color 0.3s;
            &:hover{
              cursor: not-allowed; /* hover時顯示禁止的指針 */
            }
          }
        }
      }
    }
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>