<template>
  <div class="bodyContainer">
      <back :showButton="true" />
        <div class="main-bg">
            <h1 class="main-title">機器學習</h1>
            <h3>前言</h3>
            <p class="p-h3">Introduction</p>
            <p class="p-bg">機器學習在日常工作中極為重要，因為它能提高效率、優化決策過程並預測趨勢。透過自動化繁瑣的數據分析工作，機器學習讓專業人士能夠專注於更具創造性的任務，從而推動創新並增強競爭力。</p>
        <div class="bg-magin-top">
            <h3>個人技能</h3>
            <p class="p-h3">Personal Skill</p>
            <p class="p-bg">
              <span class="icon">❶</span>掌握机器学习算法：线性回归、逻辑回归、K-近邻、K-means、SVM、GBDT、XGBoost、CRF、PCA、SVD等等。<br>
              <span class="icon">❷</span>掌握Numpy、Pandas、Matplotlib、Scikit-learn、Pycaret等模块的使用。<br>
              <span class="icon">❸</span>使用Python進行編程。<br>
            </p>
        </div>
        <div class="bg-magin-top">
            <h3>日常工作中的應用</h3>
            <p class="p-h3">Application</p>
            <p class="p-magin-top">在日常工作中，我可以用機器學習進行：</p>
            <div class="cardContainer">
              <div v-for="card in cards" :key="card.id">
                <div class="card">
                  <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                  <h4 class="cardName">{{ card.name }}</h4>
                  <p class="cardContent">{{ card.content }}</p>
                </div>
              </div>

            </div>
        </div>
        <div class="bg-magin-top">
          <br>
          <br>
          <br>
            <h3>項目案例展示</h3>
            <p class="p-h3">Project Display</p>
            <div class="projectContainer">
              <p class="title-bar">案例一：貸款違約預測</p>
              <div class="mainContainer">
                <div class="pj-photo1"></div>
                <div class="introContainer">
                  <p class="intro"><span class="Pbold">簡介：</span>進行資料建模，預測使用者未來違約的機率，以幫助借貸者做出最佳的財務決策。</p>
                  <router-link to="/ProjectMenu/loanDefaultForecast" class="pj-btn" >查看項目詳情</router-link>
                </div>
              </div>
            </div>
            <div class="projectContainer">
              <p class="title-bar">案例二：二手車價格預估</p>
              <div class="mainContainer">
                <div class="pj-photo2"></div>
                <div class="introContainer">
                  <p class="intro"><span class="Pbold">簡介：</span>通過收集車行過往的二手車估價數據進行分析和訓練，最終根據用戶輸入的二手車型號特征數據，進行價格預估。</p>
                  <router-link to="/ProjectMenu/secondHandCarPrediction" class="pj-btn" >查看項目詳情</router-link>
                </div>
              </div>
            </div>
            <!-- <div class="projectPhoto"></div> -->
        </div>
      </div>
           <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
          cards:[
            {
              id: 1,
              icon:require('@/assets/MLBook/icon1.png'),
              name:'客戶流失預測',
              content:'透過分析客戶互動、購買歷史和行為模式，識別可能離開的客戶。',
            },
            {
              id: 2,
              icon:require('@/assets/MLBook/icon2.png'),
              name:'銷售額預測',
              content:'基於歷史數據和市場趨勢，預測未來的銷售額。',
            },
            {
              id: 3,
              icon:require('@/assets/MLBook/icon3.png'),
              name:'價格預測',
              content:'透過分析競爭對手定價、市場需求和成本變化來預測產品的最佳價格點。',
            },
            {
              id: 4,
              icon:require('@/assets/MLBook/icon4.png'),
              name:'風險預測',
              content:'評估潛在的市場風險、信貸風險或運營風險，減少損失的可能性，確保企業穩健運營。',
            },
          ],
    }
}
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        }
      h4{
        font-family: 'SourceSansPro-Regular';
        font-size: 22px;
        font-weight: 500;
        }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        line-height: 32px;
        color: #444444;
        }
      .p-bg {
        position:relative;
        background-color: #f4fcff; /* 主要背景颜色 */
        margin-top: 40px;
        padding: 30px 30px 30px 40px;
        border-radius: 5px;
        overflow: hidden;
        br{
          content: '';
          margin-bottom: 10px;
          display: block;
        }
        &::before{
            content:'';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 10px;
            background-color: #5fa7e4;
          }
        }
        .icon {
          color: #81ACCF; /* 设置图标的颜色 */
          font-size: 18px; /* 设置图标的字号，可根据需要调整 */
          margin-right: 10px;
        }
        .Pbold{
            font-weight: 900;
        }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      // background-color: #81ACCF;
      margin: 0 auto;
      .bg-magin-top{
        margin-top:120px
      }
      .p-magin-top{
        margin-top:30px
      }
      .cardContainer{
          margin-top: 80px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .card{
              width: 300px;
              height: 400px;
              margin-top: 80px;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              .cardIcon{
                  width: 150px;
                  height: 150px;
                  background-repeat: no-repeat;
                  background-size: contain;
              }
              .cardName{
                  margin-top: 40px;
              }
              .cardContent{
                  padding: 0 30px;
                  margin-top: 30px;
                  color: #666;
              }
      }
    }
    .projectContainer{
      margin-bottom: 130px;
      .title-bar{
        margin-top: 60px;
        font-size: 24px;
        color: #fff;
        padding: 10px 0 10px 50px;
        border-radius: 50px;
        border: 3px solid #FFF;
        background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
      }
      .mainContainer{
        width: 100%;
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        .pj-photo1{
          width: 611px;
          height: 311px;
          margin-left: 80px;
          background-image: url('./assets/project1.png');
          background-repeat: no-repeat;
          background-size: contain;
          }
        .pj-photo2{
          width: 611px;
          height: 311px;
          margin-left: 80px;
          background-image: url('./assets/project2.png');
          background-repeat: no-repeat;
          background-size: contain;
          }
      .introContainer{
        .intro{
          width: 400px;
          margin-top: 60px;
        }
        .pj-btn{
          text-decoration: none;
          color: #fff;
          font-family: 'HuaKangFangYuan';
          font-size: 22px;
          width: 200px;
          height: 55px;
          margin-top: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          background-color: #FFB901;
          transition: background-color 0.3s;
          &:hover{
            background-color: rgba(255, 185, 1, 0.8);
          }
        }
      }
      }
    }
    .projectPhoto{
      width: 100%;
      height: 510px;
      margin-left: 80px;
      background-image: url('./assets/project1-photo.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>