<template>
    <div class="bgContainer">
      <div class="sentence-container">
        <div class="sentence1"></div>
        <div class="sentence2"></div>
        <div class="sentence3"></div>
      </div>
        <router-link to="/SkillMenu/CFABook">
            <div class="CFA"></div>
        </router-link>
        <router-link to="/SkillMenu/OperationMGTBook">
            <div class="ActivityPlanning"></div>
        </router-link>
        <router-link to="/SkillMenu/ProductThinkingBook">
            <div class="productThinking"></div>
        </router-link>
        <router-link to="/SkillMenu/DataAnalysisBook">
            <div class="dataAnalysis"></div>
        </router-link>
        <router-link to="/SkillMenu/MLBook">
            <div class="ML"></div>
        </router-link>
        <router-link to="/SkillMenu/DevelopBook">
            <div class="itDevelopment"></div>
        </router-link>
        <!-- 左下角导航栏 -->
        <backBtn @hover-start="overlay=true" @hover-end="overlay= false" /> 
        <div class="overlay" :style="{opacity:overlay ? 1 : 0}"></div>
        <div v-if="isBookOpen" class="modal">
          <!-- 模态窗口内容 -->
          <button @click="closeBook">关闭</button>
        </div>
    </div>
</template>

<script>
import backBtn from '@/components/backBtn/index.vue'

export default {
    components: {
      backBtn,
    },
    data(){
        return{
            overlay:false,
            isBookOpen: false,
        }
    },
    mounted() {
    this.preloadImages([
      './assets/CFA-Hover.png',
      './assets/ActivityPlanning-Hover.png',
      './assets/productThinking-Hover.png',
      './assets/dataAnalysis-Hover.png',
      './assets/ML-Hover.png',
      './assets/IT-Hover.png'
      ]);
    },
    methods: {
      openBook(){
        this.isBookOpen = true;
        // 这里添加移动书本到中心的逻辑
      },
      closeBook(){
        this.isBookOpen = false;
        // 这里添加恢复原本页面的逻辑
      },
      preloadImages(imageUrls) {
        imageUrls.forEach(url => {
          const img = new Image();
          img.src = url;
        });
      }
    }
  }
</script>

<style lang="less" scoped>
.bgContainer {
  width: 100vw; // 设置为视口宽度
  min-height: 100vh; /* 使用min-height而非height确保至少100%视口高度 */
  background-image: url('./assets/skill-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

// 简介
.sentence-container{
  width: 80vw;
  height: 80vh;
  position: relative;
  top: 10vh;
  left: 16vh;

  .sentence1{
    width: 100%;
    height: 8vh;
    background-image: url('./assets/sentence1.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .sentence2{
    width: 100%;
    height: 8vh;
    background-image: url('./assets/sentence2.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .sentence3{
    width: 100%;
    height: 5vh;
    margin-top: 1.5vh;
    background-image: url('./assets/sentence3.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.CFA {
  width: 21vh;
  height: 39vh;
  position: absolute;
  top: 55vh;
  left: 16vh;
  display:block;
  background-image: url('./assets/CFA.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/CFA-Hover.png');
    transform: translateY(-14vh);
  }
}

.ActivityPlanning{
  width: 21vh;
  height: 39vh;
  position: absolute;
  top: 55vh;
  left: 29vh;
  display:block;
  background-image: url('./assets/ActivityPlanning.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/ActivityPlanning-Hover.png');
    transform: translateY(-12vh);
  }
}

.productThinking{
  width: 21vh;
  height: 38vh;
  position: absolute;
  top: 56vh;
  left: 42vh;
  display:block;
  background-image: url('./assets/productThinking.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/productThinking-Hover.png');
    transform: translateY(-10vh);
  }
}

.dataAnalysis {
  width: 19vh;
  height: 35vh;
  position: absolute;
  top: 59vh;
  left: 56vh;
  display:block;
  background-image: url('./assets/dataAnalysis.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/dataAnalysis-Hover.png');
    transform: translateY(-10vh);
  }
}

.ML{
  width: 19vh;
  height: 35vh;
  position: absolute;
  top: 59vh;
  left: 69vh;
  display:block;
  background-image: url('./assets/ML.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/ML-Hover.png');
    transform: translateY(-10vh);
  }
}

.itDevelopment{
  width: 19vh;
  height: 35vh;
  position: absolute;
  top: 59vh;
  left: 82vh;
  display:block;
  background-image: url('./assets/IT.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-image: url('./assets/IT-Hover.png');
    transform: translateY(-10vh);
  }
}

// hover遮罩
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none; /*確保遮罩層不干擾下面的元素交互*/
}

</style>