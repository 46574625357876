import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../Pages/HomePage.vue'; // 这是您的首页组件
import BriefMenu from '../Pages/BriefPage'
import ProjectMenu from '../Pages/ProjectPage'
import battleActivity from '@/Pages/ProjectPage/battleActivity'
import tradingSystem from '@/Pages/ProjectPage/tradingSystem'
import promotionTools from '@/Pages/ProjectPage/PromotionTools'
import loanDefaultForecast from '@/Pages/ProjectPage/loanDefaultForecast'
import secondHandCarPrediction from '@/Pages/ProjectPage/secondHandCarPrediction'
import taobaoAnalysis from '@/Pages/ProjectPage/taobaoAnalysis'
import DataVisualization from '@/Pages/ProjectPage/DataVisualization'
import RFM from '@/Pages/ProjectPage/RFM'
import ABTest from '@/Pages/ProjectPage/ABTest'
import SkillMenu from '../Pages/SkillPage'
import CFABook from '@/Pages/SkillPage/CFABook'
import OperationMGTBook from '@/Pages/SkillPage/OperationMGTBook'
import ProductThinkingBook from '@/Pages/SkillPage/ProductThinkingBook'
import DevelopBook from '@/Pages/SkillPage/DevelopBook'
import DataAnalysisBook from '@/Pages/SkillPage/DataAnalysisBook'
import MLBook from '@/Pages/SkillPage/MLBook'
import ContactMenu from '../Pages/ContactPage'
// ... 其他组件的导入


const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: HomePage // 显示 MyBanner 和 MyHome
  },
  {
    path: '/BriefMenu',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: BriefMenu
  }, 
  {
    path: '/ProjectMenu',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: ProjectMenu,
  }, 
  {
    path: '/ProjectMenu/battleActivity',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: battleActivity,
  }, 
  {
    path: '/ProjectMenu/tradingSystem',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: tradingSystem,
  }, 
  {
    path: '/ProjectMenu/promotionTools',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: promotionTools,
  }, 
  {
    path: '/ProjectMenu/loanDefaultForecast',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: loanDefaultForecast,
  }, 
  {
    path: '/ProjectMenu/secondHandCarPrediction',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: secondHandCarPrediction,
  }, 
  {
    path: '/ProjectMenu/taobaoAnalysis',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: taobaoAnalysis,
  }, 
  {
    path: '/ProjectMenu/DataVisualization',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: DataVisualization,
  }, 
  {
    path: '/ProjectMenu/RFM',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: RFM,
  }, 
  {
    path: '/ProjectMenu/ABTest',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: ABTest,
  }, 
  {
    path: '/SkillMenu',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: SkillMenu
  },  
  {
    path: '/SkillMenu/CFABook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: CFABook
  },  
  {
    path: '/SkillMenu/OperationMGTBook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: OperationMGTBook
  },  
  {
    path: '/SkillMenu/ProductThinkingBook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: ProductThinkingBook
  },  
  {
    path: '/SkillMenu/DataAnalysisBook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: DataAnalysisBook
  },  
  {
    path: '/SkillMenu/MLBook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: MLBook
  },  
  {
    path: '/SkillMenu/DevelopBook',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: DevelopBook
  },  
  {
    path: '/ContactMenu',
    meta: { title: 'Leo Chum 作品集網站  |  開啟探索之旅  (づ′▽`)づ'},
    component: ContactMenu
  },  
  // ... 其他路由的定义
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // 如果存在保存的位置，则返回该位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { top: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title //轉title
  next()
})


export default router
