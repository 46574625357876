<template>
    <div class="bodyContainer">
        <back :showButton="true" />
        <div class="bg1"></div>
          <div class="main-bg">
            <!-- 活動背景 -->
            <div class="bg2">
                <div class="bg2-title"></div>
                <div class="bg2-png"></div>
                <p class="bg2-p">在金融市場的洪流中，創富盈匯平台悉心策劃「創富PK爭霸賽」，一場旨於激發交易者潛能，提升交易手數的競技盛會。這不僅是一次智力與策略的較量，更是資金與勇氣的試煉。<br/><br/>在這裡，每一位參賽者都將在公平的交易賽場上，展現自己的交易藝術，爭奪至高無上的榮耀與豐厚的獎金。創富盈匯呼籲各位英勇的交易者，拿起武器，加入這場盛大的財經節慶。</p>
                <div class="bg2-btn">活動目標</div>
                <ul class="bg2-ul">
                    <li>提高平台的交易量</li>
                    <li>用戶活躍度</li>
                    <li>提升曝光</li>
                </ul>
            </div>
            <!-- 用戶分析 -->
            <div class="bg3">
                <div class="bg3-title"></div>
                <div class="bg3-png"></div>
                <h2>人群分析</h2>
                <p class="bg3-p">分析數據顯示，潛在參賽者多為追求金融自由的中青年交易者，年齡層主要集中在25至45歲之間。此類用戶通常具有中等至高等的投資知識，熱衷於利用科技平台提升其交易效率和收益。<br/><br/>從性別比例來看，男性用戶佔比約65%，女性用戶佔比約35%，這表明男性用戶在交易活動中更為活躍。然而，女性用戶的增長速度在過去一年有顯著提升，顯示交易市場的性別多樣化趨勢。</p>
            </div>
        </div>
        <!-- 用戶畫像 -->
        <div class="bg4">
            <div class="bg4-bg">
              <h2>用戶畫像</h2>
              <div class="cardContainer"></div>
            </div>
        </div>
        <div class="main-bg">
          <!-- 活動玩法 -->
        <div class="bg5">
            <div class="bg5-title"></div>
            <div class="bg5-subTitleIcon"></div>
            <p class="bg5-p">活動期間每個交易日1V1隨機配對對手進行實盤比賽，得分較高者贏得當日積分X2倍獎勵，透過計算活動期間累積每日積分進行排名，活動結束時首10名勝出者可獲得豐厚獎金。</p>
            <div class="bg5-btn1Container">
                <div class="bg5-btn1">活動對象 </div>
                <p>全平台用戶</p>
            </div>
            <div class="bg5-btn2Container">
                <div class="bg5-btn2">報名時間 </div>
                <p>開賽前4天</p>
            </div>
            <div class="bg5-btn3Container">
                <div class="bg5-btn3">比賽時間 </div>
                <p>持續一個月</p>
            </div>
            <p class="bg5-tips">*策略：提前报名时间是为了给平台充足的时间来宣传活动，增加活动的曝光率。此外，还可以帮助潜在参与者准备参加活动，包括资金调配和交易策略规划。</p>
            <h2>活動特色</h2>
            <div class="bg5-cardContainer">
                <div class="bg5-card1">
                    <div class="bg5-cardIcon1"></div>
                    <div class="bg5-cardTitle">每日隨機匹配對手</div>
                    <p class="bg5-cardcontent1">確保每位參賽者每天都能被隨機配對，並與不同程度的交易者進行1對1的實盤交易比賽。</p>
                    <div class="card-box1">
                        <div class="box-title">構思</div>
                        <p class="box-content">這種方式不僅保證了比賽的公平性，還增加了比賽的不確定性和挑戰性，讓參賽者每天都充滿期待。</p>
                    </div>
                </div>
                <div class="bg5-card2">
                    <div class="bg5-cardIcon2"></div>
                    <div class="bg5-cardTitle2">1vs1 對戰</div>
                    <p class="bg5-cardcontent2">真實的交易環境，參賽者將在一對一的對決中，利用自己的交易策略和市場分析，與對手進行公開的交易比拼。</p>
                    <div class="card-box2">
                        <div class="box-title">構思</div>
                        <p class="box-content">這樣的對決模式將鼓勵參賽者更專注於市場分析和交易技巧的提升，同時也為平台帶來更高的交易活躍度。</p>
                    </div>
                </div>
                <div class="bg5-card3">
                    <div class="bg5-cardIcon3"></div>
                    <div class="bg5-cardTitle">積分X2</div>
                    <p class="bg5-cardcontent3">為了提升比賽的激勵機制，勝利者將獲得當日積分的兩倍獎勵，這不僅是對勝利者的獎賞，也激勵所有參賽者積極參與每一場比賽。</p>
                    <div class="card-box3">
                        <div class="box-title">構思</div>
                        <p class="box-content">這項規則的設定旨在鼓勵交易者不僅關注單筆交易的盈虧，而是整體的交易表現和策略的長期有效性。</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 活動流程圖 -->
        <div class="bg6">
            <div class="bg6-title"></div>
            <div class="bg6-png"></div>
        </div>
        <!-- 活動原型圖 -->
        <div class="bg7">
            <div class="bg7-title"></div>
            <div class="bg7-png"></div>
            <h2 class="bg7-h2">場景提示</h2>
            <div class="table">
                <div class="row header">
                    <div class="cell tableTitle">場景</div>
                    <div class="cell tableTitle">内容</div>
                </div>
                <div class="row">
                    <div class="cell celltitle">報名成功</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp下個交易日系統將為您配對對手，祝您旗開得勝！</div>
                </div>
                <div class="row">
                    <div class="cell celltitle">首日對戰無記錄</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp顯示 （圖標 + 暫無記錄） 提示 </div>
                </div>
                <div class="row">
                    <div class="cell celltitle">領先對手</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp真棒，您暫時領先對手 xxx 美元 盈利！ </div>
                </div>
                <div class="row">
                    <div class="cell celltitle">落後對手</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp您暫時落後對手 xxx 美元 獲利！ </div>
                </div>
                <div class="row">
                    <div class="cell celltitle">雙方平手</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp雙方暫時打平，再努力一點就能打敗對方了！ </div>
                </div>
                <div class="row">
                    <div class="cell celltitle">休市中</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp下個交易日系統將繼續為您配對對手！ </div>
                </div>
                <div class="row">
                    <div class="cell celltitle">比賽結束</div>
                    <div class="cell cellContent">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp本次活動已結束，感謝您的參與！ </div>
                </div>
            </div>
        </div>
        <!-- 活動推廣 -->
        <div class="bg8">
            <div class="bg8-title"></div>
            <div class="promotion-box">
              <div class="bg8-internal"></div>
             <div class="bg8-external"></div>
            </div>
        </div>
        <!-- 活動預期效果 -->
        <div class="bg9">
            <div class="bg9-title"></div>
            <p class="bg9-p">透過「創富PK爭霸賽」，我們預計將達到以下效果：</p>
            <div class="bg9-cardbox">
              <div class="bg9-card1"></div>
              <div class="bg9-card2"></div>
              <div class="bg9-card3"></div>
              <div class="bg9-card4"></div>
            </div>
        </div>
        </div>
             <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/MLBook/icon1.png'),
                name:'客戶流失預測',
                content:'透過分析客戶互動、購買歷史和行為模式，識別可能離開的客戶。',
              },
              {
                id: 2,
                icon:require('@/assets/MLBook/icon2.png'),
                name:'銷售額預測',
                content:'基於歷史數據和市場趨勢，預測未來的銷售額。',
              },
              {
                id: 3,
                icon:require('@/assets/MLBook/icon3.png'),
                name:'價格預測',
                content:'透過分析競爭對手定價、市場需求和成本變化來預測產品的最佳價格點。',
              },
              {
                id: 4,
                icon:require('@/assets/MLBook/icon4.png'),
                name:'風險預測',
                content:'評估潛在的市場風險、信貸風險或運營風險，減少損失的可能性，確保企業穩健運營。',
              },
            ],
      }
  }
  }
  </script>
  <style lang="less" scoped>
    * {
        font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
        h1{
          font-family: 'AaErZhiYuan';
          font-weight: 500;
          position: relative;
          top: 100px;
          }
        h3{
          font-family: 'HuaKangFangYuan';
          font-size: 32px;
          margin-bottom: 10px;
          font-weight: 500;
          }
        h4{
          font-family: 'SourceSansPro-Regular';
          font-size: 22px;
          font-weight: 500;
          }
        .p-h3{
          font-family: 'HuaKangFangYuan';
          font-size: 16px;
          color: #666;
          font-weight: 500;
        }
        p {
          font-family: 'SourceSansPro-Regular';
          font-size: 18px;
          line-height: 32px;
          color: #444444;
          }
          .Pbold{
              font-weight: 900;
          }
    }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }
  
    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .bg1{
        position: relative;
        height: 850px;
        background-image: url('./assets/bg1.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .main-bg{
        width: 1200px;
        // background-color: #81ACCF;
        margin: 0 auto;
        .bg2{
            position: relative;
            height: auto;
            .bg2-title{
                height: 100px;
                width: 220px;
                background-image: url('./assets/bg2-title.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
            .bg2-png{
                position: absolute;
                top: 190px;
                right:10px;
                height: 300px;
                width: 570px;
                background-image: url('./assets/bg2-png.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
            .bg2-p{
                margin-top: 50px;
                width: 42%;
            }
            .bg2-btn{
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                justify-content: center; /* 水平居中子元素 */
                width: 150px;
                height: 50px;
                margin-top: 50px;
                border-radius: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #356CD7;
                background: linear-gradient(180deg, #97BDFE 0%, rgba(151, 189, 254, 0.44) 100%);
            }
            .bg2-ul{
                margin-top: 20px;
                margin-left: 20px;
                font-size: 18px;
                color: #666;
            }
        }
        .bg3{
            position: relative;
            margin-top: 120px;
            .bg3-title{
                height: 100px;
                width: 220px;
                background-image: url('./assets/bg3-title.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
            .bg3-png{
                position: absolute;
                top: 230px;
                right:10px;
                height: 300px;
                width: 570px;
                background-image: url('./assets/bg3-png.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
            h2{
                margin-top: 50px;
            }
            .bg3-p{
                margin-top: 50px;
                width: 42%;
            }
        }

        .bg-magin-top{
          margin-top:120px
        }
        .p-magin-top{
          margin-top:30px
        }
        .cardContainer{
            margin-top: 80px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card{
                width: 300px;
                height: 400px;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .cardIcon{
                    width: 150px;
                    height: 150px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .cardName{
                    margin-top: 40px;
                }
                .cardContent{
                    padding: 0 40px;
                    margin-top: 30px;
                    color: #666;
                }
        }
      }
      .projectContainer{
        margin-bottom: 130px;
        .title-bar{
          margin-top: 60px;
          font-size: 24px;
          color: #fff;
          padding: 10px 0 10px 50px;
          border-radius: 50px;
          border: 3px solid #FFF;
          background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
        }
        .mainContainer{
          width: 100%;
          margin-top: 120px;
          display: flex;
          justify-content: space-between;
          .pj-photo1{
            width: 611px;
            height: 311px;
            margin-left: 80px;
            // background-image: url('./assets/project1.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
        .introContainer{
          .intro{
            width: 400px;
            margin-top: 60px;
          }
          .pj-btn{
            text-decoration: none;
            color: #000;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #FFB901;
            transition: background-color 0.3s;
            &:hover{
              background-color: rgba(255, 185, 1, 0.8);
            }
          }
        }
        }
      }
      .projectPhoto{
        width: 100%;
        height: 510px;
        margin-left: 80px;
        // background-image: url('./assets/project1-photo.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .bg4{
            margin-top: 120px;
            width: 100vw;
            height: 830px;
            background-image: url('./assets/bg4.png');
            background-repeat: no-repeat;
            background-size: cover;
            .bg4-bg{
              width: 1200px;
              margin: 0 auto;
            }
            h2{
              margin-top: 30px;
            }
            .cardContainer{
              margin-top: 130px;
                width: 100%;
                height: 900px;
                background-image: url('./assets/bg4-card4.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    .bg5{
        position: relative;
        height: auto;
        margin-top: 80px;
        .bg5-title{
            height: 100px;
            width: 220px;
            background-image: url('./assets/bg5-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-subTitleIcon{
          margin-top: 50px;
            height: 90px;
            width: 90px;
            background-image: url('./assets/bg5-subTitleIcon.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-p{
            position: relative;
            top: -80px;
            left: 140px;
            width: 85%;
        }
        .bg5-btn1Container{
            display: flex;
            position: relative;
            top: 50px;
            left: 15px;
            .bg5-btn1{
                padding: 10px 10px;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                justify-content: center; /* 水平居中子元素 */
                width: 150px;
                height: 50px;
                border-radius: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #356CD7;
                background: linear-gradient(180deg, #97BDFE 0%, rgba(151, 189, 254, 0.44) 100%);
            }
            p{
                position: relative;
                color: #666;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                font-size: 24px;
                margin-left: 30px;
            }
        }
        .bg5-btn2Container{
            display: flex;
            position: relative;
            top: 100px;
            left: 15px;
            .bg5-btn2{
                padding: 10px 10px;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                justify-content: center; /* 水平居中子元素 */
                width: 150px;
                height: 50px;
                border-radius: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #356CD7;
                background: linear-gradient(180deg, #97BDFE 0%, rgba(151, 189, 254, 0.44) 100%);
            }
            p{
              position: relative;
                color: #666;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                font-size: 24px;
                margin-left: 30px;
            }
        }
        .bg5-btn3Container{
            display: flex;
            position: relative;
            top: -50px;
            left: 800px;
            .bg5-btn3{
                padding: 10px 10px;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                justify-content: center; /* 水平居中子元素 */
                width: 150px;
                height: 50px;
                border-radius: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #356CD7;
                background: linear-gradient(180deg, #97BDFE 0%, rgba(151, 189, 254, 0.44) 100%);
            }
            p{
                position: relative;
                color: #666;
                display: flex; /* 开启Flexbox布局 */
                align-items: center; /* 垂直居中子元素 */
                font-size: 24px;
                margin-left: 30px;
            }
        }
        .bg5-tips{
            position: relative;
            top: 100px;
            color: #999;
            display: flex; /* 开启Flexbox布局 */
            align-items: center; /* 垂直居中子元素 */
            font-size: 22px;
            margin-left: 30px;
        }
        h2{
            margin-top: 200px;
        }
        .bg5-cardContainer{
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 180px;
            height: 550px;
            width: 100%;
            .bg5-card1, .bg5-card2, .bg5-card3{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 350px;
                height: 100%;
                border-radius: 20px;
                background-color: #F0F6FE;
                .bg5-cardIcon1{
                    position: relative;
                    top: -100px;
                    width: 150px;
                    height: 150px;
                    background-image: url('./assets/bg5-cardIcon1.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .bg5-cardIcon2{
                    position: relative;
                    top: -110px;
                    width: 180px;
                    height: 180px;
                    background-image: url('./assets/bg5-cardIcon2.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .bg5-cardIcon3{
                    position: relative;
                    top: -100px;
                    width: 150px;
                    height: 150px;
                    background-image: url('./assets/bg5-cardIcon3.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .bg5-cardTitle{
                    position: relative;
                    top: -75px;
                    height: 50px;
                    font-size: 28px;
                    font-weight: 500;
                    font-family: 'HuaKangFangYuan','PingFang SC';
                }
                .bg5-cardTitle2{
                    position: relative;
                    top: -105px;
                    height: 50px;
                    font-size: 28px;
                    font-weight: 500;
                    font-family: 'HuaKangFangYuan','PingFang SC';
                }
                .bg5-cardcontent1{
                    position: relative;
                    top: -50px;
                    padding: 0 40px;
                    color: #666;
                    font-size: 20px;
                }
                .bg5-cardcontent2{
                    position: relative;
                    top: -75px;
                    padding: 0 40px;
                    color: #666;
                    font-size: 20px;
                }
                .bg5-cardcontent3{
                    position: relative;
                    top: -50px;
                    padding: 0 40px;
                    color: #666;
                    font-size: 20px;
                }
                .card-box1{
                    position: relative;
                    bottom: -15px;
                    height: 230px;
                    width: 90%;
                    border-radius: 15px;
                    background-color: #F1FEF9;
                    .box-title{
                        margin: 20px;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: center;
                        font-family: 'HuaKangFangYuan','PingFang SC';
                    }
                    .box-content{
                    padding: 0 30px;
                    color: #666;
                    font-size: 20px;
                    }
                }
                .card-box2{
                    position: relative;
                    bottom: 30px;
                    height: 230px;
                    width: 90%;
                    border-radius: 15px;
                    background-color: #F1FEF9;
                    .box-title{
                        margin: 20px;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: center;
                        font-family: 'HuaKangFangYuan','PingFang SC';
                    }
                    .box-content{
                    padding: 0 30px;
                    color: #666;
                    font-size: 20px;
                    }
                }
                .card-box3{
                    position: relative;
                    bottom: 25px;
                    height: 230px;
                    width: 90%;
                    border-radius: 15px;
                    background-color: #F1FEF9;
                    .box-title{
                        margin: 20px;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: center;
                        font-family: 'HuaKangFangYuan','PingFang SC';
                    }
                    .box-content{
                    padding: 0 30px;
                    color: #666;
                    font-size: 20px;
                    }
                }
            }
        }
    }
    .bg6{
        height: auto;
        margin-top: 130px;
        .bg6-title{
            height: 100px;
            width: 220px;
            margin-bottom: 130px;
            background-image: url('./assets/bg6-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg6-png{
            height: 500px;
            width: 100%;
            background-image: url('./assets/flow.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .bg7{
        margin-top: 130px;
        .bg7-title{
            height: 100px;
            width: 220px;
            margin-bottom: 100px;
            background-image: url('./assets/bg7-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg7-png{
            height: 1210px;
            width: 100%;
            background-image: url('./assets/bg7-wireframe.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg7-h2{
            margin-top: 180px;
        }
        .table {
          margin-top: 50px;
            width: 100%;
            border-collapse: collapse;
            background-color: #f0f8ff; /* 浅蓝色背景 */
            .tableTitle{
                font-weight: 700;
                text-align: center;
            }
            .header {
                background-color: #e0eeee; /* 较深的蓝绿色背景 */
                .cell {
                border-top: none;
                border-bottom: 2px solid #a9a9a9; /* 深灰色边框 */
                font-weight: bold; /* 加粗字体 */
                }
            }

            .row {
                display: flex;
            }

            .cell {
                flex: 1; /* 两个单元格等宽 */
                padding: 10px; /* 或根据您的设计调整 */
                border: 1px solid #d3d3d3; /* 浅灰色边框 */
                font-size: 2.5vh;
            }
            .cell:first-child {
                flex: 1; /* 左侧栏目更短 */
            }

            .cell:last-child {
                flex: 3; /* 右侧栏目更长 */
            }
            .celltitle{
                text-align: center;
            }
            .cellContent{
                color: #666;
            }
        }
    }
    .bg8{
        margin-top: 130px;
        .bg8-title{
            margin-bottom: 130px;
            height: 110px;
            width: 220px;
            background-image: url('./assets/bg8-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .promotion-box{
          display: flex;
          justify-content: space-between;
          .bg8-internal{
              height: 45vh;
              width: 90vh;
              background-image: url('./assets/bg8-internal.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg8-external{
              height: 45vh;
              width: 90vh;
              background-image: url('./assets/bg8-external.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
        }
    }
    .bg9{
        margin-top: 130px;
        .bg9-title{
            height: 110px;
            width: 220pxvh;
            background-image: url('./assets/bg9-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg9-p{
            margin-left:20px ;
            margin-top:20px ;
        }
        .bg9-cardbox{
          margin-top: 80px;
          display: flex;
          justify-content: space-between;
          .bg9-card1{
              height: 380px;
              width: 300px;
              background-image: url('./assets/bg9-card1.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg9-card2{
              height: 380px;
              width: 300px;
              background-image: url('./assets/bg9-card2.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg9-card3{
              height: 380px;
              width: 300px;
              background-image: url('./assets/bg9-card3.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg9-card4{
              height: 380px;
              width: 300px;
              background-image: url('./assets/bg9-card4.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
        }
    }
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>