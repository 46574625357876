<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="main-bg">
          <div class="bg1">
              <h1 class="main-title">二手車價格預測</h1>
              <h3>項目背景</h3>
              <h6>PROJECT BACKGROUND</h6>
              <p class="bg1-p">進行資料建模來預測二手車的售賣價格，為車主提供一個快速且精確的價格評估工具。這項技術可以協助車主理解市場動態，使他們能夠根據車況、品牌、行駛里程等多種因素，做出合理的售價決策。這不僅提高了交易效率，也增加了交易透明度，幫助買賣雙方達成公平的交易。</p>
              <div class="bg1-image"></div>
          </div>
          <div class="bg2">
              <h3>數據說明</h3>
              <h6>DATA DESCRIPTION</h6>
              <p><span class="Pbold">數據來源：</span> 第三方</p>
              <p><span class="Pbold">數據大小：</span> 8128條數據</p>
              <p><span class="Pbold">字段名稱描述：</span></p>
              <el-table
                :data="tableData"
                stripe
                border
                style="font-size: 18px;"
                class="tableStyle">
                <el-table-column
                  prop="name"
                  label="列名稱"
                  width="400">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="說明">
                </el-table-column>
              </el-table>
          </div>
          <div class="bg3">
              <h3>數據讀取、初探</h3>
              <h6>DATA READING & EXPLORATION</h6>
              <h4>一、數據讀取及基本描述總覽</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 导入需要的库
              import pandas as pd
              import numpy as np
              import seaborn as sns
              import matplotlib.pyplot as plt
              from sklearn.preprocessing import OneHotEncoder, StandardScaler
              from sklearn.model_selection import train_test_split

              import warnings
              warnings.filterwarnings('ignore')
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              df = pd.read_csv('./car_price.csv')
              df
                  </code>
              </pre>
              <br>
              <div class="bg3-image"></div>
              <br>
              <p>通過上表可見，字段mileage、engine、max_power和torque都包含數值和字符串，需要進行處理才可以進行分析及對比。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看數據集的結構及類型
              print(df.info())

              // 查看數據集的缺失值
              print(df.isnull().sum())
                  </code>
              </pre>
              <br>
              <div class="bg3-container">
              <pre v-pre>
              &lt;class 'pandas.core.frame.DataFrame'&gt;
              RangeIndex: 8128 entries, 0 to 8127
              Data columns (total 13 columns):
              #   Column         Non-Null Count  Dtype  
              ----------------------------  
              0           name                                 8128 non-null              object 
              1           year                                    8128 non-null              int64  
              2           selling_price                  8128 non-null              int64  
              3           km_driven                      8128 non-null              int64  
              4           fuel                                     8128 non-null              object 
              5           seller_type                     8128 non-null              object 
              6           transmission                 8128 non-null              object 
              7           owner                               8128 non-null              object 
              8           mileage                           7907 non-null              object 
              9           engine                             7907 non-null              object 
              10         max_powe                    7913 non-null              object 
              11         torque                             7906 non-null              object 
              12         seats                                7907 non-null              float64
              dtypes: float64(1), int64(3), object(9)
              memory usage: 825.6+ KB
              None
              ---------------------------- 
              </pre>
              <div class="bg3-image2"></div>
            </div>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;根據上方左邊圖表顯示，字段中的mileage、engine、max_power和torque顯示為object，導致無法進行統計，需要轉化成數值類型。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;根據上方有圖顯示，字段中的mileage、engine、max_power、torque和seats都存在缺失值，需要進行填補或刪除處理。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>二、數據處理</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 刪除有缺失值的行
              df.dropna(inplace=True)

              // 刪除Name列
              df.drop(columns=['name'],inplace=True)

              // 將字段mileage、engine 和 max_power先根據空格進行分割，然後取第 0 個（既第一個位置的數值）
              df['mileage'] = df['mileage'].map(lambda x : float(x.split(' ')[0]))
              df['engine'] = df['engine'].map(lambda x : float(x.split(' ')[0]))
              df['max_power'] = df['max_power'].map(lambda x : float(x.split(' ')[0]))

              // 引入“re”模塊，解決從 'torque' 列的每個條目中提取最大的數字（去掉了千分位的逗號），並將提取到的最大數字替換原來列中的值。 假設
              這些值代表每分鐘轉數（rpm），這樣處理後的數據更有利於後續分析。
              import re

              def parse_rpm(x):
                  y = x.replace(',','')
                  return max(float(x) for x in re.findall('\d+',y))

              df['torque'] = df['torque'].map(parse_rpm)

              // 轉换列的類型為 float64
              for column in ['mileage', 'engine', 'max_power', 'torque']:
                  df[column] = df[column].astype('float64')

              // 查看處理後的數據詳情
              print(df.info())

              // 查看最新數據列表
              df
                  </code>
              </pre>
              <br>
              <pre v-pre>
              &lt;class 'pandas.core.frame.DataFrame'&gt;
              RangeIndex: 7906 entries, 0 to 8127
              Data columns (total 12 columns):
              #   Column         Non-Null Count  Dtype  
              ----------------------------  
              0           name                                 7906 non-null              object 
              1           year                                    7906 non-null              int64  
              2           selling_price                  7906 non-null              int64  
              3           km_driven                      7906 non-null              int64  
              4           fuel                                     7906 non-null              object 
              5           seller_type                     7906 non-null              object 
              6           transmission                 7906 non-null              object 
              7           owner                               7906 non-null              object 
              8           mileage                           7906 non-null              object 
              9           engine                             7906 non-null              object 
              10         max_powe                    7906 non-null              object 
              11         torque                             7906 non-null              object 
              12         seats                                7906 non-null              float64
              dtypes: float64(1), int64(3), object(8)
              memory usage: 803.0+ KB
              None
              ---------------------------- 
              </pre>
              <div class="bg3-image3"></div>
              <br>
              <br>
              <br>
              <br>
              <h4>三、數據字段分佈圖、關係熱力圖</h4>
              <h5>柱形分佈圖</h5>
              <p>輸出各字段分佈圖，進行分析：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製各字段柱形圖
              plt.figure(figsize=(20,20), dpi=300)
              plt.subplots_adjust(wspace=0.3, hspace=0.3)

              for n,i in enumerate(df.columns):
                  plt.subplot(4,3,n+1)
                  plt.title(i,fontsize=15)
                  plt.grid(linestyle='--')
                  df[i].hist(color='grey',alpha=0.5)
                  </code>
              </pre>
              <br>
              <div class="bg3-image4"></div>
              <br>
              <br>
              <br>
              <p>根據以上圖片顯示，我們可以看到汽車相關數據集中不同特徵的分布。下面是基於這些圖的觀察點：</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">年份（year）：</span>大多數汽車的年份集中在2000年之後，尤其是2010年以後的汽車數量最多，顯示較新的車輛在數據集中較為常見。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">售價（selling_price）：</span>里程數主要集中在10萬公里以下，很少有行駛里程超過15萬公里的汽車。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">行駛里程（km_driven）：</span>里程數主要集中在10萬公里以下，很少有行駛里程超過15萬公里的汽車。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">燃料類型（fuel）：</span>柴油（Diesel）和汽油（Petrol）是兩種主要的燃料類型，其中汽油使用較為普遍。液化石油氣（LPG）和壓縮天然氣（CNG）車輛較少。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">賣家類型（seller_type）：</span>個人賣家（Individual）的汽車數量遠多於經銷商（Dealer）和認證經銷商（Trustmark Dealer）。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">變速箱（transmission）：</span>手動變速箱（Manual）的汽車數量遠多於自動變速箱（Automatic）。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">車主數量（owner）：</span>第一任車主（First Owner）的汽車最多，隨著車主數量的增加，汽車數量遞減。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">里程數（mileage）：</span>大多數汽車的里程數分布在 10 到 20 kmpl 之間。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">引擎排量（engine）：</span>大部分汽車的引擎排量在 1000 到 1500 cc 之間。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">最大功率（max_power）：</span>大多數汽車的最大功率集中在 50 到 100 馬力之間。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">扭力（torque）：</span>汽車的扭矩值分布範圍廣泛，多數集中在 0 到 500 Nm 之間。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">座位數量（seats）：</span>大部分汽車是 5 座位，其次是 7 座位，超過 7 座位的汽車相對稀少。</p>
              <br>
              <p>以上總結提供了對於數據集中不同汽車特性的基本理解，這些信息對於汽車銷售數據分析和預測市場趨勢都非常有用。</p>
              <br>
              <br>
              <br>
              <br>
              <h5>熱力圖</h5>
              <p>通過以下熱力圖可看出，最大功率（max_power）與售價有著較強的正相關（0.75），功率越高的汽車售價越高。其次就是engine（0.46）和年份（0.41）。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製各字段的相關係數圖表
              plt.figure(figsize=(10,5), dpi=300)
              sns.heatmap(df.corr(), cmap='Reds', annot=True)
                  </code>
              </pre>
              <br>
              <div class="bg3-image5"></div>
              <br>
              <br>
              <br>
          </div>
          <div class="bg4">
              <h3>特征工程</h3>
              <h6>FEATURE ENGINEERING</h6>
              <p>接下來，將會透過轉換數據使模型可以更好地解釋特徵，有助於提高模型的準確性和效率。</p>
              <br>
              <br>
              <br>
              <h4>一、類別字段 - 進行OneHot編碼</h4>
              <p>機器學習模型通常無法直接處理非數值數據，所以，以下字段的非數值資料將轉換為一系列的二進位列，每一列代表一個類別，其中一個類別為1，其餘為0。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 選擇非數值型的字段
              car_columns = ['fuel','seller_type','transmission','owner']

              // 使用OneHot編碼進行處理
              onehotencoder = OneHotEncoder(drop='first', sparse=False)
              // 在df中應用 OneHotEncoder，但不直接轉換成 array，這樣可以保持 DataFrame 的格式
              car_feature = pd.DataFrame(onehotencoder.fit_transform(df[car_columns]))
              // 修改索引與原df一致
              car_feature.index = df.index
              // 取得 OneHotEncoder 所建立的列名，這些列名是自動產生的，如 'fuel_Diesel'，'fuel_Petrol' 等
              column_names = onehotencoder.get_feature_names_out(car_columns)
              // 更新轉換後的 DataFrame 列名
              car_feature.columns = column_names

              // 將原始 DataFrame 中的非數值字段刪除，並將新的 OneHot 編碼字段加入
              df = df.drop(car_columns, axis=1)
              df = pd.concat([df, car_feature], axis=1)

              // 展示轉換後的前幾行資料以進行檢查
              df.head()
                  </code>
              </pre>
              <br>
              <div class="bg4-image"></div>
          </div>
          <div class="bg5">
            <h3>使用Pycaret模塊進行模型訓練</h3>
            <h6>TRAINING MODEL</h6>
            <h4>一、設置Pycaret環境</h4>
            <pre v-highlight>
                  <code class="lang-javascript">
            from pycaret.regression import *

            // 設置環境
            model_setup = setup(data= data_remain,  target='selling_price', session_id=88)
                  </code>
              </pre>
              <br>
            <div class="bg5-image"></div>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">預測目標：</span>二手車價格。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">目標類型：</span>Regression 回歸。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">原始數據：</span>數據集中有 7511行和 18列。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">拆分成訓練集：</span>5257 （佔比70%）。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">拆分成測試集：</span>2254 （佔比30%）。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">N-Fold交叉驗證：</span>10折交叉驗證。</p>
            <br>
            <br>
            <br>
            <br>
            <h4>二、模型比較</h4>
            <p>接下來將會使用模型比較功能功能比較不同機器學習模型的表現。 此功能自動訓練和評估了多個模型，並展示了每個模型在各種測量上的表現。 以下是對比結果的解讀：</p>
            <br>
            <br>
            <pre v-highlight>
                  <code class="lang-javascript">
            // 比较模型
            best_model = compare_models()
                  </code>
              </pre>
              <br>
              <div class="bg5-image2"></div>
              <p>從上圖我們可以看到多個回歸模型在預測任務上的性能比較。表格顯示了每個模型在不同性能指標上的表現。這些性能指標包括平均絕對誤差（MAE）、均方誤差（MSE）、均方根誤差（RMSE）、決定係數（R²）、對數均方根誤差（RMSLE）、平均絕對百分比誤差（MAPE）以及訓練時間（TT (Sec)）。以下是從圖片中觀察到的重要點：</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">Extreme Gradient Boosting（xgboost）</span>在多數性能指標上表現優秀，特別是在 MAE 和 RMSE 上，顯示出相對較低的誤差值。這意味著 xgboost 在這個特定數據集上的預測相對準確。它的 R²（決定係數）值也相對較高，R² 越接近 1，表示模型的預測越能精準反映實際數據。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">Light Gradient Boosting Machine (lightgbm) </span>在 MAE 和 RMSE 上的表現緊接著 xgboost，其 R² 值略低於 xgboost，但整體表現仍然很好。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">Random Forest Regressor (rf) 和 Extra Trees Regressor (et) </span>的 R² 值與 xgboost 和 lightgbm 相似，這表明它們在解釋目標變數變異數方面也是有效的。 然而，在預測準確度上（如 MAE 和 RMSE），它們稍遜於前兩者。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;其他演算法如<span class="Pbold">Gradient Boosting Regressor (gbr)、Decision Tree Regressor (dt)、AdaBoost Regressor (ada) </span>等，在這些效能指標上表現較差，這可能是由於模型複雜度不足的問題。</p>
              <br>
              <p>綜合考慮所有指標，我們可以看到 xgboost 不僅在預測誤差上表現優秀，且在解釋數據變異性上同樣表現良好。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>三、創建模型</h4>
              <p>創建xgboost模型，下圖是經過10次交叉驗證後的平均準確率，也是剛才模型對比中所顯示的平均計算結果。此外，還會查看當前默認的超參數，用作之後調整模型時作對比：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 創建模型
              xgboost = create_model('xgboost')
                  </code>
              </pre>
              <br>
              <div class="bg5-image3"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 打印查看xgboost模型的超參數
              print(xgboost)
                  </code>
              </pre>
              <br>
              <pre v-pre>
              XGBRegressor(base_score=None, booster='gbtree', callbacks=None,
                              colsample_bylevel=None, colsample_bynode=None, 
                            colsample_bytree=None, device='cpu', early_stopping_rounds=None,
                              enable_categorical=False, eval_metric=None, feature_types=None, 
                            gamma=None, grow_policy=None, importance_type=None,
                            interaction_constraints=None, learning_rate=None, max_bin=None,
                            max_cat_threshold=None, max_cat_to_onehot=None, 
                            max_delta_step=None, max_depth=None, max_leaves=None,
                            min_child_weight=None, missing=nan, monotone_constraints=None,
                            multi_strategy=None, n_estimators=None, n_jobs=-1,
                            num_parallel_tree=None, random_state=88, ...)
              </pre>
              <br>
              <br>
              <br>
              <br>
              <h4>四、調整模型</h4>
              <p>使用自定義參數調整模型：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 定義超參數搜索空間
              params = {
                  'n_estimators': np.arange(50, 401, 50),  // 从50到400，步长为50
                  'max_depth': np.arange(3, 16, 3),  // 从3到15，步长为3
                  'learning_rate': np.arange(0.01, 0.2, 0.02),  // 从0.01到0.2，步长为0.02
              }

              // 调整模型
              tuned_xgboost = tune_model(xgboost, custom_grid=params)
                  </code>
              </pre>
              <br>
              <div class="bg5-image4"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 打印查看調整後的xgboost模型超參數
              print(tuned_xgboost)
                  </code>
              </pre>
              <br>
              <pre v-pre>
              經調整後的參數改變：

              max_depth=3, 
              learning_rate=0.2, 
              n_estimators=300
              </pre>
              <br>
              <p>我們嘗試對比不同參數的結果，雖然調整後的 R²（決定係數）值有所提高，但是MAE的誤差值也隨之變大，所以最後決定用原本的模型參數進行下一步預測。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>五、繪製模型</h4>
              <p>接下來會繪製圖表，分析模型的性能：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 殘差圖
              plot_model(tuned_xgboost)
                  </code>
              </pre>
              <br>
              <div class="bg5-image5"></div>
              <p>殘差圖表展示了 XGBRegressor 模型的訓練集和交叉驗證結果的殘差，也就是實際值和預測值之間的差異。從圖表可以觀察到以下幾點：</p>
              <br>
              <p>1.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">殘差分佈：</span>大部分的殘差集中在0的附近，這表示大多數的預測都相當準確。這是模型性能好的一個跡象。</p>
              <p>2.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">離群值：</span>圖中可以看到一些大的正殘差，這表明模型在這些點上低估了實際的值。如果這些點是離群值，它們可能需要進一步的分析來決定是否要在模型訓練中包含它們。</p>
              <p>3.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">訓練集和測試集的 R²：</span>訓練集的 R² 是 0.996，而測試集的 R² 是 0.975，這表明模型在訓練集上的表現略優於測試集，但測試集的表現仍然很好。一個接近1的 R² 值表明模型預測非常接近真實數據。</p>
              <p>4.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">殘差的對稱性：</span>理想的殘差分佈應該是隨機且對稱分佈在水平軸（y=0）的兩側，這表示模型沒有系統性的偏差。在這個圖中，殘差看起來相對對稱，這意味著模型沒有明顯的偏差。</p>
              <p>5.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">殘差的均勻性：</span>一個好的模型殘差應該隨預測值均勻分佈，不應隨預測值的增加而增大或減小。從圖表來看，隨著預測值的增加，殘差的變化並不明顯，這表示模型具有良好的均勻性。</p>
              <br>
              <p>總體而言，這個 XGBRegressor 模型看起來性能不錯。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 預測誤差圖
              plot_model(tuned_xgboost, plot='error')
                  </code>
              </pre>
              <br>
              <div class="bg5-image6"></div>
              <p> 這張圖顯示了 XGBRegressor 模型的預測值與實際值之間的關係。從這張圖中，我們可以觀察到以下特點：</p>
              <br>
              <p>1.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">散點分佈：</span>大部分散點緊密地分佈在對角線周圍，這表示模型預測值與實際值非常接近。理想情況下，所有的散點都會完全位於這條線上，這會代表完美的預測。</p>
              <p>2.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">對角線（identity line）：</span>這條虛線表示預測值和實際值完全相等的情況。如果散點位於這條線上，則意味著預測完全準確。</p>
              <p>3.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">最佳擬合線（best fit line）：</span>這條黑色虛線表示模型預測的最佳擬合趨勢。在這個圖中，最佳擬合線接近於身份線，這表明模型的預測與實際值的關係很接近一個一對一的關係。</p>
              <p>4.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">R²值：</span>圖中標示的 R² 值為 0.975，這是一個非常高的數值，意味著模型能夠解釋大部分的變異性，並且預測性能很好。</p>
              <p>5.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">離群點：</span>雖然大部分散點都靠近對角線，但我們可以觀察到一些離群點偏離了對角線，特別是在實際值較高的區域。這些可能是模型預測不準的案例，需要進一步分析原因，可能是異常值或是模型無法捕捉的複雜模式。</p>
              <br>
              <p>總結來說，這張圖顯示了一個很好的模型擬合度，但考慮到離群點的存在，可能需要進一步的數據清理或特徵工程來優化模型。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 特征重要性圖
              plot_model(tuned_xgboost, plot='feature')
                  </code>
              </pre>
              <br>
              <div class="bg5-image7"></div>
              <p>這張圖顯示了數據集的特征重要性，從高至低進行了排序，可以看到最大功率（max_power）是最重要的。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>六、模型預測</h4>
              <p>接下來將會使用訓練集的30%數據進行預測，看它們是否與CV結果有實際性差異：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 用模型预测測試集的結果
              predict_model(tuned_xgboost)
                  </code>
              </pre>
              <br>
              <div class="bg5-image8"></div>
              <p>測試集的準確度為0.9753，而tuned_xgboost的結果為0.9627。 這比交叉驗證的平均R²稍高，顯示模型在測試集上的表現比交叉驗證時略好。而MAE、MSE和RMSE的值也略有提升，意味著模型在預測新數據時，預測值與實際值的偏差更小。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>七、決定模型</h4>
              <p>經過以上評估、分析及調整後，準備將模型投入使用前的最後一步。 為了進一步評估模型的準確性，將使用未公開過給Pycaret的5%（395個數據）數據進行預測。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 最終確定模型
              final_xgboost = finalize_model(tuned_xgboost)

              // 使用 final_xgboost 對未見過的數據 data_unseen 進行預測
              unseen_predictions = predict_model(final_xgboost, data=data_unseen)

              // 輸出預測結果
              unseen_predictions
                  </code>
              </pre>
              <br>
              <div class="bg5-image9"></div>
              <p>總結：整體預測結果和之前的模型結果十分接近。可以保存該模型的參數，以便日後進行使用。</p>
          </div>
      </div>
      <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
        tableData: [{
          name: 'name',
          Describe: '汽車的品牌和型號'
        }, {
          name: 'year',
          Describe: '車型的年份'
        }, {
          name: 'selling_price',
          Describe: '二手車的價格'
        }, {
          name: 'km_driven',
          Describe: '該車的總公里數，單位為KM'
        }, {
          name: 'fuel',
          Describe: '汽車使用的燃料類型'
        }, {
          name: 'seller_type',
          Describe: '賣家類型'
        }, {
          name: 'transmission',
          Describe: '汽車使用的變速箱類型'
        }, {
          name: 'owner',
          Describe: '車主是否為第一手、第二手或其他'
        }, {
          name: 'mileage',
          Describe: '汽車公司提供的里程數，單位是kmpl'
        }, {
          name: 'engine',
          Describe: '引擎的排氣量，單位是cc'
        }, {
          name: 'max_power',
          Describe: '引擎的最大功率，單位是bhp'
        }, {
          name: 'torque',
          Describe: '扭力'
        }, {
          name: 'seats',
          Describe: '汽車中的座位數'
        }],
      };
    },
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 28px;
        margin-top: 40px;
        font-weight: 500;
        }
      h4{
        font-family: 'HuaKangFangYuan';
        font-size: 26px;
        font-weight: 500;
        color: #444;
        margin-bottom: 70px;
        }
      h5{
        font-family: 'HuaKangFangYuan';
        font-size: 24px;
        font-weight: 500;
        color: #444;
        margin-bottom: 50px;
        }
      h6{
        font-family: 'HuaKangFangYuan';
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;
        color: #666;
        font-weight: 500;
      }

      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p, pre {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin:10px 0;
        line-height: 32px;
        white-space: pre-wrap; /* 保持换行和空格，同时允许自动换行 */
        color: #444444;
      }
      .p8d{
        color: #8d8d8d;
      }
      .p16 {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        color: #333;
        padding: 20px;
        background-color: #F8F8F8;
        margin: 20px 0 ;
      }
      .Pbold{
            font-weight: 900;
      }
      .bg-p{
              margin-top: 30px;
              width: 100%;
      }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      margin: 0 auto;
    }
.bg1{
  .bg1-image{
      width: 100%;
      height: 400px;
      margin-top: 120px;
      margin-bottom: 220px;
      background-image: url('./assets/bg1-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
}
.bg2{
    margin-top: 120px;
    .tableStyle{
      width: 80%;
      margin-top:30px;
      font-size: 16px;
    }
}
.bg3{
    margin-top: 120px;
    .bg3-container{
      width: 100%;
      display: flex;
      gap: 60px;
      pre{
        width: 50%;
      }
    }
    .bg3-image{
      width: 100%;
      height: 420px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image2{
      width: 30%;
      height: 400px;
      background-image: url('./assets/bg3-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image3{
      width: 100%;
      height: 433px;
      background-image: url('./assets/bg3-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image4{
      width: 100%;
      height: 1182px;
      background-image: url('./assets/bg3-image4.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image5{
      width: 100%;
      height: 726px;
      background-image: url('./assets/bg3-image5.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg4{
    margin-top: 120px;
    .bg4-image{
      width: 100%;
      height: 116px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg5{
  margin-top: 120px;
  .bg5-image{
      width: 100%;
      height: 600px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image2{
      width: 100%;
      height: 670px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image3{
      width: 100%;
      height: 500px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image4{
      width: 100%;
      height: 500px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image4.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image5{
      width: 100%;
      height: 510px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image5.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image6{
      width: 100%;
      height: 510px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image6.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image7{
      width: 100%;
      height: 470px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image7.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image8{
      width: 100%;
      height: 500px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image8.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image9{
      width: 100%;
      height: 500px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image9.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
}

  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>