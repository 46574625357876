<template>
    <div class="bgContainer">
        <div class="contactboy" ></div>
        <div class="emailPng" @click="handleClick('Leochumwk@gmail.com')" @mouseover="showImage('email')" @mousemove="moveImage" @mouseleave="hideImage"></div>
        <div class="websitePng" @click="handleClick('Leochum.cn')" @mouseover="showImage('website')" @mousemove="moveImage" @mouseleave="hideImage"></div>
        <div class="wechatPng" @click="handleClick('Leochum66')" @mouseover="showImage('wechat')" @mousemove="moveImage" @mouseleave="hideImage"></div>
        <div class="whatsappPng" @click="handleClick('98239810')" @mouseover="showImage('whatsapp')" @mousemove="moveImage" @mouseleave="hideImage"></div>
        <div v-if="imageVisible" :style="{ top: imagePosition.y + 'px', left: imagePosition.x + 'px' }" class="followImage"></div>
         <!-- 左下角导航栏 -->
        <backBtn @hover-start="overlay=true" @hover-end="overlay= false" /> 
        <div class="overlay" :style="{opacity:overlay ? 1 : 0}"></div>
    </div>
</template>

<script>
import backBtn from '@/components/backBtn/index.vue'

export default {
    components: {
      backBtn,
    },
    data(){
        return{
            overlay:false,
            imageVisible: false,
            imagePosition: { x: 0, y: 0 },
            currentHover: '', // 新增属性来存储当前悬停的图片类型
        }
    },
    methods: {
      //  选中文字后复制
            selectCopy() {
                document.execCommand("handleClick"); // 执行浏览器复制命令
                this.$message({
                    message: '複製成功',
                    type: 'success'
                });
            },
            // 点击复制
            handleClick(content) {
            let input = document.createElement('input');
            input.setAttribute('value', content);
            document.body.appendChild(input);
            input.select();
            let result = document.execCommand('copy');
            document.body.removeChild(input);

            if (result) {
              this.$message({
                message: `複製成功: ${content}`,
                type: 'success',
              });
            } else {
              this.$message({
                message: '複製失敗',
                type: 'error',
              });
            }
          },
        showImage() {
          this.imageVisible = true;
        },
        moveImage(event) {
          this.imagePosition = {
            x: event.clientX,
            y: event.clientY
          };
        },
        hideImage() {
          this.imageVisible = false;
        },
    }
  }
</script>

<style lang="less" scoped>
*{
  font-family: Balsamiq Sans, Georgia,'PingFang SC', Helvetica, sans-serif;
}
.bgContainer {
  width: 100vw; // 设置为视口宽度
  max-height: 100vh; /* 使用min-height而非height确保至少100%视口高度 */
  background-image: url('./assets/contactbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  .contactboy{
    width: 85vh;
    height: 70vh; /* 高度自动，保持原始宽高比 */
    position: absolute;
    top: 20vh;  /* 从顶部开始50%的位置，与屏幕中心对齐 */
    left: 58vh; /* 从右侧开始10%的位置 */
    background-image: url("./assets/contactBoy.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    }
  .emailPng{
    width: 50vh;
    height: 26vh; /* 高度自动，保持原始宽高比 */
    position: relative;
    top: 12vh; 
    left: 32vh; /* 从右侧开始10%的位置 */
    background-image: url("./assets/email.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    transition: all 0.3s ease-in-out;
    &::before{
      content: "";
      position: absolute;
      top: -15vh;
      left: -15vh;
      height: 38vh;
      width: 38vh;
      background-image: url('./assets/email-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }
    &:hover{
      transform: scale(1.1);
    }
  }
  .websitePng{
    width: 50vh;
    height: 26vh; /* 高度自动，保持原始宽高比 */
    position: relative;
    top: -10vh; 
    left: 125vh; /* 从右侧开始10%的位置 */
    background-image: url("./assets/website.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    transition: all 0.3s ease-in-out;
    &::before{
      content: "";
      position: absolute;
      top: -15vh;
      left: 30vh;
      height: 32vh;
      width: 32vh;
      background-image: url('./assets/website-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }
    &:hover{
      transform: scale(1.1);
    }
  }
  .wechatPng{
    width: 50vh;
    height: 26vh; /* 高度自动，保持原始宽高比 */
    position: relative;
    bottom: 3vh; 
    left: 25vh; /* 从右侧开始10%的位置 */
    background-image: url("./assets/wechat.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    transition: all 0.3s ease-in-out;
    &::before{
      content: "";
      position: absolute;
      top: -12vh;
      left: -10vh;
      height: 28vh;
      width: 28vh;
      background-image: url('./assets/wechat-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }
    &:hover{
      transform: scale(1.1);
    }
  }
  .whatsappPng{
    width: 50vh;
    height: 26vh; /* 高度自动，保持原始宽高比 */
    position: relative;
    bottom: 25vh; 
    left: 125vh; /* 从右侧开始10%的位置 */
    background-image: url("./assets/whatsapp.png");
    background-size: contain; /* 使用contain以确保图片内容完整显示 */
    background-repeat: no-repeat; // 不重复图片
    transition: all 0.3s ease-in-out;
    &::before{
      content: "";
      position: absolute;
      top: -8vh;
      left: 38vh;
      height: 18vh;
      width: 18vh;
      background-image: url('./assets/whatsapp-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }
    &:hover{
      transform: scale(1.1);
    }
  }
}

// 對話框
.followImage {
  position: fixed; // 使用 fixed 定位使图片能够跟随鼠标
  width: 30vh; // 设置图片的宽度
  height: 10vh; // 设置图片的高度
  background-image: url('./assets/btn-hover.png'); // 设置图片的背景
  background-size: contain; // 确保图片覆盖整个元素
  background-repeat: no-repeat;
  opacity: 0.8;
  pointer-events: none; // 防止图片干扰鼠标事件
  z-index: 100; // 确保图片显示在其他元素之上
}

// hover遮罩
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none; /*確保遮罩層不干擾下面的元素交互*/
}
</style>