<template >
  <loading-screen v-if="loading"></loading-screen>
  <div v-cloak v-if="!loading">
    <!-- Your app content -->
        <div class="router-view-container">
          <!-- <MyHeader/> -->
          <router-view/>
          <MyFooter/>
        </div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen.vue';
import MyHeader from "./components/Header/MyHeader.vue";
import MyFooter from "./components/Footer/MyFooter.vue";

export default {
  name: 'App',

  components: {
    LoadingScreen,
    MyHeader,
    MyFooter
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    updateLoadingStatus() {
      const isMobile = /phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone/i.test(navigator.userAgent);
      // 如果不是移动设备，则执行加载完成的逻辑
      if (!isMobile) {
        setTimeout(() => {
          this.loading = false;
        }, 2000); // 假设加载需要2秒钟
      } else {
        this.loading = false; // 移动设备直接设置加载完成
      }
    },
  },
  mounted() {
    // 调用方法来更新加载状态
    this.updateLoadingStatus();
    window.addEventListener('resize', this.updateLoadingStatus);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateLoadingStatus);
  },

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* 这将确保元素的padding和border不会增加到其定义的宽高 */
  
  }
    [v-cloak] {
    display: none;
  }

  .router-view-container{
    position: absolute;
  }

  /* @font-face 规则用于定义字体 */
  @font-face {
  font-family: 'HuaKangFangYuan';
  src: url('../src/assets/fonts/華康方圓t.woff') format('woff'); /* 引用 woff 文件 */
  font-weight: normal;
  font-style: normal;
  }
  @font-face {
  font-family: 'AaErZhiYuan';
  src: url('../src/assets/fonts/AaErZhiYuan-2.woff') format('woff'); /* 引用 woff 文件 */
  font-weight: normal;
  font-style: normal;
  }

  @font-face {
  font-family: 'Balsamiq Sans';
  src: url('../src/assets/fonts/balsamiq-sans-bold.woff') format('woff'); /* 引用 woff 文件 */
  font-weight: normal;
  font-style: normal;
  }
  @font-face {
  font-family: 'SourceSansPro-Bold';
  src: url('../src/assets/fonts/SourceSansPro-Bold.woff') format('woff'); /* 引用 woff 文件 */
  font-weight: normal;
  font-style: normal;
  }
  @font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('../src/assets/fonts/SourceSansPro-Regular.woff') format('woff'); /* 引用 woff 文件 */
  font-weight: normal;
  font-style: normal;
  }
  /* 全局样式 */
  html body {
    font-family: 'HuaKangFangYuan', sans-serif; /* 使用自定义字体 */
    cursor: url('./assets/pointer.png'), auto;
    max-width: 100%;
    overflow-x: hidden; /* 阻止页面横向滚动 */
    /* cursor: url('~@/assets/link.png'), pointer */
  }

  button {
  font-family: 'HuaKangFangYuan', sans-serif;
  }

  /* 全局定义滚动条样式 */
html {
  scrollbar-width: thin; /* 对于Firefox */
  scrollbar-color: #e1e1e9 transparent; /* 对于Firefox，滑块颜色和轨道颜色 */
}

/* 对于基于Webkit的浏览器的样式 */
::-webkit-scrollbar {
    width: 10px; /* 调整滚动条的宽度 */
    background-color: transparent; /* 滚动条轨道颜色 */
}

::-webkit-scrollbar-thumb {
    background-color: #e1e1e9; /* 滚动条滑块颜色 */
    border-radius: 10px; /* 滚动条滑块边角的圆滑度 */
    background-clip: padding-box;
    border: 2px solid transparent; /* 设置滑块的边框 */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #e1e1e9; /* 悬停时滑块的颜色 */
}

::-webkit-scrollbar-button {
    display: none; /* 不显示滚动条的按钮 */
}

</style>
