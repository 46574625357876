<template>
    <div class="backBtn" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <div class="backBtn-BG">
          <router-link to="/">
            <div class="home-btn"></div>
          </router-link>
          <div class="btn-container">
            <router-link to="/BriefMenu" class="briefButton"></router-link>
            <router-link to="/SkillMenu" class="skillButton" ></router-link>
            <router-link to="/ProjectMenu" class="projectButton" ></router-link>
            <router-link to="/ContactMenu" class="contactButton" ></router-link>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  methods:{
    handleMouseOver(){
      this.$emit('hover-start')
    },
    handleMouseLeave(){
      this.$emit('hover-end')
    }
  }
}
</script>

<style lang="less" scoped>
.backBtn{
  width: 9vh;
  height: 9vh;
  position: fixed;
  bottom: 5vh;
  left: 1.5vh;
  background-image: url('./assets/backBtn.png');
  background-size: contain;
  background-repeat: no-repeat; 
  cursor: pointer;
  transition: all 0.5s ease-in-out, opacity 0.5s ease;
  z-index: 99999;
  
    &:hover{
        opacity: 1;
        transform: translateX(-10vh);
        & > .backBtn-BG {
            display: block;
            transform: translateX(0vh);
        }
    }
    + .alpha-BG{
      display: block;
      opacity: 0.5;
      transition: opacity 0.5s ease, display 0s; // 显示时没有延迟
    }
}

// 黑色半透明背景
.alpha-BG{
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.5); // 使用 rgba 以支持透明度
  opacity: 0;
  display: none;
  position: fixed; // 固定位置
  top: 0;
  left: 0;
  z-index: 1; // 确保它位于其他元素之下
  transition: opacity 0.5s ease, display 0s 0.5s; // 隐藏时延迟消失
}
// 当 backBtn 不被 hover 时，隐藏遮罩
.backBtn:not(:hover) + .alpha-BG {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease; // 只有透明度过渡
}

.backBtn-BG {
  width: 48vh;
  height: 50vh;
  position: absolute;
  top: -36vh;
  left: -20vh;
  background-image: url('./assets/back-btn-effect.png');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0; // 开始时不可见
  transform: translateX(-50%);
  transition: opacity 0.8s ease, transform 0.8s ease; // 平滑过渡效果
  z-index: 1; // 确保背景效果在按钮之下
}

.backBtn:hover > .backBtn-BG {
  opacity: 1; // 完全不透明
  transform: translateX(59%); // 移动到可见位置
}

.backBtn:not(:hover) > .backBtn-BG {
  opacity: 0; // 完全透明
  transform: translateX(-70%); // 移回初始位置
}

// 按钮样式
.home-btn{
  width: 6vh;
  height: 6vh;
  position: relative;
  top: 10vh;
  left: 9vh;
  background-image: url('./assets/home-btn.png');
  background-repeat: no-repeat;
  background-size: contain;

  &:hover{
    background-image: url('./assets/home-btn-Hover.png');
  }
}

// BG內的按鈕樣式
.btn-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 13vh;
  left: 9vh;

  .briefButton{
    width: 14vh;
    height: 4vh;
    position: absolute;
    background-image: url('./assets/Brief-Btn.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 2vh;

    &:hover{
      background-image: url('./assets/Brief-Btn-Hover.png');
    }
  }

  .skillButton{
    width: 13vh;
    height: 4vh;
    position: absolute;
    background-image: url('./assets/Skill-Btn.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 8vh;
    
    &:hover{
      background-image: url('./assets/Skill-Btn-Hover.png');
    }
  }

  .projectButton{
    width: 18vh;
    height: 4vh;
    position: absolute;
    background-image: url('./assets/Project-Btn.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 14vh;

    &:hover{
      background-image: url('./assets/Project-Btn-Hover.png');
    }
  }

  .contactButton{
    width: 20vh;
    height: 4vh;
    position: absolute;
    background-image: url('./assets/Contact-Btn.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 20vh;

    &:hover{
      background-image: url('./assets/Contact-Btn-Hover.png');
    }
  }
}
</style>