<template>
    <div v-if="showButton" @click="goBack" class="back-button"></div>
  </template>
  
  <script>
  export default {
    props: {
      showButton: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style scoped>
  .back-button {
    position: fixed;
    top:9vh;
    left: 4vh;
    cursor: pointer;
    width: 6vh;
    height: 6vh;
    background-color: #fff;
    background-image: url('./assets/back.png');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); 
    border-radius: 50%;
    transition: all 0.3s ease; /* 平滑过渡效果 */
    z-index: 1;
    /* 样式可以根据您的设计来调整 */
    &:hover{
        background-color: #f0f0f0; 
    }
  }
  </style>
  