import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "animate.css"
import 'animate.css/animate.compat.css'
import router from './router'
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css' //模仿jupyter主题，会好看一点



const app = createApp(App)

app.use(ElementPlus)
app.use(router)

// 定义全局自定义指令 'highlight'
app.directive('highlight', (el) => {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightElement(block) // 使用 highlightElement 方法
  })
})

app.mount('#app')
