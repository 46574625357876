<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="main-bg">
          <div class="bg1">
              <h1 class="main-title">RFM模型客戶價值分類及數據分析</h1>
              <h3>項目背景</h3>
              <h6>PROJECT BACKGROUND</h6>
              <p class="bg1-p">本項目將隨機抽取部份數據，通過構建RFM模型，將消費者分類為不同價值層次的客戶群。對於各種價值層次的消費者，我們將採取差異化的策略。透過這些模型，能夠針對不同的消費者群體實施具體的營銷策略，最大限度地留住高價值客戶。同時，對於那些低價值客戶，我們可以相應減少投入成本，從而達到資源的最優配置。</p>
              <div class="bg1-image"></div>
              <br>
          </div>
          <div class="bg2">
              <h3>數據說明</h3>
              <h6>DATA DESCRIPTION</h6>
              <p><span class="Pbold">數據來源：</span> — —</p>
              <p><span class="Pbold">數據大小：</span> 15867條數據</p>
              <p><span class="Pbold">字段名稱描述：</span></p>
              <el-table
                :data="tableData"
                stripe
                border
                style="font-size: 18px;"
                class="tableStyle">
                <el-table-column
                  prop="name"
                  label="列名稱"
                  width="400">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="說明">
                </el-table-column>
              </el-table>
          </div>
          <div class="bg3">
              <h3>庫導入及數據讀取</h3>
              <h6>DATA READING</h6>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 导入需要的库
              import pandas as pd
              import numpy as np
              import seaborn as sns
              import matplotlib.pyplot as plt
              from pyecharts.charts import Bar,Line, Pie, Scatter
              from pyecharts import options as opts
              from pyecharts.commons.utils import JsCode

              // 设置matplotlib的字体
              plt.rcParams['font.sans-serif'] = ['Microsoft YaHei']  # 微软雅黑的字体名
              plt.rcParams['axes.unicode_minus'] = False  # 用来正常显示负号

              import warnings
              warnings.filterwarnings('ignore')
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              df = pd.read_csv('./saleslist.csv')
                  </code>
              </pre>
              <br>
              <br>
              <br>
              <br>
              <h3>數據預覽及數據處理</h3>
              <h6>DATA PRE-PROCESSING</h6>
              <h4>一、數據預覽</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看數據及維度
              df
                  </code>
              </pre>
              <div class="bg3-image"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看數據信息
              df.info()
                  </code>
              </pre>
              <br>
              <pre>
              'pandas.core.frame.DataFrame'>
              RangeIndex: 15867 entries, 0 to 15866
              Data columns (total 4 columns):
              #    Column                        Non-Null Count          Dtype 
              ---  ----------                        ----------------------        ---------- 
              0   OrderID                        15867 non-null          int64 
              1   CustomerID                15867 non-null          int64 
              2   DealDateTime           15867 non-null          object
              3   Sales                              15867 non-null          int64 
              dtypes: int64(3), object(1)
              memory usage: 496.0+ KB
              </pre>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看訂單金額是否存在小於0的異常值
              df[df['Sales'] &lt; 0].sum()
                  </code>
              </pre>
              <br>
              <pre>
              OrderID                 0.0
              CustomerID         0.0
              DealDateTime    0.0
              Sales                       0.0
              dtype: float64
              </pre>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看各列缺失值
              df.isna().sum()
                  </code>
              </pre>
              <br>
              <pre>
              OrderID                     0
              CustomerID             0
              DealDateTime        0
              Sales                          0
              dtype: int64
              </pre>
              <br>
              <p>可以看到，數據集非常完整，沒有任何缺失值和異常值。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>二、數據處理</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 確保交易時間是日期時間格式
              df['DealDateTime'] = pd.to_datetime(df['DealDateTime'])
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 透過交易時間計算出每筆交易的星期數
              weekday = df['DealDateTime'].dt.dayofweek
              df['Weekday'] = weekday.apply(lambda x: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'][x])

              // 獲取每筆交易的年月份
              df['Year_Month'] = df['DealDateTime'].dt.strftime('%Y-%m')

              //查看處理後的數據
              df.head()
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg3-image2"></div>
          </div>
          <div class="bg4">
              <h3>RFM用戶分層模型</h3>
              <h6>RFM MODEL</h6>
              <h4>一、模型說明</h4>
              <p>RFM模型主要包括以下三個指標：</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">R：</span>Recency，最近一次交易時間。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">F：</span>Frequency，交易的頻次。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">M：</span>Monetary，交易總金額。</p>
              <br>
              <p>接著，對計算結果進行評分，根據三個指標的評分可以將使用者分為8大類。</p>
              <br>
              <br>
              <br>
              <div class="bg4-image"></div>
              <br>
              <br>
              <br>
              <h4>二、RFM模型構建</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 分別計算 R、F、M
              df_rfm = df.groupby('CustomerID').agg({
                  'DealDateTime':lambda x : (pd.to_datetime('2015-09-25')-x.max()).days, #計算最近一次消費天數 Recency
                  'CustomerID': lambda x : len(x), #計算消費頻率 Frequency
                  'Sales': lambda x : x.sum() #計算消費金額 Monetary
                  })

              // 更改列名稱
              df_rfm.rename(columns={'DealDateTime':'R','CustomerID':'F','Sales':'M'},inplace=True)

              // 查看列表
              df_rfm
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image2"></div>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 查看所有用戶轉化後的數據描述
              df_rfm.describe()
                  </code>
              </pre>
              <br>
              <div class="bg4-image3"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 計算R分組的分數位，用於將客戶基於最近一次交易時間的近到遠排序
              r_bins = df_rfm['R'].quantile([0,0.25,0.5,0.75,1])
              // 定義R分數的標籤，近的時間得分高（分為4組）
              r_names=['4','3','2','1']

              // 計算F分組的分數位，用於將客戶基於交易頻率的高到低排序
              f_bins = df_rfm['F'].quantile([0,0.25,0.5,0.75,1])
              // 定義F分數的標籤，频率高得分高（分為4組）
              f_names=['1','2','3','4']

              // 計算M分組的分數位，用於將客戶基於交易金額的多到少排序
              m_bins = df_rfm['M'].quantile([0,0.25,0.5,0.75,1])
              // 定義M分數的標籤，金額多得分高（分為4組）
              m_names=['1','2','3','4']

              // 根據R分組分位數將客戶分配R分數，並轉換為浮點數
              df_rfm['r_score'] = pd.cut(df_rfm['R'], r_bins, labels=r_names).astype(float)
              // 根據F分組分位數將客戶分配F分數，並轉換為浮點數
              df_rfm['f_score'] = pd.cut(df_rfm['F'], f_bins, labels=f_names).astype(float)
              // 根據M分組分位數將客戶分配M分數，並轉換為浮點數
              df_rfm['m_score'] = pd.cut(df_rfm['M'], m_bins, labels=m_names).astype(float)

              // 輸出更新後的數據
              df_rfm
                  </code>
              </pre>
              <br>
              <div class="bg4-image4"></div>
              <p>在列表中：</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">R：</span>（Recency）值越低，代表顧客最近一次購買時間越近，所以得分越高。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">F：</span>（Frequency）值越高，代表顧客購買越頻繁，所以分數越高。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">M：</span>（Monetary）值越高，代表客戶購買總金額越多，所以得分越高。</p>
              <br>
              <p>這些分數後續可以用來評估顧客的價值。</p>
              <br>
              <p>首先，將每個顧客根據最近一次交易時間（Recency, R）、交易頻率（Frequency, F）和交易金額（Monetary, M）的得分，與各自指標平均值進行比較，從而將顧客分為“高”或“低”兩組：</p>
              <br>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 計算RFM綜合分值
              df_rfm['R高低'] = df_rfm['r_score'].apply(lambda x: '高' if x > df_rfm['r_score'].mean() else '低')
              df_rfm['F高低'] = df_rfm['f_score'].apply(lambda x:'高'if x > df_rfm['f_score'].mean() else '低')
              df_rfm['M高低'] = df_rfm['m_score'].apply(lambda x:'高'if x > df_rfm['m_score'].mean() else '低')
                  </code>
              </pre>
              <br>
              <br>
              <p>然後，將三組指標的“高”或“低”結果進行相加，進一步判斷是什麼類型的客戶：</p>
              <br>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 三組指標相加
              df_rfm['RFM'] = df_rfm['R高低'] + df_rfm['F高低'] + df_rfm['M高低']

              // 用戶分類函數
              def rfm_type(x):
                  if x == '高高高':
                      return '重要價值客戶'
                  elif x == '低高高':
                      return '重要保持客戶'
                  elif x == '高低高':
                      return '重要發展客戶'
                  elif x == '低低高':
                      return '重要挽留客戶'
                  elif x == '高高低':
                      return '消費潛力客戶'
                  elif x == '高低低':
                      return '新客戶'
                  elif x == '低高低':
                      return '低價值客戶'
                  elif x == '低低低':
                      return '沉睡客戶'

              // 把分類結果添加到列表中
              df_rfm['用戶類型'] = df_rfm['RFM'].apply(rfm_type)

              // 打印結果
              df_rfm.head()
                  </code>
              </pre>
              <br>
              <div class="bg4-image5"></div>
          </div>
          <div class="bg5">
            <h3>數據探索</h3>
            <h6>DATA EXPLORATION</h6>
            <h4>一、各類用戶人數佔比情況</h4>
            <pre v-highlight>
                  <code class="lang-javascript">
            df_count = df_rfm['用戶類型'].value_counts().reset_index()
            df_count.rename(columns={'index':'用戶類型','用戶類型':'人數'},inplace=True)
            df_count['人數佔比'] = df_count['人數']/df_count['人數'].sum()
            df_count
                  </code>
              </pre>
              <br>
              <br>
              <br>
            <div class="bg5-image"></div>
            <br>
            <p>上圖列表顯示了客戶標籤分佈及其所佔的百分比，從這個分布中，我們將對最高佔比的三類客戶，對公司的客戶基礎和運營狀況進行初步評估：</p>
            <br>
            <p>1.&nbsp;&nbsp;<span class="Pbold">流失客戶最多：</span>佔了25.25%。這個數值相對較高，表示了有四分之一的客戶已經流失，這可能是客戶不滿意或找到了更好的替代品。公司需要分析流失原因並採取措施挽留這部分客戶。</p>
            <p>2.&nbsp;&nbsp;<span class="Pbold">重要保持客戶：</span>其次多，占比22.92%。這類客戶在過去有較高的購買頻率和金額，但近期沒有購買。這表明可能需要通過促銷活動或提醒來重建與這些客戶的關係。</p>
            <p>3.&nbsp;&nbsp;<span class="Pbold">新客戶：</span>佔比17.08%。新客戶比例健康，說明公司在獲得新客戶方面做得不錯。然而，關鍵在於將這些新客戶轉化為忠誠客戶。</p>
            <br>
            <p>總的來說，公司似乎在新客戶獲取方面表現良好，但在客戶留存和價值提升方面面臨挑戰。應重點關注提高客戶滿意度、增加客戶忠誠度以及減少客戶流失。這可能涉及改進產品或服務、優化客戶服務、實施個性化的市場營銷策略和忠誠度獎勵計劃等。</p>
            <br>
            <br>
            <br>
            <br>
            <h4>二、各類用戶消費金額貢獻佔比</h4>
            <pre v-highlight>
                  <code class="lang-javascript">
            df_amount = df_rfm.groupby('用戶類型')['M'].sum().reset_index()
            df_amount.columns = ['用戶類型','消費金額']
            df_amount['金額佔比'] = df_amount['消費金額'] / df_amount['消費金額'].sum()
            df_amount.sort_values(by=['金額佔比'],ascending=False, inplace=True)
            df_amount
                  </code>
              </pre>
              <br>
              <br>
              <br>
              <div class="bg5-image2"></div>
              <br>
              <p>上圖展示了不同類型客戶的消費金額和其所佔的百分比，以下是根據表格提供的數據對公司運營狀況的評估：</p>
              <br>
              <p>1.&nbsp;&nbsp;<span class="Pbold">重要價值客戶：</span>消費金額佔比最大，約為29.81%。這顯示公司有一部分忠實客戶群體，他們為公司貢獻了顯著的收入。應該繼續維護這些客戶的滿意度和忠誠度。</p>
              <p>2.&nbsp;&nbsp;<span class="Pbold">重要保持客戶：</span>消費金額佔比20.17%，這個比例也相當高。儘管這些客戶最近可能沒有消費，但過去的貢獻顯示了他們的潛在價值。應采取策略重新吸引這類客戶。</p>
              <p>3.&nbsp;&nbsp;<span class="Pbold">沉睡客戶：</span>佔比18.13%。這是一個相對較高的數字，意味著公司有較高比例的以前忠誠的客戶已經流失，需要採取行動分析原因和挽回措施。</p>
              <p>4.&nbsp;&nbsp;<span class="Pbold">新客戶：</span>佔比12.47%，表示公司能吸引新顧客進行消費，但需要進一步的策略將他們轉化為重要保持客戶或重要價值客戶。</p>
              <p>5.&nbsp;&nbsp;<span class="Pbold">重要挽留客戶：</span>佔比較小，為6.66%。這類客戶曾經有較高的消費，但現在活躍度下降。需要通過個性化營銷來提高這些客戶的參與度。</p>
              <p>6.&nbsp;&nbsp;<span class="Pbold">重要發展客戶：</span>佔比6.03%，這些客戶的消費金額和頻率有上升的潛力，應該通過營銷策略和激勵措施來促進他們的轉化。</p>
              <p>7.&nbsp;&nbsp;<span class="Pbold">消費潛力客戶：</span>僅佔4.02%，表明這部分客戶的消費能力有待開發。可以透過優惠、促銷或者忠誠計劃來增加其消費。</p>
              <p>8.&nbsp;&nbsp;<span class="Pbold">低價值客戶：</span>佔比最低，為2.81%，這可能是偶爾購買或只購買低價商品的客戶。雖然他們目前對營收貢獻有限，但仍有潛力通過市場營銷策略提升他們的消費水平。</p>
              <br>
              <p>綜合考慮所有指標，我們可以看到 xgboost 不僅在預測誤差上表現優秀，且在解釋數據變異性上同樣表現良好。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>三、RFM精細化運營</h4>
              <p>1.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">重要價值客戶：</span>提供VIP專屬服務</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次交易時間近，交易頻次和金額都較高。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：優先維護這類用戶，建立專屬的VIP客戶服務團隊，提供專享權益和定期的福利活動，使他們感受到特別的尊貴體驗。無論在精神還是物質層面，都要給予充分激勵，激發他們進行主動分享及推薦。</p>
              <br>
              <p>2.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">重要保持客戶：</span>努力提升消費頻次</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次交易時間近，消費金額高，但消費頻次不高。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：針對這些忠誠度較低的用戶，需促使其在平台上更活躍，持續優化產品與服務，制定相關策略，以增加其使用與購買頻次。</p>
              <br>
              <p>3.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">重要發展客戶：</span>積極主動接觸</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較遠，但是消費頻次和金額都較高。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：定期透過電子郵件、應用內推送、短訊等方式，主動與客戶保持聯繫與互動。</p>
              <br>
              <p>4.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">重要挽留客戶：</span>分析問題所在</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較遠，消費頻次不高，但上次消費的金額較高。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：聚焦於防止流失，預先設置流失預警機制，並搭配相關策略進行有效接觸。</p>
              <br>
              <p>5.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">消費潛力客戶：</span></p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較近，消費頻次高，但是消費金額低。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：此類用戶具備發展潛力，可透過發放各種優惠券刺激消費增長或鼓勵更多投入。</p>
              <br>
              <p>6.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">新客戶：</span></p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較近，消費頻次低，消費金額也低。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：這類客戶可能是新客戶，需要加強針對性的引導，提供新手福利，並在節日活動時及時提醒，增進其參與度。</p>
              <br>
              <p>7.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">低價值客戶：</span></p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較遠，消費頻次高，消費金額低。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：此類用戶可能正處於即將流失的邊緣，曾經活躍，但逐漸對產品或服務失去信心。需要針對性地進行情感與利益觸達。</p>
              <br>
              <p>8.&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">沉睡客戶：</span></p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;客戶特性：距離上次消費時間較遠，消費頻次低，消費金額低。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;運營策略：該部分用戶可能已流失一段時間，若預算有限，可暫時考慮不投入過多資源。</p>
          </div>
      </div>
      <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
        tableData: [{
          name: 'OrderID',
          Describe: '訂單ID'
        }, {
          name: 'CustomerID',
          Describe: '客戶ID'
        }, {
          name: 'DealDateTime',
          Describe: '交易時間'
        }, {
          name: 'Sales',
          Describe: '訂單金額'
        }],
      };
    },
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 28px;
        margin-top: 40px;
        font-weight: 500;
        }
      h4{
        font-family: 'HuaKangFangYuan';
        font-size: 26px;
        font-weight: 500;
        color: #444;
        margin-bottom: 70px;
        }
      h5{
        font-family: 'HuaKangFangYuan';
        font-size: 24px;
        font-weight: 500;
        color: #444;
        margin-bottom: 50px;
        }
      h6{
        font-family: 'HuaKangFangYuan';
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;
        color: #666;
        font-weight: 500;
      }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p, pre {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin:10px 0;
        line-height: 32px;
        white-space: pre-wrap; /* 保持换行和空格，同时允许自动换行 */
        color: #444444;
      }
      .p8d{
        color: #8d8d8d;
      }
      .p16 {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        color: #333;
        padding: 20px;
        background-color: #F8F8F8;
        margin: 20px 0 ;
      }
      .Pbold{
            font-weight: 900;
      }
      .bg-p{
              margin-top: 30px;
              width: 100%;
      }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      margin: 0 auto;
    }
.bg1{
  .bg1-image{
    width: 100%;
    height: 400px;
    margin: 120px 0;
    background-image: url('./assets/bg1-image.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.bg2{
    margin-top: 120px;
    .tableStyle{
      width: 80%;
      margin-top:30px;
      font-size: 16px;
    }
}
.bg3{
    margin-top: 120px;
    .bg3-image{
      width: 100%;
      height: 400px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image2{
      width: 100%;
      height: 230px;
      background-image: url('./assets/bg3-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg4{
    margin-top: 120px;
    .bg4-image{
      width: 100%;
      height: 875px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image2{
      width: 100%;
      height: 520px;
      margin-bottom: 30px;
      background-image: url('./assets/bg4-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image3{
      width: 100%;
      height: 360px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image4{
      width: 100%;
      height: 270px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image4.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image5{
      width: 100%;
      height: 300px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image5.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg5{
  margin-top: 120px;
  .bg5-image{
      width: 100%;
      height: 385px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg5-image2{
      width: 100%;
      height: 418px;
      margin-bottom: 60px;
      background-image: url('./assets/bg5-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
}

  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>