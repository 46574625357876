<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="bg-banner"></div>
        <div class="main-bg">
          <div class="bg1">
              <div class="bg1-title"></div>
              <div class="bg1-container">
                <div class="left-box">
                  <h2>整體介绍</h2>
                  <p class="bg1-p">過往，僅通過郵件方式向代理發送其個人的推廣編號和推廣連結，這一做法雖保證了基本的功能需求，卻在日常使用中顯得不夠便捷。許多代理反映，每當需要進行推廣時，必須翻閱大量郵件以尋找相關信息，這不僅耗時而且效率低下。另外返佣金額只能從固定組別中選取，限制了業務的發展機會。基於對用戶反饋的深入分析和積極響應，我們開發了一個全新的推廣工具頁面，為代理提供一個更加直觀、便捷的方式來獲取和使用他們的推廣資源。這樣不僅體現了我們對客戶需求的重視，也展示了公司在不斷追求創新和提升用戶體驗方面的決心。</p>
                  <h2 class="bg1-h2">功能現況</h2>
                  <p class="bg1-p2">面對先前推廣方式的局限，我們的新推廣工具頁面已經上線，為代理提供了一個更加高效、便利的平台。除了保留原有的推薦編號和鏈接方式，我們還新增了二維碼和海報推廣功能，讓代理能夠即刻生成並分享個性化的推廣材料。這些改進不僅提升了推廣的便利性和效率，也進一步優化了代理的使用體驗，使他們能夠更加輕鬆地擴展業務範圍。</p>
                </div>
                <div class="bg1-image"></div>
              </div>
          </div>
          <div class="bg2">
              <div class="bg2-title"></div>
              <h3>鎖客工具，一應俱全</h3>
              <p class="bg2-p">利用槓桿網路傳播的力量，吸引大量理想客戶成為您的下線代理/客戶</p>
              <div class="bg2-image"></div>
              <h3 class="bg2-h3">專屬海報，推廣無憂</h3>
              <p class="bg2-p">使用個人專屬海報吸引更多潛在客戶，進而實現團隊的快速發展</p>
          </div>     
        </div>
        <div class="carousel-container">
          <div class="carousel" :style="{ '--paused': paused }">
            <img v-for="(poster, index) in tripledPosters" :key="index"
                :src="getImgPath(poster)" :alt="'Poster ' + index"
                class="carousel-image"
                @mouseover="hoverImage" @mouseleave="leaveImage"/>
          </div>
        </div>
        <div class="bg4">
          <div class="bg4-bg">
            <h3 class="bg4-h3">自由返佣，隨心所欲</h3>
            <p class="bg4-p">下線返佣想返多少   由您決定</p>
            <ul class="bg4-ul">
              <li>無需客服協助， 擺脫無效溝通</li>
              <li>自由返佣功能，返佣金額由您決定</li>
              <li>隨時隨地在代理後台發送下線邀請</li>
            </ul>
            <div class="bg4-image"></div>
          </div>
        </div>
        <div class="bg5">
          <div class="main-bg">
            <div class="bg5-title"></div>
            <div class="bg5-image"></div>
          </div>
          </div>
        <div class="main-bg">
          <div class="bg6">
            <div class="bg6-title"></div>
            <div class="bg6-image"></div>
          </div>
          <div class="bg7">
            <div class="bg7-title"></div>
            <div class="bg7-image"></div>
          </div>
          <div class="bg8">
            <div class="bg8-title"></div>
            <h3>發展客戶 - 核心功能板塊</h3>
            <div class="bg8image-container">
              <div class="bg8-image1"></div>
              <ul class="bg8-ul"><h2 class="bg8-h2">海報推廣</h2>
                <li>默認設置不返佣給客戶，降低選項過多導致操作複雜的情景。</li>
                <li>實時顯示當前返佣規則，降低錯誤操作。</li>
                <li>不懂設計也能輕鬆製作各種海報需求。</li>
                <li>線上生成，操作簡單，一鍵生成專屬海報。</li>
                <li>方便裂變推廣，自動綁定關係。</li>
              </ul>
            </div>
            <div class="bg8image-container">
              <div class="bg8-image2"></div>
              <ul class="bg8-ul"><h2 class="bg8-h2">鏈接推廣</h2>
                <li>「推薦連結」與「二維碼」 附帶自動綁定上下線功能，代理可依自身需求選擇是否返佣給客戶，生成後好友無需填寫推薦編號，點擊連結或掃碼後即可直接跳轉至 註冊介面，自動綁定關係。</li>
                <li>系統設置了推薦文案，用戶可以自由選擇並一鍵複製使用。</li>
              </ul>
            </div>
            <div class="bg8image-container">
              <div class="bg8-image3"></div>
              <ul class="bg8-ul"><h2 class="bg8-h2">編號推廣</h2>
                <li>用來發展下線客戶時，綁定上下線關係。</li>
                <li>選擇是否收取客戶手續費（二選一），點擊“複製編號”，就能直接發給客戶使用。</li>
              </ul>
            </div>
            <h3 class="bg8-h3">發展下線代理 - 核心功能板塊</h3>
            <div class="bg8image-container">
              <ul class="bg8-ul"><h2 class="bg8-h2">海報推廣</h2>
                <li>發展下線代理專用海報</li>
                <li>上傳個人微信或其他二維碼，即可生成專屬海報</li>
                <li>二維碼會顯示在海報中，方便其他人掃描後私聊。</li>
              </ul>
              <div class="bg8-image4"></div>
            </div>
            <div class="bg8image-container">
              <ul class="bg8-ul"><h2 class="bg8-h2">發展下線代理</h2>
                <li>無需像過往一樣必須叫客服協助開戶，用戶可自行在後台發送下線開戶邀請</li>
                <li>有效降低了代理與客服之間的溝通成本與時間成本。</li>
                <li>有效避免了因客服無法即時處理而導致等候時間過久，使客戶流失的風險。</li>
              </ul>
              <div class="bg8-image5"></div>
            </div>
        </div>
        </div>
        <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
  import back from '@/components/back/index.vue'
  export default {
    name: 'MyCarousel',
    components: {
        back,
    },
    data() {
      return {
        paused: 'running', // 控制动画的播放状态
        posters: [
          '202310寒露.jpg', '202310励志海报.jpg', '202310万圣节.jpg',
          '202309秋分.jpg', '202309白露.jpg', '202309中秋节.jpg',
          '202308立秋.jpg', '202308励志海报.jpg', '202308七夕.jpg',
          '202307励志海报.jpg', '202307大暑.jpg', '202307小暑.jpg',
          // ...其他海报文件名
        ]
      };
    },
    computed: {
      tripledPosters() {
        return [...this.posters, ...this.posters, ...this.posters];
      }
    },
    methods: {
      getImgPath(poster) {
        return require('@/assets/posters/' + poster);
      },
      hoverImage() {
        this.paused = 'paused'; // 鼠标悬停时暂停动画
      },
      leaveImage() {
        this.paused = 'running'; // 鼠标离开后恢复动画
      }
    }
  };
  </script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h2{
        font-weight: 900;
      }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        }
      h4{
        font-family: 'SourceSansPro-Regular';
        font-size: 22px;
        font-weight: 500;
        }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        line-height: 32px;
        color: #444444;
        }
        .Pbold{
            font-weight: 900;
        }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .bg-banner{
      position: relative;
      height: 562px;
      background-image: url('./assets/bg-banner.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .main-bg{
      width: 1200px;
      // background-color: #81ACCF;
      margin: 0 auto;
      .bg-magin-top{
        margin-top:120px
      }
      .p-magin-top{
        margin-top:30px
      }
  }
  .bg1{
    margin-top: 120px;
    height: auto;
      .bg1-title{
          margin-bottom: 80px;
          height: 110px;
          width: 220px;
          background-image: url('./assets/bg1-title.png');
          background-repeat: no-repeat;
          background-size: contain;
      }
      .bg1-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-box{
          width: 55%;
        }
      }
        .bg1-image{
            margin-top: 110px;
            height: 400px;
            width: 400px;
            background-image: url('./assets/bg1-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg1-p{
            margin-top: 30px;
        }
        .bg1-h2{
          margin-top: 80px;
        }
        .bg1-p2{
            margin-top: 30px;
        }
    }
  .bg2{
        margin-top: 120px;
        text-align: center;
        .bg2-title{
            height: 110px;
            width: 220px;
            margin-bottom: 80px;
            background-image: url('./assets/bg2-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg2-image{
          margin-top: 100px;
            height: 350px;
            width: 100%;
            background-image: url('./assets/bg2-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg2-p{
          margin: 20px 0;
        } 
        .bg2-h3{
          margin-top: 180px;
        }
    }
  .bg4{
        position: relative;
        height: 715px;
        width: 100%;
        background-color: #ffa000;
        overflow: hidden;
        text-align: center;
        .bg4-bg{
              width: 1200px;
              margin: 0 auto;
            }
        .bg4-p{
          margin: 20px 0;
        } 
        .bg4-h3{
          margin-top: 130px;
        }
        .bg4-ul{
          margin-top: 100px;
          margin-bottom: 60px;
          font-size: 22px;
          display: flex;
          color: #fff;
          gap: 13vh;
        }
        .bg4-image{
          height: 330px;
          width: 100%;
          background-image: url('./assets/bg4-image.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
    }
  .bg5{
      position: relative;
      height: 800px;
      background: linear-gradient(to bottom, #fef0ea 0%, #ffffff 100%);
        .bg5-title{
          position: relative;
          top: 80px;
          height: 110px;
          width: 280px;
          background-image: url('./assets/bg5-title.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .bg5-image{
            margin-top: 180px;
            height: 410px;
            width: 100%;
            background-image: url('./assets/bg5-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
  .bg6{
        .bg6-title{
            height: 110px;
            width: 220px;
            background-image: url('./assets/bg6-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg6-image{
          margin-top: 120px;
            height: 600px;
            width: 100%;
            background-image: url('./assets/bg6-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
  .bg7{
    margin-top: 130px;
        .bg7-title{
            height: 110px;
            width: 220px;
            margin-top: 130px;
            background-image: url('./assets/bg7-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg7-image{
          margin-top: 130px;
          height: 810px;
          width: 100%;
          background-image: url('./assets/bg7-image.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
    }
  .bg8{
    margin-top: 130px;
    text-align: center;
        .bg8-title{
            margin-bottom: 130px;
            height: 110px;
            width: 300px;
            background-image: url('./assets/bg8-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg8-h3{
            margin-top: 150px;
          }
        .bg8image-container{
          margin-top: 100px;
          width: 100%;
          height: 350px;
          display: flex;
          gap: 80px;

          .bg8-h2{
            font-size: 26px;
            margin: 30px 0;
          }
          .bg8-image1{
              height: 400px;
              width: 60%;
              background-image: url('./assets/bg8-image1.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg8-image2{
              height: 400px;
              width: 60%;
              background-image: url('./assets/bg8-image2.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg8-image3{
              height: 400px;
              width: 60%;
              background-image: url('./assets/bg8-image3.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg8-image4{
              height: 400px;
              width: 60%;
              background-image: url('./assets/bg8-image4.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg8-image5{
              height: 400px;
              width: 60%;
              background-image: url('./assets/bg8-image5.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          
          .bg8-ul{
            font-size: 18px;
            width: 40%;
            list-style-type: none; /* 移除默认的项目符号 */
            padding-left: 0; /* 移除默认的填充 */
            li{
              text-align: left;
              margin-bottom: 13px; /* 设置列表项之间的间距 */
              padding-left: 50px; /* 为自定义项目符号留出空间 */
              background-image: url('./assets/point.png');
              background-repeat: no-repeat; /* 确保图片不重复 */
              background-position: 0 1px;
              background-size: 5%;
              list-style-type: none;
            }
          }
        }
    }

  .carousel-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100vw; /* 视口宽度 */
    height: 60vh;
    margin-top: 8vh;
    display: flex;
    align-items: center;
    background-image: url('./assets/bg3-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    .carousel {
      display: flex;
      animation: scroll linear infinite;
      animation-duration: 90s; /* 动画持续时间 */
      animation-play-state: var(--paused); /* 使用 CSS 变量控制播放状态 */
    }
    .carousel-image {
      width: calc((100vw - (13 * 30px)) / 6.5); /* 6张完整和2张半张图片的宽度，减去总间隔 */
      height: auto;
      margin-right: 30px; /* 为每张图片设置右间隔 */
      border-radius: 10px;
      object-fit: cover; /* 避免图片变形 */
      transition: transform 0.3s ease; /* 平滑过渡效果 */
      position: relative; /* 为伪元素定位做准备 */
      box-shadow: 0 0 0 0 rgba(255, 165, 0, 0); /* 初始阴影设置为透明 */
      transition: transform 0.3s ease; /* 添加 box-shadow 到过渡效果 */
    }
    .carousel-image:hover {
      transform: scale(1.2); /* 鼠标悬停时放大图片 */
      box-shadow: 0 0 15px 5px rgba(255, 165, 0, 0.7); /* 悬停时的边框光效 */
      animation: glowing-border 1.5s infinite alternate; /* 应用动画 */
    }
  }
  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }

  @keyframes glowing-border {
    0% {
      box-shadow: 0 0 10px 0 rgba(255, 165, 0, 0.7);
    }
    50% {
      box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 10px 0 rgba(255, 165, 0, 0.7);
    }
  }
  
  .carousel-image::after {
    content: '';
    position: absolute;
    top: -10px; /* 根据需要调整阴影的位置 */
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-radius: 15px; /* 保持圆角与图片一致 */
    z-index: -1;
    box-shadow: 0 0 30px rgba(255, 165, 0, 0); /* 初始化时没有阴影 */
    opacity: 0; /* 初始化时不可见 */
    transition: opacity 0.3s ease;
  }
  
  .carousel-image:hover::after {
    animation: dynamic-glow 2s infinite linear;
    opacity: 1; /* 鼠标悬停时显示阴影 */
  }
  
  @keyframes dynamic-glow {
    0%, 100% {
      box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0);
    }
    50% {
      box-shadow: -10px -10px 40px 20px rgba(255, 165, 0, 0.5); /* 阴影位置和大小的变化 */
    }
  }
  
  
  @keyframes scroll {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(calc(-100vw / 6.5 * 24), 0, 0); /* 36是三倍的海报数量 */
    }
  }
</style>