<template>
    <div class="footerContainer">
        © 2024 Leo Chum | 創新引領的數字旅程
    </div>
</template>

<style lang="less" scoped>
.footerContainer{
    position: absolute;
    bottom: 4vh;
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    font-size: 18px;
}
/* 当屏幕宽度小于或等于某个值时显示提示 */
@media (max-width: 430px) {
  .footerContainer{
    font-size: 14px;
  }
}
</style>