<template>
  <div  class="bgContainer" @scroll="handleScroll">
    <section class="bannerContainer">
      <img src="./assets/sky.png" ref="mountains_behind" id="mountains_behind">
      <div ref="text" id="text"></div>
      <img src="./assets/brief-bg.png" ref="mountains_front"  id="mountains_front">
      <img v-show="showGif" src="./assets/downGif.gif" class="gifClass" />
    </section> 
    <div class="containerOne">
      <div class="wow animate__animated animate__fadeInUp titleOne" data-wow-duration="1s"></div>
      <p class="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s">在這個瞬息萬變的數字世界裡，我就像是一艘航行在無盡海洋中的帆船，不斷探索和駕馭每一股商業和技術的潮流。我熱衷於將混沌的創意轉化為精細的產品藍圖，用數據的指南針引領團隊駛向成功的港灣。每一個項目都是一次新的航程，每一次創新都是對未知的征服。</p>
      <div class="wow animate__animated animate__fadeInUp titleOne-image" data-wow-duration="1s"></div>
    </div>
  <!-- 各種項目，輕車熟路 -->
  <div class="containerTwo">
    <div class="wow animate__animated animate__fadeInUp titleTwo" data-wow-duration="1s"></div>
    <p class="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s">擁有多項目實戰經驗，能夠熟練應對與實施；從規劃到執行，迅速適應，確保任務高質量完成。</p>
    <div class="card-grid">
      <div v-for="card in cards" :key="card.id" class=" wow animate__animated animate__fadeInUp card-link" data-wow-duration="1.5s">
        <!-- 卡片樣式 -->
        <div class="card">
        <div class="cardHeader">
          <div class="cardHeaderAvator" :style="{ backgroundImage: 'url(' + card.photo + ')' }"></div>
          <p class="cardHeaderUp">{{card.title}}</p>
          <div class="textstyle" :style="{ backgroundImage: 'url(' + card.subtitle + ')' }"></div>
        </div>
      </div>
    </div>
    </div>
  </div>
    <!-- 需求、開發、優化 -->
  <div class="containerThree">
    <div class="wow animate__animated animate__fadeInUp titleThree" data-wow-duration="1s"></div>
    <p class="wow animate__animated animate__fadeInUp" data-wow-duration="1.5s">深耕用戶體驗，從洞悉需求到技術開發，再至迭代優化，專注於提升產品的核心競爭力，確保每一次創新都貼合用戶心聲。</p>
    <div class="wow animate__animated animate__fadeInUp flowImg"></div>
  </div>
    
    <!-- 我的技能寶典 -->
    <div class="containerFour">
      <div class="wow animate__animated animate__fadeInUp titleFour" data-wow-duration="1s"></div>
      <div class="skill-boy"></div>
      <div class="skill-page1">
        <div class="skillTitle1"></div>
        <div class="boxes-container">
        <div class="box1 wow popUp" data-wow-duration="1.5s" data-wow-delay="0.5s">
          <div class="abtestbox"></div>
          <p class="boxName">A/B&nbsp;Test</p>
        </div>
        <div class="box1 wow popUp" data-wow-duration="1.9s" data-wow-delay="0.5s">
          <div class="aarrrbox"></div>
          <p class="boxName">AARRR</p>
        </div>
      </div>  
      </div>
      <div class="skill-page2">
        <div class="skillTitle2"></div>
        <div class="boxes-container">
        <div class="box1 wow popUp" data-wow-duration="1.5s" data-wow-delay="0.5s">
          <div class="pythonbox"></div>
          <p class="boxName">Python</p>
        </div>
        <div class="box1 wow popUp" data-wow-duration="1.9s" data-wow-delay="0.5s">
          <div class="mysqlbox"></div>
          <p class="boxName">SQL</p>
        </div>
        <div class="box1 wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="jupyterbox"></div>
          <p class="boxName">Jupyter</p>
        </div>
      </div>  
      </div>
      <div class="skill-page3">
        <div class="skillTitle3"></div>
        <div class="boxes-container">
          <div class="box1 wow popUp" data-wow-duration="1.5s" data-wow-delay="0.5s">
          <div class="chatgptbox"></div>
          <p class="boxName">ChatGPT</p>
        </div>
        <div class="box1 wow popUp" data-wow-duration="1.9s" data-wow-delay="0.5s">
          <div class="midjourneybox"></div>
          <p class="boxName">Midjourney</p>
        </div>
        <div class="box1 wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="mlbox"></div>
          <p class="boxName">ML</p>
        </div>
        </div>
        
      </div>
      <div class="skill-page4">
        <div class="skillTitle4"></div>
        <div class="boxes-container">
        <div class="box2 wow popUp" data-wow-duration="1.5s" data-wow-delay="0.5s">
          <div class="htmlbox"></div>
          <p class="boxName">HTML</p>
        </div>
        <div class="box2 wow popUp" data-wow-duration="1.9s" data-wow-delay="0.5s">
          <div class="cssbox"></div>
          <p class="boxName">CSS</p>
        </div>
        <div class="box2 wow popUp" data-wow-duration="2.2s" data-wow-delay="0.5s">
          <div class="vscodebox"></div>
          <p class="boxName">VS&nbsp;Code</p>
        </div>
        <div class="box2 boxY wow popUp" data-wow-duration="2.2s" data-wow-delay="0.5s">
          <div class="jsbox"></div>
          <p class="boxName">JavaScript</p>
        </div>
        <div class="box2 wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="vuebox"></div>
          <p class="boxName">Vue</p>
        </div>
      </div>
      </div>
      <div class="skill-page5">
        <div class="skillTitle5"></div>
        <div class="boxes-container">
        <div class="box2 blackbox wow popUp" data-wow-duration="2.2s" data-wow-delay="0.5s">
          <div class="photoshopbox"></div>
          <p class="boxName">photoshop</p>
        </div>
        <div class="box2 blackbox wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="figmabox"></div>
          <p class="boxName">Figma</p>
        </div>
      </div>
      </div>
      <div class="skill-page6">
        <div class="skillTitle6"></div>
        <div class="boxes-container">
        <div class="box3 wow popUp" data-wow-duration="2.2s" data-wow-delay="0.5s">
          <div class="pptbox"></div>
          <p class="boxName">PPT</p>
        </div>
        <div class="box3 wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="wordbox"></div>
          <p class="boxName">Word</p>
        </div>
        <div class="box3 wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="excelbox"></div>
          <p class="boxName">Excel</p>
        </div>
        <div class="box3 boxB wow popUp" data-wow-duration="2.5s" data-wow-delay="1s">
          <div class="processonbox"></div>
          <p class="boxName">ProcessOn</p>
        </div>
      </div>
      </div>
      <div class="skill-page7">
        <div class="skillTitle7"></div>
        <div class="boxes-container">
          <p class="boxName">更多工作技能正在解鎖中...</p>
      </div>
      </div>
    </div>
    
    <!-- 左下角导航栏 -->
    <backBtn @hover-start="overlay=true" @hover-end="overlay= false" /> 
    <div class="overlay" :style="{opacity:overlay ? 1 : 0}"></div>
  </div>
</template>

<script>
import backBtn from '@/components/backBtn/index.vue'
import WOW from "wow.js"
import { onMounted } from 'vue'

export default {
  setup(){
    onMounted(()=> {
      var wow = new WOW({
        boxClass: 'wow',
        animateClass: "animated",
        offset: 0,
        mobile:true,
        live:true,
        scrollcontainer:null,
        resetAnimation:true,
      });
      wow.init();
    })
  },
  components: {
    backBtn,
  },
  data() {
    return {
      overlay: false,
      showGif: true, // 初始化 GIF 显示状态
      cards:[
        {
          id: 1,
          title:'活動策劃',
          photo:require('@/Pages/BriefPage/assets/idea.gif'),
          subtitle:require('@/Pages/BriefPage/assets/textD.png'),
        },
        {
          id: 2,
          title:'數據分析',
          photo:require('@/Pages/BriefPage/assets/analysis.gif'),
          subtitle:require('@/Pages/BriefPage/assets/textD.png'),
        },
        {
          id: 3,
          title:'功能迭代優化',
          photo:require('@/Pages/BriefPage/assets/medal.gif'),
          subtitle:require('@/Pages/BriefPage/assets/textD.png'),
        },
        {
          id: 4,
          title:'交易系統開發',
          photo:require('@/Pages/BriefPage/assets/website.gif'),
          subtitle:require('@/Pages/BriefPage/assets/textD.png'),
        },
        {
          id: 5,
          title:'CMS管理後台',
          photo:require('@/Pages/BriefPage/assets/CMS.gif'),
          subtitle:require('@/Pages/BriefPage/assets/textD.png'),
        }
        ],
    };
  },
  mounted() {
  // 监听滚动事件
  this.$nextTick(() => {
    window.addEventListener('scroll', this.handleScroll);
  });
  },
  beforeUnmount() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 只要页面滚动，就将 showGif 设置为 false
      if(window.scrollY === 0){
        this.showGif = true;
      }else{
        this.showGif = false;
      }

      let value = window.scrollY; // 获取滚动距离
      // 计算基于滚动的偏移量，这将取决于你的具体动画效果
      // 更新元素样式
      this.$refs.mountains_behind.style.top = value * 0.5 + 'px';
      this.$refs.mountains_front.style.top = value * 0 + 'px';
      this.$refs.text.style.transform = `translate(-50%, -50%) translate(${-value * 0.8}px, ${value * 0.8}px)`;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Balsamiq Sans, 'SourceSansPro-Regular', Georgia,'PingFang SC', Helvetica, sans-serif;
  /* 当用户手动导航或者 CSSOM scrolling API 触发滚动操作时 */
  scroll-behavior: smooth;
  h2 {
    font-size: 5vh;
    color: #fff;
    padding-top: 10vh;
  }
  p {
  font-size: 2.5vh;
  padding: 4vh 0;
  color: #fff;
}
}
body {
  min-height: 100vh;
  overflow-x: hidden;
  background: linear-gradient(#2b1055, #7597de);
}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background-color: aqua;
  // overflow-x: hidden;
}


#text{
  position: absolute;
  top: 7%;
  left: 105%;
  transform: translate(-50%, -50%); /* 中心对齐 */
  height: 22vh;
  width: 100%;
  background-image: url('./assets/moon.png');
  background-repeat: no-repeat;
  background-size: contain;
}
// hover遮罩
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none; /*確保遮罩層不干擾下面的元素交互*/
}
/* 滾動效果 */
// 頂部banner
section {
  position: relative;
  width: 100%;
  height: 100vh;
  // padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

   &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, #1c0522, transparent);
    z-index: 1000;
  }
  .gifClass{
    width: 15vh;
    height: 15vh;
    z-index: 9999;
    position: relative;
    top: 40vh;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* object-fit CSS 属性指定可替换元素（例如：<img> 或 <video>）的内容应该如何适应到其使用高度和宽度确定的框。 */
    object-fit: cover;
    pointer-events: none;

    &#moon {
      /* mix-blend-mode CSS 属性描述了元素的内容应该与元素的直系父元素的内容和元素的背景如何混合。 */
      mix-blend-mode: screen;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500vh;
      height: 500vh;
      transform: translate(-50%, -50%); /* 中心对齐 */
    }

    &#mountains_front {
      z-index: 10;
    }
  }
  #text {
    position: absolute;
    // top: 20vh;
    right: -30vh;
    color: #fff;
    white-space: nowrap;
    font-size: 7.5vw;
    z-index: 9;
  }
}
// 第一部份:歡迎來到我的世界
.containerOne {
  position: relative;
  padding: 6vh 20vh;
  height: 100vh;
  background: #1c0522;
  display: flex;
  flex-direction: column;
  text-align: center;

  .titleOne {
    margin: 6vh 0;
    width: 100%;
    height: 5vh;
    background-image: url('./assets/titleOne.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  P{
    padding: 0 28vh;
  }
  .titleOne-image{
    position: relative;
    top: 3vh;
    left: 16vh;
    width: 100%;
    height: 60vh;
    background-image: url('./assets/BBG.png');
    background-repeat: no-repeat;
    background-size: contain;
    // background-position: center;
  }
}
// 第二部分：各種項目，輕車熟路
.containerTwo {
  position: relative;
  padding: 0 20vh;
  height: 100vh;
  background: #1c0522;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  .titleTwo {
    margin: 6vh 0;
    width: 100%;
    height: 5.5vh;
    background-image: url('./assets/titleTwo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  // 卡片容器
.card-grid {
  height: 50vh;
  width: 100%;
  position: relative; /* 確保子元素可以相對於這個容器定位 */
  margin-top: 10vh;
  display: flex;
  gap: 6vh; /* 卡片之间的间隔 */
  align-items: center;
  justify-content: center;
  background-color: #1c0522;
  .card {
    flex: 1; /* 每张卡片都有相同的灵活性 */
    height: 37vh;
    width: 25vh;
    background:#1c0522;
    box-sizing: border-box;
    position: relative;
    border-radius: 20px;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        hsl(300deg,100%,50%),
        hsl(200deg,100%,50%),
        hsl(100deg,100%,50%)
      );
      border-radius: 2vh;
      filter: blur(24px);
      z-index: -1;
    }
    .cardHeader{
      width: 100%;
      height: 60vh;
      .cardHeaderAvator {
      position: relative;
      transform: translateX(-50%);
      top: 2vh;
      left: 50%;
      height: 15vh;
      width: 20vh;
      border-radius: 1vh;
      background-size: contain; /* 确保图片完整地覆盖整个元素 */
      background-position: center; /* 图片居中显示 */
      background-repeat: no-repeat;
      background-color: #fff4e6;
    }
    .cardHeaderUp{
      position: absolute;
      text-align: center;
      bottom: 1.5vh;
      right: 0;
      left: 0;
      text-align: center;
      // transform: translateX(-50%); /* 向左移动自身宽度的一半 */
      margin: 15px 0;
      font-size: 3vh;
      color: #40ce7f;
      z-index: 2;
    }
    .textstyle{
      position: absolute;
      bottom: 3.5vh;
      left: 10%;
      height: 2.5vh;
      width: 20vh;
      background-size: contain; /* 确保图片完整地覆盖整个元素 */
      background-position: center; /* 图片居中显示 */
      background-repeat: no-repeat;
    }
    }
  }
  }
}
// 第三部分：需求、開發、優化
.containerThree {
  position: relative;
  padding: 0vh 20vh;
  height: 100vh;
  background: #1c0522;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  .titleThree {
    position: absolute;
    top: 12vh;
    left: 50vh;
    // margin: 6vh 0;
    width: 95vh;
    height: 10vh;
    background-image: url('./assets/titleThree.png');
    background-repeat: no-repeat;
    background-size: contain;
    // background-position: center;
  }
  P{
    position: absolute;
    top: 30vh;
    padding: 0vh 20vh;
    
  }
  .flowImg{
    margin-top: 30vh;
    height: 20vh;
    width: 170vh;
    background-image: url('./assets/flow.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
// 第四部分：我的技能寶典
.containerFour{
  position: relative;
  padding: 0vh 20vh;
  height: 120vh;
  // width: 100%;
  background-color: #fdf0e9;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    background: linear-gradient(
      to bottom, /* 渐变方向向下 */
      #1c0522 0%, /* 渐变开始颜色 */
      #342755 50%, /* 中间过渡颜色，可以调整这些值实现平滑过渡 */
      #564f88 75%, /* 中间过渡颜色 */
      transparent 100% /* 渐变结束颜色 */
    );
    z-index: 1000;
  }
  .titleFour {
    position: relative;
    top: 25vh;
    height: 5vh;
    background-image: url('./assets/titleFour.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill-boy{
    position: absolute;
    top: 55vh;
    left: 12vh;
    width: 50vh;
    height: 65vh;
    background-image: url('./assets/skillBoy.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .skill-page1{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65vh;
    // height: auto;
    top: 29vh;
    left:-6.5vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 5.3vh;
      left: 14.5vh;
      width: 35vh; /* 或者您想要的宽度 */
      height: 23vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
    .skillTitle1{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 2vh 0;
        background-image: url('./assets/yunying.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .abtestbox{
      position: relative;
      width: 70%;
      height: 70%;
      background-image: url('./assets/abtest.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .aarrrbox{
      position: relative;
      width: 70%;
      height: 70%;
      left: 0.3vh;
      background-image: url('./assets/hg.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
  }
  .skill-page2{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65vh;
    // height: auto;
    top: 8vh;
    left:43vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 5.3vh;
      left: 8.1vh;
      width: 48vh; /* 或者您想要的宽度 */
      height: 23vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
    .skillTitle2{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 2vh 0;
        background-image: url('./assets/analysis.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .pythonbox{
      position: relative;
      width: 70%;
      height: 70%;
      background-image: url('./assets/python.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .mysqlbox{
      position: relative;
      width: 70%;
      height: 70%;
      background-image: url('./assets/mysql.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .jupyterbox{
      position: relative;
      width: 80%;
      height: 80%;
      top: 0.5vh;
      left: 0.9vh;
      background-image: url('./assets/jupyter.png');
      background-repeat: no-repeat;
      background-size: contain;
      // background-position: center;
    }
  }
  .skill-page3{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65vh;
    // height: auto;
    top: -13vh;
    left:99vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 5.3vh;
      left: 8.1vh;
      width: 48vh; /* 或者您想要的宽度 */
      height: 23vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
    .skillTitle3{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 2vh 0;
        background-image: url('./assets/ai.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    .chatgptbox{
      position: relative;
      width: 70%;
      height: 70%;
      background-image: url('./assets/chatgpt.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .midjourneybox{
      position: relative;
      width: 70%;
      height: 70%;
      left: 0.5vh;
      background-image: url('./assets/midjourney.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .mlbox{
      position: relative;
      width: 80%;
      height: 80%;
      // top: 0.5vh;
      // left: 0.9vh;
      background-image: url('./assets/ML.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .skill-page4{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 85vh;
    top: -3vh;
    left:37.5vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 4.8vh;
      left: 6vh;
      width: 72.5vh; /* 或者您想要的宽度 */
      height: 21vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
      .skillTitle4{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        background-image: url('./assets/kaifa.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .htmlbox{
      position: relative;
      width: 70%;
      height: 70%;
      top: 0.4vh;
      left: 0.5vh;
      background-image: url('./assets/html.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .cssbox{
      position: relative;
      width: 70%;
      height: 70%;
      top: 0.4vh;
      left: 0.5vh;
      background-image: url('./assets/CSS.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .vscodebox{
      position: relative;
      width: 65%;
      height: 65%;
      top: 0.3vh;
      background-image: url('./assets/vscode.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .jsbox{
      position: relative;
      width: 55%;
      height: 55%;
      top: 2vh;
      left: 0.6vh;
      background-image: url('./assets/js.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .vuebox{
      position: relative;
      width: 75%;
      height: 75%;
      top: 0.7vh;
      left: 0.4vh;
      background-image: url('./assets/vue.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .skill-page5{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vh;
    top: -22vh;
    left:110vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 4.8vh;
      left: 14vh;
      width: 31vh; /* 或者您想要的宽度 */
      height: 21vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
      .skillTitle5{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        background-image: url('./assets/sheji.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .photoshopbox{
      position: relative;
      width: 55%;
      height: 55%;
      background-image: url('./assets/photoshop.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .figmabox{
      position: relative;
      width: 70%;
      height: 70%;
      top: 0.4vh;
      left: 0.3vh;
      background-image: url('./assets/figma.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
  }
  .skill-page6{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vh;
    top: -12vh;
    left:50vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 4.8vh;
      left: 4.3vh;
      width: 51vh; /* 或者您想要的宽度 */
      height: 17vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
      .skillTitle6{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        background-image: url('./assets/bangong.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .pptbox{
      position: relative;
      width: 70%;
      height: 70%;
      background-image: url('./assets/ppt.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .wordbox{
      position: relative;
      width: 75%;
      height: 75%;
      background-image: url('./assets/word.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .excelbox{
      position: relative;
      width: 65%;
      height: 65%;
      background-image: url('./assets/excel.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center
    }
    .processonbox{
      position: relative;
      width: 55%;
      height: 55%;
      background-image: url('./assets/processon.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .skill-page7{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vh;
    top: -29vh;
    left:104vh;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 4.8vh;
      left: 9vh;
      width: 42vh; /* 或者您想要的宽度 */
      height: 17vh; /* 或者您想要的高度 */
      border: 2px solid #F5F5FA; /* 白色边框 */
      border-radius: 2vh;
      box-shadow: 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.18), /* 阴影效果 */
                inset 0.9vh 0.9vh 2.3vh rgba(0, 0, 0, 0.15);
    }
      .skillTitle7{
        position: relative;
        width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        background-image: url('./assets/other.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .boxName{
        position: absolute;
        top: 9vh;
        color: #666;
      }
    }
.boxes-container {
  display: flex; /* 這將使 .box 子元素水平排列 */
  justify-content: center; /* 水平居中對齊 .box */
  gap: 5vh; /* .box 之間的間隔 */
  // 第一層box
  .box1 {
    position: relative;
    width: 10vh;
    height: 10vh;
    background-color: #c9d5e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5vh; /* 50px / 300px * 20vh */
    transform-origin: center; // 设置变换的原点为中心
    box-shadow: 2vh 2vh 2vh -0.667vh rgba(0, 0, 0, 0.15),
                inset 0.5vh 0.5vh 0.667vh rgba(255, 255, 255, 0.75),
                -3vh -3vh 4.333vh rgba(255, 255, 255, 0.55),
                inset -0.067vh -0.067vh 0.667vh rgba(0, 0, 0, 0.2);
      .boxName{
        position: absolute;
        top: 12vh;
        left: 52%;
        transform: translate(-50%);
        color: #333;
        padding: 0 0;
      }
  }
  .box2.boxY {
    background-color: #fde930
  }
  .box2.blackbox{
      background-color: #1a1d21
    }
  // 第二層box
  .box2 {
    position: relative;
    width: 9vh;
    height: 9vh;
    background-color: #c9d5e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5vh; /* 50px / 300px * 20vh */
    transform-origin: center; // 设置变换的原点为中心
    box-shadow: 2vh 2vh 2vh -0.667vh rgba(0, 0, 0, 0.15),
                inset 0.5vh 0.5vh 0.667vh rgba(255, 255, 255, 0.75),
                -3vh -3vh 4.333vh rgba(255, 255, 255, 0.55),
                inset -0.067vh -0.067vh 0.667vh rgba(0, 0, 0, 0.2);
      .boxName{
        position: absolute;
        top: 11vh;
        left: 52%;
        font-size: 2.3vh;
        transform: translate(-50%);
        color: #333;
        padding: 0 0;
      }
  }
  // 第三層box
  .box3 {
    position: relative;
    width: 7vh;
    height: 7vh;
    background-color: #c9d5e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5vh; /* 50px / 300px * 20vh */
    transform-origin: center; // 设置变换的原点为中心
    box-shadow: 2vh 2vh 2vh -0.667vh rgba(0, 0, 0, 0.15),
                inset 0.5vh 0.5vh 0.667vh rgba(255, 255, 255, 0.75),
                -3vh -3vh 4.333vh rgba(255, 255, 255, 0.55),
                inset -0.067vh -0.067vh 0.667vh rgba(0, 0, 0, 0.2);
      .boxName{
        position: absolute;
        top: 8vh;
        left: 52%;
        font-size: 2vh;
        transform: translate(-50%);
        color: #333;
        padding: 0 0;
      }
  }
  .box3.boxB{
      background-color: #3679e7
    }
}

// box動畫
@keyframes popUp {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.popUp {
  animation-name: popUp;
  animation-duration: 2s;
  animation-fill-mode: both;
}
}
</style>