<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="main-bg">
          <div class="bg1">
              <h1 class="main-title">信用違約預測模型</h1>
              <h3>項目背景</h3>
              <h6>PROJECT BACKGROUND</h6>
              <p class="bg1-p">進行資料建模以預測使用者未來違約的可能性，目的在於為金融機構提供精確的風險評估工具。這可以幫助借貸者進行更明智的財務決策，同時也使金融機構能夠更有效地管理信用風險，避免潛在的不良貸款，從而保護其資產安全。此模型運用機器學習技術，通過分析歷史信用數據來識別可能的違約指標。</p>
              <div class="bg1-image"></div>
            </div>
          <div class="bg2">
              <h3>數據說明</h3>
              <h6>DATA DESCRIPTION</h6>
              <p><span class="Pbold">數據來源：</span> Kaggle</p>
              <p><span class="Pbold">數據大小：</span> 150000條數據</p>
              <p><span class="Pbold">字段名稱描述：</span></p>
              <el-table
                :data="tableData"
                stripe
                border
                style="font-size: 18px;"
                class="tableStyle">
                <el-table-column
                  prop="name"
                  label="列名稱"
                  width="400">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="說明">
                </el-table-column>
              </el-table>
          </div>
          <div class="bg3">
              <h3>數據讀取、初探</h3>
              <h6>DATA READING & EXPLORATION</h6>
              <h4>一、數據讀取及基本描述總覽</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 导入需要的库
              import pandas as pd
              import numpy as np
              import seaborn as sns
              import matplotlib.pyplot as plt
              from sklearn.model_selection import train_test_split

              import warnings
              warnings.filterwarnings('ignore')
                  </code>
              </pre>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 讀取數據並替換列名
              df = pd.read_csv('./cs-training.csv')
              df = df.drop('Unnamed: 0', axis=1)
              df.rename(columns = {'SeriousDlqin2yrs':'未來兩年可能違約', 'RevolvingUtilizationOfUnsecuredLines':'可用信貸額度比例', 'age':'年齡',
                    'NumberOfTime30-59DaysPastDueNotWorse':'逾期30-59天的筆數', 'DebtRatio':'負債率', 'MonthlyIncome':'月收入',
                    'NumberOfOpenCreditLinesAndLoans':'信貸數量', 'NumberOfTimes90DaysLate':'逾期90天+的筆數',
                    'NumberRealEstateLoansOrLines':'固定資產貸款數', 'NumberOfTime60-89DaysPastDueNotWorse':'逾期60-89天的筆數',
                    'NumberOfDependents':'家屬數量'},inplace=True)

              print(df.info())
              print(df.head().T)
              df.describe().T
                  </code>
              </pre>
              <br>
              <pre v-pre>
              &lt;class 'pandas.core.frame.DataFrame'&gt;
              RangeIndex: 150000 entries, 0 to 149999
              Data columns (total 11 columns):
              #   Column       Non-Null Count   Dtype  
              ---------------------------- 
              0   未來兩年可能違約               150000 non-null  int64  
              1   可用信貸額度比例               150000 non-null  float64
              2   年齡                                             150000 non-null  int64 
              3   逾期30-59天的筆數             150000 non-null  int64
              4   負債率                                        150000 non-null  float64
              5   月收入                                        120269 non-null  float64
              6   信貸數量                                   150000 non-null  int64
              7   逾期90天+的筆數                 150000 non-null  int64  
              8   固定資產貸款數                    150000 non-null  int64  
              9   逾期60-89天的筆數             150000 non-null  int64
              10  家屬數量                                 146076 non-null  float64
              dtypes: float64(4), int64(7)
              memory usage: 12.6 MB
              None
              ---------------------------- 
              </pre>
              <pre v-pre>
                                                                            0                               1                               2                             3                             4
            未來兩年可能違約               1.000000               0.000000               0.000000               0.00000               0.000000
            可用信貸額度比例               0.766127               0.957151               0.658180               0.23381               0.907239
            年齡                                             45.000000            40.000000             38.000000            30.00000             49.000000
            逾期30-59天的筆數               2.000000               0.000000               1.000000               0.00000               1.000000
            負債率                                        0.802982               0.121876               0.085113               0.03605               0.024926
            月收入                                        9120.000000     2600.000000          3042.000000       3300.00000        63588.000000
            信貸數量                                  13.000000               4.000000               2.000000               5.00000               7.000000
            逾期90天+的筆數                  0.000000               0.000000               1.000000               0.00000                0.000000
            固定資產貸款數                    6.000000               0.000000               0.000000               0.00000                1.000000
            逾期60-89天的筆數             0.000000               0.000000               0.000000               0.00000                0.000000
            家屬數量                                  2.000000               1.000000               0.000000               0.00000                0.000000
            </pre>
              <div class="bg3-image"></div>
              <p>發現有缺失值，分別：月收入 29731， 家屬數量 3924</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              df.isnull().sum()
                  </code>
              </pre>
              <br>
              <div class="bg3-image2"></div>
          </div>
          <div class="bg4">
              <h4>二、數據字段分佈圖、箱型圖、關係熱力圖</h4>
              <h5>柱形分佈圖</h5>
              <p>輸出各字段分佈圖，查看情況，發現大多數字段明顯偏態，後續建模需考慮糾偏處理。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製各字段柱形圖
              plt.figure(figsize=(20,20), dpi=300)
              plt.subplots_adjust(wspace=0.3, hspace=0.3)

              for n,i in enumerate(df.columns):
                  plt.subplot(4,3,n+1)
                  plt.title(i,fontsize=15)
                  plt.grid(linestyle='--')
                  df[i].hist(color='grey',alpha=0.5)
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image"></div>
              <h5>箱型圖</h5>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;通過箱型圖觀察個字段異常情況。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;負債率異常值（錯誤）較多；可用信貸額度比例異常值（錯誤）較多，理論應少於或等於1。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp; “逾期30-59天的筆數”，“負債率”，“月收入”，“逾期90天+的筆數”，“固定資產貸款數”，“逾期60-89天筆數”異常值非常多，難以觀察數據分佈。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;年齡方面異常值有待觀察。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製各字段的箱線圖
              plt.figure(figsize=(20,20), dpi=300)
              plt.subplots_adjust(wspace=0.3, hspace=0.3)

              for n,i in enumerate(df.columns):
                  plt.subplot(4,3,n+1)
                  plt.title(i,fontsize=15)
                  plt.grid(linestyle='--')
                  df[[i]].boxplot(sym='.')
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image2"></div>
              <h5>熱力圖</h5>
              <p>繪製各字段之間的相關係數圖，通過下圖可見，逾期筆數這三個字段，有著非常強的線性關係，可考慮去除</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製各字段的相關係數圖表
              plt.figure(figsize=(10,5), dpi=300)
              sns.heatmap(df.corr(), cmap='Reds', annot=True)
                  </code>
              </pre>
              <br>
              <br>
              <div class="bg4-image3"></div>
          </div>
          <div class="bg5">
              <h3>數據預處理及相關函數構建</h3>
              <h6>DATA PRE-PROCESSING</h6>
              <h4>一、構建異常值及明顯錯誤處理的函數</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              def show_error(df,col,whis=1.5, show=False):
              // 顯示上下限異常值數量，可選顯示示例異常數據
              //  whis:默認1.5，對應1.5倍iqr
              //  show:是否顯示示例異常數據
                      iqr = df[col].quantile(0.75) - df[col].quantile(0.25)
                      upper_bound = df[col].quantile(0.75) + whis * iqr //上界
                      lower_bound = df[col].quantile(0.25) - whis * iqr //下界
                      print('【',col,'】上界異常值總數：',df[col][df[col] > upper_bound].count())
                      if show:
                          print('異常值示例：\n', df[col][df[col] > upper_bound].head(5).T)
                      print('【',col,'】下界異常值總數：',df[col][df[col] &lt; lower_bound].count())
                      if show:
                          print('異常值示例：\n', df[col][df[col] &lt; lower_bound].head(5).T)
                      print('------')
                      
                    
                  def drop_error(df,col):
                      iqr = df[col].quantile(0.75) - df[col].quantile(0.25)
                      upper_bound = df[col].quantile(0.75) + whis * iqr //上界
                      lower_bound = df[col].quantile(0.25) - whis * iqr //下界
                      data_del = df[col][(df[col] > upper_bound) | (df[col] &lt; lower_bound)].count()
                      data = df[col][(df[col] &lt;= upper_bound) & (df[col] >= lower_bound)]
                      print('总剔除数据量：',data_del, data)
                      return data

              // 可用信貸額度
              // 從分佈直方圖可見， 比例大於1的應該為錯誤值
              // 錯誤值共3321，若剔除可能影響建模效果。僅剔除>=20000的數據
              show_error(df,'可用信貸額度比例')
              df = df[df.可用信貸額度比例&lt;=20000]

              // 年齡
              // 異常值數量不多，剔除年齡大於100，小於18的異常數據
              show_error(df,'年齡')
              df = df[(df["年齡"]>18) & (df['年齡'] &lt; 100)]

              // 逾期30-59天的筆數
              // 根據箱型圖去除>80的異常數據
              show_error(df,'逾期30-59天的筆數')
              df = df[df['逾期30-59天的筆數'] &lt; 80]

              // 逾期60-89天的筆數
              // 根據箱型圖去除>80的異常數據
              show_error(df,'逾期60-89天的筆數')
              df = df[df['逾期60-89天的筆數'] &lt; 80]

              // 逾期90天+的筆數
              // 根據箱型圖去除>80的異常數據
              show_error(df,'逾期90天+的筆數')
              df = df[df['逾期90天+的筆數'] &lt; 80]

              // 負債率
              // 根據箱型圖去除>100000的異常數據
              show_error(df,'負債率')
              df = df[df['負債率'] &lt; 100000]

              // 月收入
              // 根據箱型圖去除>500000的異常數據
              show_error(df,'月收入')
              df = df[(df['月收入'] &lt; 500000) | df.月收入.isna()]

              // 固定資產貸款數
              // 根據箱型圖去除>20的異常數據
              show_error(df,'固定資產貸款數')
              df = df[df['固定資產貸款數'] &lt; 20]

              // 家屬數量
              // 根據箱型圖去除>10的異常數據
              show_error(df,'家屬數量')
              df = df[df['家屬數量'] &lt; 10]
                  </code>
              </pre>
              <br>
              <pre v-pre>
              【 可用信貸額度比例 】上界異常值總數： 763 
              【 可用信貸額度比例 】下界異常值總數： 0
              ------ 
              【 年齡 】上界異常值總數： 45 
              【 年齡 】下界異常值總數： 1
              ------ 
              【 逾期30-59天的筆數 】上界異常值總數： 23980 
              【 逾期30-59天的筆數 】下界異常值總數： 0 
              ------ 
              【 逾期60-89天的筆數 】上界異常值總數： 7335 
              【 逾期60-89天的筆數 】下界異常值總數： 0 
              ------ 
              【 逾期90天+的筆數 】上界異常值總數： 8068 
              【 逾期90天+的筆數 】下界異常值總數： 0 
              ------ 
              【 負債率 】上界異常值總數： 31271 
              【 負債率 】下界異常值總數： 0 
              ------ 
              【 月收入 】上界異常值總數： 4876 
              【 月收入 】下界異常值總數： 0 
              ------ 
              【 固定資產貸款數 】上界異常值總數： 793 
              【 固定資產貸款數 】下界異常值總數： 0 
              ------ 
              【 家屬數量 】上界異常值總數： 13326 
              【 家屬數量 】下界異常值總數： 0 
              ------
              </pre>
              <h4>二、構建去共線性函數</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 3種違約情況，從上節的相關係數熱力圖中，可以看出有很高的共線性。
              // 可考慮保留“逾期90天+的筆數”，求出“逾期60-89天的筆數” / “逾期30-59天的筆數”的比值。

              def collineation_processing(df,col,col1,col2,name):
              //     去除共線性，保留一個字段，其他字段求比值
              //     df：數據源
              //     col：保留字段
              //     col1，col2：求比值字段
              //     name：新比值字段名稱
                  def trans2percent(row):
                      if row[col2] == 0:
                          return 0
                      else:
                          return row[col1] / row[col2]
                  df[name] = df.apply(trans2percent, axis=1)
                  </code>
              </pre>
              <br>
              <br>
              <h4>三、構建缺失值處理函數</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              def missing_value_processing(df,func1=1, func2=1):
              '''
              缺失值處理
              df：數據源
              func1：默認為1，眾數填充家屬；0，去除带空值数据行。
              func2：默認為1，眾數填充月收入；0，平均數填充月收入。
              '''
              // 家屬數量 - 剔除或眾數填充
              if func1 == 1:
                  df['家屬數量'].fillna(df['家屬數量'].mode()[0], inplace=True)
              elif func1 == 0:
                  df.dropna(subset=['家屬數量'], inplace=True)
                  
              if func2 == 1:
                  df['月收入'].fillna(df['月收入'].mode()[0], inplace=True)
              elif func2 == 0:    
                  df['月收入'].fillna(df['月收入'].mean(), inplace=True)

              return df
                  </code>
              </pre>
              <br>
              <br>
              <h4>四、構建重采樣函數</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 從數據初探可以發現，“未來兩年可能違約”標籤類別分佈不均，需要對樣本進行重取樣

              def resample(df):
                  '''
                  使樣本'未來兩年可能違約'標籤的0，1項可以各佔一半，以提高預測效果。sample()可以考慮添加random_state以便生成相同樣本集
                  df：數據源
                  frac=1 代表隨機抽取100%的數據進行重新排列
                  '''
                  num = df['未來兩年可能違約'].value_counts()[1]
                  df_t = df[df['未來兩年可能違約']==1]
                  df_f = df[df['未來兩年可能違約']==0].sample(frac=1)[0:num]
                  df_balanced = pd.concat([df_t,df_f]).sample(frac=1).reset_index(drop=True)
                  return df_balanced
                  </code>
              </pre>
              <br>
              <br>
              <h4>五、數據預處理</h4>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 設置【df1】為違約概率模型所用的數據集
              df1 = df.copy()

              //去除共線性
              collineation_processing(df1,'逾期90天+的筆數','逾期60-89天的筆數','逾期30-59天的筆數','逾期60-89天/30-59天')
              //缺失值處理
              missing_value_processing(df1,func1=1,func2=1)
              //數據重采樣
              df_balanced = resample(df1)
              //刪除共線性較高的字段
              df_balanced.drop(columns=['逾期30-59天的筆數','逾期60-89天的筆數','逾期60-89天/30-59天'], inplace=True)

              // 隨機抽取5%作為data_unseen，剩餘95%留在原始資料集中
              data_unseen, data_remain = train_test_split(df_balanced, test_size=0.95, random_state=42)
                  </code>
              </pre>

          </div>
          <div class="bg6">
            <h3>使用Pycaret模塊進行模型訓練</h3>
            <h6>TRAINING MODEL</h6>
            <h4>一、設置Pycaret環境</h4>
            <pre v-highlight>
                  <code class="lang-javascript">
            from pycaret.classification import *

            // 設置環境
            model_setup = setup(data= data_remain, target='未來兩年可能違約')
                  </code>
              </pre>
              <br>
            <div class="bg6-image"></div>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">預測目標：</span>未來兩年可能違約的結果。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">目標類型：</span>Binary 二分類。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">原始數據：</span>數據集中有 18446行和 9列。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">拆分成訓練集：</span>12912 （佔比70%）。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">拆分成測試集：</span>5534 （佔比30%）。</p>
            <p>•&nbsp;&nbsp;&nbsp;&nbsp;<span class="Pbold">N-Fold交叉驗證：</span>10折交叉驗證。</p>
            <br>
            <br>
            <br>
            <br>
            <h4>二、模型比較</h4>
            <p>接下來將會使用模型比較功能功能比較不同機器學習模型的表現。 此功能自動訓練和評估了多個模型，並展示了每個模型在各種測量上的表現。 以下是對比結果的解讀：</p>
            <br>
            <br>
            <pre v-highlight>
                  <code class="lang-javascript">
            // 比较模型
            best_model = compare_models()
                  </code>
              </pre>
              <br>
              <div class="bg6-image2"></div>
              <p>从表格中可以看出，各个模型在不同指标上的表现，其中高亮显示了每个指标最佳的值。例如，在准确度（Accuracy）这一指标上，Gradient Boosting Classifier (gbc) 表现最佳，为 0.7571。<br><br>
              整體來看，除了在精确度（Precision）上，Quadratic Discriminant Analysis (qda) 表现較佳，其他指標的最佳模型都是 gbc。接下來，我將會創建gbc模型。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>三、創建模型</h4>
              <p>創建gbc模型，下圖是經過10次交叉驗證後的平均準確率，也是剛才模型對比中所顯示的0.7571的平均計算結果。此外，還會查看當前默認的超參數，用作之後調整模型時作對比：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 創建模型
              gbc = create_model('gbc')
                  </code>
              </pre>
              <br>
              <div class="bg6-image3"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 打印查看gbc模型的超參數
              print(gbc)
                  </code>
              </pre>
              <br>
              <p>max_depth=3, <br>
              max_features=None, <br>
              min_samples_leaf=1, <br>
              min_samples_split=2, <br>
              min_weight_fraction_leaf=0.0,<br> 
              n_estimators=100</p>
              <br>
              <br>
              <br>
              <br>
              <h4>四、調整模型</h4>
              <p>使用預定義搜尋空間中的隨機網格搜尋自動調整模型的超參數：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 調整模型
              tuned_gbc = tune_model(gbc)
                  </code>
              </pre>
              <br>
              <div class="bg6-image4"></div>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 打印查看調整後的gbc模型超參數
              print(tuned_gbc)
                  </code>
              </pre>
              <br>
              <p>
              max_depth=7, <br>
              max_features='log2',<br> 
              min_samples_leaf=3, <br>
              min_samples_split=4, <br>
              min_weight_fraction_leaf=0.0,<br> 
              n_estimators=170</p>
              <br>
              <p>如果我們將這個改進的gbc模型與先前的gbc模型的準確度指標進行比較，我們會發現一個差異，因為它的精確度從0.7571提高到了0.7578。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>五、繪製模型</h4>
              <p>接下來會繪製圖表，分析模型的性能：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製AUC圖表
              plot_model(tuned_gbc, plot = 'auc')
                  </code>
              </pre>
              <br>
              <div class="bg6-image5"></div>
              <p>上圖展示的是用於評估分類模型性能的 ROC 曲線， ROC曲線是一種圖形化展示分類器性能的工具，它通過將真陽性率（True Positive Rate, TPR）和假陽性率（False Positive Rate, FPR）在不同的閾值設定下繪製出來。<br><br>
              在圖中，您可以看到：</p>
              <br>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;ROC of class 0, AUC = 0.84：代表第一個類別（標籤為0的類別）的 ROC 曲線及其對應的 AUC 值。AUC 值為 0.84，表示模型對於這個類別的識別能力是較好的。AUC 值範圍在 0 到 1 之間，越接近 1 表示性能越好。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;ROC of class 1, AUC = 0.84：代表第二個類別（標籤為1的類別）的 ROC 曲線及其 AUC 值，同樣是 0.84，表明模型對這個類別的識別能力同樣較好。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;micro-average ROC curve, AUC = 0.84：微平均 ROC 曲線，是基於所有類別匯總的結果計算得出的。</p>
              <p>•&nbsp;&nbsp;&nbsp;&nbsp;宏平均 ROC 曲線，是所有類別的 ROC 曲線的簡單平均。</p>
              <br>
              <p>ROC 曲線上越接近左上角表示模型的性能越好，因為這意味著在保持低假陽性率的同時獲得了高真陽性率。在這張圖中，所有的 ROC 曲線以及宏平均和微平均的 AUC 值都是 0.84，這說明模型的整體性能是相當不錯的。這些曲線彼此非常接近，意味著模型在兩個類別上的表現相對均衡。<br><br>
              此外，ROC 曲線下的點狀線代表了隨機猜測的結果，理想的分類器的 ROC 曲線會遠離這條線並盡量靠近圖形的左上角。在此圖中，模型的 ROC 曲線顯著高於隨機猜測的線，這表明該模型具有很好的分類性能。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 繪製 PR 曲线
              plot_model(tuned_gbc, plot = 'pr')
                  </code>
              </pre>
              <br>
              <div class="bg6-image6"></div>
              <p>這張圖告訴我們，當模型更加保守時（只有在非常確信的時候才將案例分類為正），它能獲得很高的精確率，但隨著模型變得更激進（願意冒險將更多案例分類為正以捕獲更多的正樣本），它的精確率會下降。这个模型的整体表现是相当不错的，因为平均精确率是 0.84，这意味着它在精确率和召回率之间取得了较好的平衡。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>六、模型預測</h4>
              <p>接下來將會使用訓練集的30%數據進行預測，看它們是否與CV結果有實際性差異：</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 模型预测
              predict_model(tuned_gbc)
                  </code>
              </pre>
              <br>
              <div class="bg6-image7"></div>
              <p>測試集的準確度為0.7582，而tuned_gbc的結果為0.7578。 這並不是一個顯著的差異。 如果測試集和訓練集的結果之間存在較大差異，這通常表示過擬合，但也可能是由於其他幾個因素造成的，需要進一步調查。</p>
              <br>
              <br>
              <br>
              <br>
              <h4>七、決定模型</h4>
              <p>經過以上評估、分析及調整後，準備將模型投入使用前的最後一步。 為了進一步評估模型的準確性，將使用未公開過給Pycaret的5%（970個數據）數據進行預測。</p>
              <br>
              <br>
              <pre v-highlight>
                  <code class="lang-javascript">
              // 最終確定模型
              final_gbc = finalize_model(tuned_gbc)

              // 使用 final_gbc 對未見過的數據 data_unseen 進行預測
              unseen_predictions = predict_model(final_gbc, data=data_unseen)

              // 輸出預測結果
              unseen_predictions
                  </code>
              </pre>
              <br>
              <div class="bg6-image8"></div>
              <p>總結：預測結果為 0.7757，和之前的預測十分接近。最後可以保存該模型的參數，以便日後進行使用。</p>
          </div>
      </div>
      <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'

export default {
  components: {
    back,
  },
    data() {
      return {
        tableData: [{
          name: 'SeriousDlqin2yrs',
          Describe: '未來兩年可能違約 Y/N'
        }, {
          name: 'RevolvingUtilizationOfUnsecuredLines',
          Describe: '可用信用額度比例'
        }, {
          name: 'age',
          Describe: '年齡（年）'
        }, {
          name: 'NumberOfTime30-59DaysPastDueNotWorse',
          Describe: '逾期30-59天的筆數'
        }, {
          name: 'DebtRatio',
          Describe: '負債比率'
        }, {
          name: 'MonthlyIncome',
          Describe: '月收入'
        }, {
          name: 'NumberOfOpenCreditLinesAndLoans',
          Describe: '信貸數量：未償還貸款數量'
        }, {
          name: 'NumberOfTimes90DaysLate',
          Describe: '逾期90天+的筆數'
        }, {
          name: 'NumberRealEstateLoansOrLines',
          Describe: '固定資產貸款數'
        }, {
          name: 'NumberOfTime60-89DaysPastDueNotWorse',
          Describe: '逾期60-89天的筆數'
        }, {
          name: 'NumberOfDependents',
          Describe: '家屬數量'
        }],
      };
    },
}
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 28px;
        margin-top: 40px;
        font-weight: 500;
        }

      h4{
        font-family: 'HuaKangFangYuan';
        font-size: 26px;
        font-weight: 500;
        color: #444;
        margin-bottom: 70px;
      }

      h5{
        font-family: 'HuaKangFangYuan';
        font-size: 24px;
        font-weight: 500;
        color: #444;
        margin-bottom: 50px;
        }
      h6{
        font-family: 'HuaKangFangYuan';
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;
        color: #666;
        font-weight: 500;
      }

      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p, pre {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin:10px 0;
        line-height: 32px;
        white-space: pre-wrap; /* 保持换行和空格，同时允许自动换行 */
        color: #444444;
      }
      .p8d{
        color: #8d8d8d;
      }
      .p16 {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        color: #333;
        padding: 20px;
        background-color: #F8F8F8;
        margin: 20px 0 ;
      }
      .Pbold{
            font-weight: 900;
      }
      .bg-p{
              margin-top: 30px;
              width: 100%;
      }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .main-bg{
      width: 1200px;
      margin: 0 auto;
    }
.bg1{
  .bg1-image{
      width: 100%;
      height: 400px;
      margin: 120px 0;
      background-image: url('./assets/bg1-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
}
.bg2{
    margin-top: 120px;
    .tableStyle{
      width: 80%;
      margin-top:30px;
      font-size: 16px;
    }
}
.bg3{
    margin-top: 120px;
    .bg3-image{
      width: 100%;
      height: 450px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg3-image2{
      width: 100%;
      height: 311px;
      margin-bottom: 60px;
      background-image: url('./assets/bg3-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg4{
    margin-top: 120px;
    .bg4-image{
      width: 100%;
      height: 1186px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image2{
      width: 100%;
      height: 1203px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .bg4-image3{
      width: 100%;
      height: 752px;
      margin-bottom: 60px;
      background-image: url('./assets/bg4-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
}
.bg5{
  margin-top: 120px;
}
.bg6{
  margin-top: 120px;
  .bg6-image{
      width: 100%;
      height: 600px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image2{
      width: 100%;
      height: 587px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image2.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image3{
      width: 100%;
      height: 477px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image3.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image4{
      width: 100%;
      height: 477px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image4.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image5{
      width: 100%;
      height: 490px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image5.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image6{
      width: 100%;
      height: 490px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image6.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image7{
      width: 100%;
      height: 487px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image7.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .bg6-image8{
      width: 100%;
      height: 485px;
      margin-bottom: 60px;
      background-image: url('./assets/bg6-image8.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
}

  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>