<template>
    <div class="bodyContainer">
        <back :showButton="true" />
        <div class="bg-banner"></div>
          <div class="main-bg">
            <!-- 項目概述 -->
        <div class="bg2">
            <div class="bg2-title"></div>
            <div class="bg2-image"></div>
            <h2>整體概述</h2>
            <p class="bg2-p">交易神器 —— 集交易智能分析與用戶體驗於一身的創新平台。我們打造的不僅是一個普通的交易工具，而是一個交易決策的助手，旨在通過實時市場數據、名師建議以及其他大神做單建議，為用戶提供量身定制的交易策略和決策支持。利用先進的數據分析工具，實時追蹤市場動態，幫助用戶洞悉市場先機，掌握投資主動權。</p>
            <h2 class="bg2-h2">主要定位</h2>
            <p class="bg2-p2">我們致力於打造一個直觀、快捷且高效的用戶操作體驗。從簡潔的界面設計到流暢的交易執行，每一個環節都經過精心設計，以減少用戶的操作負擔，提升整體交易效率。讓用戶在這個多變的市場中，以最少的操作，達到最大的交易效益。</p>
        </div>
        <!-- 產品定義 -->
        <div class="bg3">
            <div class="bg3-title"></div>
            <div class="bg3-image"></div>
            <div class="bg3-box1 boxStyle">
                <p class="bg3-subtitle1 subtitleStyle">用戶體驗優先</p>
                <p class="bg3-p1">將用戶放在首位，每一項功能的設計都是為了優化用戶體验。從簡潔的用戶界面到迅速的執行響應，我們的目標是让投資操作既直觀又高效，確保每一位用戶都能在這個平台上輕鬆進行交易。</p>
            </div>
            <div class="bg3-box2 boxStyle">
                <p class="bg3-subtitle2 subtitleStyle">實時市場信息</p>
                <p class="bg3-p2">提供了最新的多空情緒交易動態，幫助用戶及時調整投資策略，亦有利投資小白用戶用更小的時間成本進行交易決策。</p>
            </div>
            <div class="bg3-box3 boxStyle">
                <p class="bg3-subtitle3 subtitleStyle">智能投資策略</p>
                <p class="bg32-p3">獨特的「大神喊单」板块讓用戶能夠直觀地查看各路交易高手的策略，并根據自己的投資風格選擇跟隨。透過實時數據和專業分析，每一次跟單操作都經過仔细考量，提供给用戶最佳的投資決策支援。</p>
            </div>
            <div class="bg3-box4 boxStyle">
                <p class="bg3-subtitle4 subtitleStyle">持續技術進步</p>
                <p class="bg32-p4">致力于通過不斷的技術進步，為用戶提供最穩定和最先進的交易體驗。交易神器積極整合最新的金融科技，包括大數據分析和高频交易算法，以确保用戶在市場上始终保持競爭優勢。</p>
            </div>
            <p class="botton-p">我们的目标是打造一個功能豐富，操作簡單，且始终走在行業前沿的交易平台。</p>
        </div>
        <!-- 市場分析 -->
        <div class="bg4">
            <div class="bg4-title"></div>
            <div class="bg4-image"></div>
            <h2 class="subtitle1">市場背景</h2>
            <p class="bg4-p1 subtitleStyle">投資市場快速變動，用戶需求智能化交易工具以應對市場波動。</p>
            <p class="bg4-p2 subtitleStyle">多元化投資選項增加，用戶尋求即時、準確的市場分析支援決策。</p>
            <p class="bg4-p3 subtitleStyle">技術進步推動交易平台創新，用戶期待更高效率及操作便利性。</p>
            <h2 class="subtitle2">用戶分析</h2>
            <div class="cardContainer">
                <div class="bg4-card1"></div>
                <div class="bg4-card2"></div>
                <div class="bg4-card3"></div>
            </div>
        </div>
        <!-- 競品分析 -->
        <div class="bg5">
            <div class="bg5-title"></div>
            <div class="bg5-image"></div>
        </div>
        <!-- 功能結構 -->
        <div class="bg6">
            <div class="bg6-title"></div>
            <div class="bg6-image"></div>
        </div>
        <!-- 設計思考 -->
        <div class="bg7">
            <div class="bg7-title"></div>
            <p>透過對市場的深度理解和用戶需求的精準把握，我們的交易神器打造了一套集即時分析、安全性、用戶友善性和創新技術於一身的交易平台。 我們的設計理念是為了提供直覺、易用且高度個人化的使用者體驗，讓交易者在複雜多變的市場環境中保持領先。</p>
            <div class="bg7-boxContainer">
                <div class="cardContainer">
                    <div v-for="card in cards" :key="card.id">
                    <div class="card">
                        <div class="cardImage" :style="{ backgroundImage: 'url(' + card.photo +')'}"></div>
                        <p class="cardTitle">{{ card.title }}</p>
                        <p class="cardsubtitle1"><span class="bold">{{ card.subtitle1Title }}</span>{{ card.subtitle1 }}</p>
                        <p class="cardsubtitle2"><span class="bold">{{ card.subtitle2Title }}</span>{{ card.subtitle2 }}</p>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        <!-- 推薦算法 -->
        <div class="bg8">
            <div class="bg8-title"></div>
            <div class="bg8-boxContainer">
                <div class="cardContainer">
                    <div v-for="algorithm in algorithms" :key="algorithm.id">
                    <div class="card">
                        <p class="cardTitle">{{ algorithm.title }}</p>
                        <div class="cardImage" :style="{ backgroundImage: 'url(' + algorithm.photo +')'}"></div>
                        <p class="cardsubtitle1"><span class="bold">{{ algorithm.subtitle1Title }}</span>{{ algorithm.subtitle1 }}</p>
                        <p class="cardsubtitle2"><span class="bold">{{ algorithm.subtitle2Title }}</span>{{ algorithm.subtitle2 }}</p>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        <!-- 設計稿 -->
        <div class="bg9">
            <div class="bg9-title"></div>
            <div class="bg9-image"></div>
        </div>
        <p class="endPage"> 已經到底啦~ </p>
    </div>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  export default {
      components: {
          back,
      },
      data() {
      return {
          cards:[
            {
              id: 1,
              photo:require('@/assets/tradingSystem/bg7-card1.png'),
              title:'市場多空情緒',
              subtitle1Title:'功能簡介：',
              subtitle1:'首頁即時更新市場數據，提供多空比例、價格趨勢圖表等關鍵資訊',
              subtitle2Title:'直覺展示：',
              subtitle2:'即時顯示市場投資人對黃金或白銀的交易態勢，幫助投資人快速掌握市場脈動'
            },
            {
              id: 2,
              photo:require('@/assets/tradingSystem/bg7-card2.png'),
              title:'名師投資建議',
              subtitle1Title:'功能簡介：',
              subtitle1:'提供不同風險等級的投資策略，滿足各類投資人的不同需求',
              subtitle2Title:'專業洞察：',
              subtitle2:'經驗豐富的分析師團隊提供的投資建議，幫助使用者做出更明智的交易選擇'
            },
            {
              id: 3,
              photo:require('@/assets/tradingSystem/bg7-card3.png'),
              title:'交易大神榜TOP10',
              subtitle1Title:'功能簡介：',
              subtitle1:'近30天獲利排行榜，使用者可以直接觀看哪些大神值得跟隨',
              subtitle2Title:'競技榜單：',
              subtitle2:'展現獲利能力最強的交易大神，引領投資風向'
            },
            {
              id: 4,
              photo:require('@/assets/tradingSystem/bg7-card4.png'),
              title:'精選喊單推薦',
              subtitle1Title:'功能簡介：',
              subtitle1:'使用者可依自己的風險偏好，選擇一鍵跟單的服務，快速回應市場變化',
              subtitle2Title:'智能推薦：',
              subtitle2:'系統根據大神喊單的表現和使用者回饋，推薦最有價值的投資機會'
            },
          ],
          algorithms:[
            {
              id: 1,
              photo:require('@/assets/tradingSystem/bg8-card1.png'),
              title:'初始熱度',
              subtitle1Title:'雷達圖評分：',
              subtitle1:'每位交易大神擁有專屬的雷達圖評分，我們的算法以此為基礎，賦予每個喊單一個初始熱度分，確保只有最高水平的交易策略展現於投資者眼前',
              subtitle2Title:'實時動態熱度：',
              subtitle2:'推薦系統實時監測並更新熱度分數，確保您總能捕捉到最新、最熱門的交易喊單'
            },
            {
              id: 2,
              photo:require('@/assets/tradingSystem/bg8-card2.png'),
              title:'增加熱度',
              subtitle1Title:'優勝大神前列展示：',
              subtitle1:'演算法精準地將表現卓越的大神推薦至前端，高曝光率確保優質交易策略不被錯過',
              subtitle2Title:'跟單動力激勵：',
              subtitle2:'每位跟單者的加入都會為喊單增添熱度，藉此提升大神的可見度，形成正向的市場反饋循環'
            },
            {
              id: 3,
              photo:require('@/assets/tradingSystem/bg8-card3.png'),
              title:'熱度遞減',
              subtitle1Title:'時間衰減機制：',
              subtitle1:'我們的演算法設計考慮到時間因素，以公正的方式逐漸減少喊單的熱度，確保市場的活力與公平性',
              subtitle2Title:'優化的決策支持：',
              subtitle2:'通過細緻的演算法調校，交易神器確保每一位投資者都能在最佳時機把握跟單機會，優化您的投資回報率'
            },
          ]
      }}
  }
  </script>
  
  <style lang="less" scoped>
 * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h2{
        font-weight: 900;
      }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        }
      h4{
        font-family: 'SourceSansPro-Regular';
        font-size: 22px;
        font-weight: 500;
        }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        line-height: 32px;
        color: #444444;
        }
        .Pbold{
            font-weight: 900;
        }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .bg-banner{
      position: relative;
      height: 562px;
      background-image: url('./assets/bg-banner.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .main-bg{
      position: relative;
      width: 1200px;
      margin: 0 auto;
      .bg-magin-top{
        margin-top:120px
      }
      .p-magin-top{
        margin-top:30px
      }
  }
      .bg2{
          .bg2-title{
              height: 100px;
              width: 180px;
              margin: 80px 0;
              background-image: url('./assets/bg2-title.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg2-image{
              position: absolute;
              top: 250px;
              right: 0;
              height: 380px;
              width: 400px;
              background-image: url('./assets/bg2-image.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          h2{
            margin-top: 80px;
            margin-bottom: 30px;
          }
          .bg2-p{
              width: 50%;
          }
          .bg2-h2{
            margin-top: 80px;
            margin-bottom: 30px;
          }
          .bg2-p2{
              width: 50%;
          }
      }
      .bg3{
        position: relative;
        margin-top: 120px;
          .bg3-title{
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg3-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bg3-image{
              position: relative;
              top: 300px;
              left: 450px;
              width: 200px;
              height: 200px;
              background-image: url('./assets/bg3-image.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .boxStyle{
              width: 400px;
              height: 100px;
          }
          .subtitleStyle{
              margin: 15px 0;
              color: #7AA6F7;
              font-size: 24px;
              font-weight: 800;
              &::before{
                  content: "";
                  position: relative;
                  top: 4px;
                  display: inline-block;
                  width: 30px;
                  margin-right: 10px;
                  height: 30px;
                  background-image: url('./assets/bg3-subtitleIcon.png');
                  background-repeat: no-repeat;
                  background-size: contain;
              }
          }
          .bg3-box1{
              position: absolute;
              top: 220px;
              left: 0vh;
          }
          .bg3-box2{
              position: absolute;
              top: 220px;
              right: 0px;
          }
          .bg3-box3{
              position: absolute;
              top: 620px;
              left: 0;
          }
          .bg3-box4{
              position: absolute;
              top: 620px;
              right: 0px;
          }
          .botton-p{
              position: absolute;
              top: 950px;
              left: 280px;
              color: #666;
              display: inline-block;
              &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  left: -30px;
                  bottom: 0; /* 调整下划线位置，可根据实际情况调整 */
                  width: 105%; /* 下划线宽度 */
                  height: 10px; /* 下划线厚度 */
                  border-radius: 10px;
                  background-color: yellow;
                  z-index: -1;
              }
          }
      }
      .bg4{
          margin-top: 800px;
          .bg4-title{
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg4-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bg4-image{
              position: absolute;
              top: 1950px;
              right: 0;
              height: 400px;
              width: 400px;
              background-image: url('./assets/bg4-image.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .subtitle1{
              position: relative;
              top: 100px;
          }
          .subtitleStyle{
              position: relative;
              top:150px;
              left: 20px;
              margin: 30px 0;
              &::before{
                  content: "";
                  position: relative;
                  top: 12px;
                  display: inline-block;
                  width: 30px;
                  margin-right: 10px;
                  height: 30px;
                  background-image: url('./assets/bg4-subtitleIcon.png');
                  background-repeat: no-repeat;
                  background-size: contain;
              }
          }
          .subtitle2{
              margin-top: 300px;
          }
          .cardContainer{
            margin-top: 80px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8vh;
          }
          .bg4-card1{
              width: 500px;
              height: 600px;
              background-image: url('./assets/bg4-card1.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg4-card2{
              width: 460px;
              height: 550px;
              background-image: url('./assets/bg4-card2.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
          .bg4-card3{
              width: 500px;
              height: 600px;
              background-image: url('./assets/bg4-card3.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
      }
      .bg5{
          .bg5-title{
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg5-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bg5-image{
            margin-top: 130px;
            height: 600px;
            width: 100%;
            background-image: url('./assets/bg5-image.png');
            background-repeat: no-repeat;
            background-size: contain;
          }  
      }
      .bg6{
        margin-top: 120px;
          .bg6-title{
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg6-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bg6-image{
            margin-top: 120px;
            height: 760px;
            width: 100%;
            background-image: url('./assets/bg6-image.png');
            background-repeat: no-repeat;
            background-size: contain;
          }  
      }
      .bg7{
          margin-top: 120px;
          .bg7-title{
            margin-bottom: 30px;
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg2-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .cardContainer{
              width: 100%;
              display: flex;
              justify-content: space-around;
              gap: 10px;
          }
          .card{
            margin-top: 80px;
              width: 300px;
              height: 500px;
  
              .cardImage{
                  width: 100%;
                  height: 200px;
                  background-repeat: no-repeat;
                  background-size: contain;
              }
              .cardTitle{
                font-size: 22px;
                font-weight: 800;
                text-align: center;
              }
              .bold{
                color: #555;
                font-weight: 700;
              }
              .cardsubtitle1{
                padding: 30px 20px;
                font-size: 18px;
                color: #666;
              }
              .cardsubtitle2{
                padding: 30px 20px;
                font-size: 18px;
                color: #666;
              }
          }
      }
      .bg8{
          margin-top: 120px;
          .bg8-title{
            height: 100px;
            width: 310px;
            background-image: url('./assets/bg8-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .cardContainer{
              width: 100%;
              margin-top: 80px;
              display: flex;
              justify-content: space-around;
              gap: 10px;
          }
          .card{
              width: 380px;
              height: 540px;
              border-radius: 20px;
              background: linear-gradient(180deg, #E1FAEF 0%, rgba(225, 250, 239, 0.50) 100%);
  
              .cardImage{
                  position: relative;
                  top: 50px;
                  left: 110px;
                  width: 100%;
                  height: 180px;
                  background-repeat: no-repeat;
                  background-size: contain;
              }
              .cardTitle{
                  position: relative;
                  top: 30px;
                  left: 0;
                  font-size: 24px;
                  font-weight: 800;
                  text-align: center;
              }
              .bold{
                  color: #555;
                  font-weight: 700;
              }
              .cardsubtitle1{
                  position: relative;
                  top: 50px;
                  left: 0;
                  padding: 0 40px;
                  font-size: 18px;
                  color: #666;
              }
              .cardsubtitle2{
                  position: relative;
                  top: 90px;
                  left: 0;
                  padding: 0 40px;
                  font-size: 18px;
                  color: #666;
              }
          }
      }
      .bg9{
          margin-top: 120px;
          .bg9-title{
            height: 100px;
            width: 280px;
            background-image: url('./assets/bg9-title.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bg9-image{
              margin-top: 180px;
              height: 950px;
              width: 100%;
              background-image: url('./assets/bg9-image.png');
              background-repeat: no-repeat;
              background-size: contain;
          }
      }
        .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>