<template>
  <div class="bodyContainer">
      <back :showButton="true" />
      <div class="bg-banner"></div>
        <div class="main-bg">
            <div class="bg1">
              <div class="bg1-title"></div>
              <div class="bg1-image"></div>
              <p class="bg1-p">為提升代理的業績與業務發展目標的清晰度，我們計劃開發一個數據可視化項目。該項目旨在通過將代理的業務數據進行可視化處理，使其能夠直觀地理解和分析業績趨勢，從而做出更加明智的業務決策，提升整體業績。這一策略有望為代理提供一個強大的工具，幫助他們有效地監控和優化他們的銷售策略。</p>
            </div>    
        </div>
        <div class="bg2">
          <div class="main-bg">
            <div class="bg2-title"></div>
            <div class="bg2-image"></div>
          </div>
        </div>
        <div class="bg3">
          <div class="main-bg">
            <div class="bg3-title"></div>
            <div class="bg3-image"></div>
          </div>
        </div>
        <div class="main-bg">
          <div class="bg4">
            <div class="bg4-title"></div>
            <div class="bg4-image"></div>
          </div>
          <div class="bg5">
            <div class="bg5-title"></div>
            <div class="bg5-image1"></div>
            <div class="bg5-image2"></div>
            <div class="bg5-image3"></div>
            <div class="bg5-image4"></div>
            <div class="bg5-image5"></div>
          </div>
        </div>
  <p class="endPage"> 已經到底啦~ </p>
  </div>
</template>
<script>
import back from '@/components/back/index.vue'
export default {
  name: 'MyCarousel',
  components: {
      back,
  },
};
</script>
<style lang="less" scoped>
  * {
      font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
      h1{
        font-family: 'AaErZhiYuan';
        font-weight: 500;
        position: relative;
        top: 100px;
        }
      h2{
        font-weight: 900;
      }
      h3{
        font-family: 'HuaKangFangYuan';
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        }
      h4{
        font-family: 'SourceSansPro-Regular';
        font-size: 22px;
        font-weight: 500;
        }
      .p-h3{
        font-family: 'HuaKangFangYuan';
        font-size: 16px;
        color: #666;
        font-weight: 500;
      }
      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        line-height: 32px;
        color: #444444;
        }
        .Pbold{
            font-weight: 900;
        }
  }
  .main-title{
    font-family: 'HuaKangFangYuan';
    font-size: 40px;
    text-align: center;
    margin-bottom: 300px;
  }

  .bodyContainer{
    height: auto;
    width: 100vw;
  }
  .bg-banner{
      position: relative;
      height: 650px;
      background-image: url('./assets/bg-banner.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .main-bg{
      width: 1200px;
      margin: 0 auto;
      .bg-magin-top{
        margin-top:120px
      }
      .p-magin-top{
        margin-top:30px
      }
  }
  .bg1{
    position: relative;
    margin-top: 120px;
        .bg1-title{
            height: 100px;
            width: 180px;
            background-image: url('./assets/bg1-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg1-image{
            position: absolute;
            top: 60px;
            right: 100px;
            height: 300px;
            width: 500px;
            background-image: url('./assets/bg1-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg1-p{
          margin-top: 40px;
          width: 40%;
        }
    }
  .bg2{
      margin-top: 120px;
      background: linear-gradient(to bottom, #fef0ea 0%, #ffffff 100%);
        .bg2-title{
            height: 100px;
            width: 180px;
            margin-top: 200px;
            background-image: url('./assets/bg2-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg2-image{
            height: 400px;
            width: 100%;
            background-image: url('./assets/bg2-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
  .bg3{
      margin-top: 120px;
      height: 700px;
      width: 100vw;
      background-image: url('./assets/bg3.png');
      background-repeat: no-repeat;
      background-size: cover;
        .bg3-title{
          height: 100px;
          width: 180px;
          margin-top: 200px;
          background-image: url('./assets/bg3-title.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .bg3-image{
            height: 450px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg3-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
  .bg4{
        .bg4-title{
          height: 100px;
          width: 180px;
          margin-top: 130px;
          background-image: url('./assets/bg4-title.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .bg4-image{
            height: 1690px;
            width: 100%;
            margin-top: 130px;
            background-image: url('./assets/bg4-image.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
  .bg5{
        .bg5-title{
            height: 100px;
            width: 240px;
            background-image: url('./assets/bg5-title.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-image1{
            height: 700px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg5-image1.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-image2{
            height: 700px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg5-image2.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-image3{
            height: 500px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg5-image3.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-image4{
            height: 600px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg5-image4.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .bg5-image5{
            height: 900px;
            width: 100%;
            margin-top: 120px;
            background-image: url('./assets/bg5-image5.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

    }
  .endPage{
      text-align: center;
      position: relative;
      margin: 100px 0 ;
      padding: 5vh 0;
      font-family: 'HuaKangFangYuan';
      color: #999;
  }
</style>