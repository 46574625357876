<template>
      <router-link to="/" class="logo-container">
        <img src="@/assets/logo.svg" alt="logo" class="nav-logo" >
      </router-link>
</template>

<style lang="less" scoped>
@primary-color: #ff5252;
.logo-container {
  position: fixed;
  padding: 0.7rem 2rem;
    .nav-logo {
      height: auto; /* 保持图片的原始宽高比 */
      max-height: 8vh; /* 限制最大高度，根据需要调整 */
    }
  }
</style>