<template>
    <div class="bodyContainer">
        <back :showButton="true" />
          <div class="main-bg">
              <h1 class="main-title">金融知識</h1>
              <h3>資格概覽</h3>
              <p class="p-h3">Qualification</p>
              <p class="p-bg">CFA（Chartered Financial Analyst）認證是金融專業人士中最受尊敬和認可的資格之一。 它不僅要求具備深厚的金融知識和嚴格的倫理標準，而且通過率統計顯示，這項考試具有相當的挑戰性。</p>
              <div class="badgeContainer">
                  <div class="bg1-CFAbadge"></div>
                  <p>CFA Level 1：已通過 </p>
              </div>
              <div class="bg1-Email"></div>
          <div class="p-magin-top">
              <h3>專業知識領域</h3>
              <p class="p-h3">Knowledge</p>
              <div class="knowledgeContainer">
                <div v-for="knowledge in knowledges" :key="knowledge.id">
                    <div class="knowledge" :style="{ backgroundImage: 'url(' + knowledge.photo +')'}">
                        <div class="knowledgeIcon" :style="{ backgroundImage: 'url(' + knowledge.icon +')'}"></div>
                        <p class="knowledgeTitle">{{ knowledge.title }}</p>
                        <p class="knowledgeSubTitle">{{ knowledge.subtitle }}</p>
                        <p class="knowledgeContent">{{ knowledge.content }}</p>
                    </div>
                </div>
              </div>
          </div>
          <div class="bg-magin-top">
              <h3>日常工作中的應用</h3>
              <p class="p-h3">Application</p>
              <p class="p-magin-top">在日常工作中，可以有效做到：</p>
              <div class="cardContainer">
                <div v-for="card in cards" :key="card.id">
                  <div class="card">
                    <div class="cardIcon" :style=" {backgroundImage: 'url(' + card.icon +')'}"></div>
                    <h4 class="cardName">{{ card.name }}</h4>
                    <p class="cardContent">{{ card.content }}</p>
                  </div>
                </div>
              </div>
          </div>
          <div class="bg-magin-top1">
              <h3>項目案例展示</h3>
              <p class="p-h3">Project Display</p>
              <div class="projectContainer">
                <p class="title-bar">案例一：A / B 測試 - 網站轉化率</p>
                <div class="mainContainer">
                  <div class="pj-photo1"></div>
                  <div class="introContainer">
                    <p class="intro"><span class="Pbold">簡介：</span>公司非常注重自己的落地頁設計，希望通過改進設計來提高轉化率。以往該公司全年轉化率平均在13左右%，現在希望設計的新頁面能夠帶來更高的轉化率，希望新頁面的轉化率能有2%的提升，達到15%。在正式推出新頁面之前，AB 測試在小范圍的用戶中進行測試，並使用統計學的假設檢驗進行結果的有效性評估，以確保新頁面的效果能夠達到預期目標。</p>
                    <router-link to="/ProjectMenu/ABTest" class="pj-btn" >查看項目詳情</router-link>
                  </div>
                </div>
              </div>
          </div>
        </div>
             <p class="endPage"> 已經到底啦~ </p>
    </div>
  </template>
  <script>
  import back from '@/components/back/index.vue'
  
  export default {
    components: {
      back,
    },
      data() {
        return {
            cards:[
              {
                id: 1,
                icon:require('@/assets/CFABook/icon1.png'),
                name:'數據分析',
                content:'使用統計學和機率論等知識來解讀數據，預測趨勢，並為決策提供量化依據。',
              },
              {
                id: 2,
                icon:require('@/assets/CFABook/icon2.png'),
                name:'市場分析',
                content:'理解和分析市場動態和經濟指標，預測市場變化對產品和策略的影響。',
              },
              {
                id: 3,
                icon:require('@/assets/CFABook/icon3.png'),
                name:'財務報告解讀',
                content:'解讀和分析財務報表，評估企業的財務狀況、營運效率和獲利能力。',
              },
              {
                id: 4,
                icon:require('@/assets/CFABook/icon4.png'),
                name:'行為心理學',
                content:'利用行為心理學理解投資者和消費者的行為模式，創造更符合使用者實際需求和行為傾向的產品。',
              },
            ],
            knowledges:[
                {
                id: 1,
                photo:require('@/assets/CFABook/bg2-1.png'),
                icon:require('@/assets/CFABook/bg2-1icon.png'),
                title:'量化分析',
                subtitle:'Quantitative Methods',
                content:'首頁即時更新市場數據，提供多空比例、價格趨勢圖表等關鍵資訊',
            },
            {
                id: 2,
                photo:require('@/assets/CFABook/bg2-2.png'),
                icon:require('@/assets/CFABook/bg2-2icon.png'),
                title:'經濟學',
                subtitle:'Economics',
                content:'宏觀經濟指標分析、貨幣政策和財政政策的影響、經濟週期的識別。',
            },
            {
                id: 3,
                photo:require('@/assets/CFABook/bg2-3.png'),
                icon:require('@/assets/CFABook/bg2-3icon.png'),
                title:'財務報告與分析',
                subtitle:'FSA',
                content:'理解和分析財務報告，包括資產負債表、利潤表、現金流量表等，識別公司財務健康狀況。',
            },
            {
                id: 4,
                photo:require('@/assets/CFABook/bg2-4.png'),
                icon:require('@/assets/CFABook/bg2-4icon.png'),
                title:'公司金融',
                subtitle:'Corporate Issuers',
                content:'了解企業價值評估、資本結構優化、投資決策和公司治理。',
            },
            {
                id: 5,
                photo:require('@/assets/CFABook/bg2-5.png'),
                icon:require('@/assets/CFABook/bg2-5icon.png'),
                title:'權益類投資',
                subtitle:'Equity',
                content:'掌握股票市場分析，股票估值和選股策略。',
            },
            {
                id: 6,
                photo:require('@/assets/CFABook/bg2-6.png'),
                icon:require('@/assets/CFABook/bg2-6icon.png'),
                title:'衍生品',
                subtitle:'Derivatives',
                content:'了解各類衍生品工具，包括期權、期貨、互換合同等。',
            },
            {
                id: 7,
                photo:require('@/assets/CFABook/bg2-7.png'),
                icon:require('@/assets/CFABook/bg2-7icon.png'),
                title:'另類投資',
                subtitle:'Alternative Investments',
                content:'理解另類資產類別，如房地產、大宗商品、私募股權等的特性。',
            },
            {
                id: 8,
                photo:require('@/assets/CFABook/bg2-8.png'),
                icon:require('@/assets/CFABook/bg2-8icon.png'),
                title:'投資組合管理',
                subtitle:'Portfolio Management',
                content:'掌握投資組合構建和風險管理策略，資產配置與選擇。',
            },
            {
                id: 9,
                photo:require('@/assets/CFABook/bg2-9.png'),
                icon:require('@/assets/CFABook/bg2-9icon.png'),
                title:'固定收益類投資',
                subtitle:'Fixed Income',
                content:'了解債券市場分析，了解利率變動、信用風險等因素對固定收益投資的影響。',
            },
            ],
      }
  }
}
  </script>
  <style lang="less" scoped>
    * {
        font-family: 'SourceSansPro-Bold','SourceSansPro-Regular','PingFang SC', 'HuaKangFangYuan','AaErZhiYuan', Georgia, sans-serif, Helvetica;
        h1{
          font-family: 'AaErZhiYuan';
          font-weight: 500;
          position: relative;
          top: 100px;
          }
        h3{
          font-family: 'HuaKangFangYuan';
          font-size: 32px;
          margin-bottom: 10px;
          font-weight: 500;
          }
        h4{
          font-family: 'SourceSansPro-Regular';
          font-size: 22px;
          font-weight: 500;
          }
        .p-h3{
          font-family: 'HuaKangFangYuan';
          font-size: 16px;
          color: #666;
          font-weight: 500;
        }
        p {
          font-family: 'SourceSansPro-Regular';
          font-size: 18px;
          color: #444444;
          }
        .p-bg {
          position:relative;
          background-color: #f4fcff; /* 主要背景颜色 */
          margin-top: 40px;
          padding: 30px 30px 30px 40px;
          border-radius: 5px;
          overflow: hidden;
          br{
            content: '';
            margin-bottom: 10px;
            display: block;
          }
          &::before{
              content:'';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 10px;
              background-color: #5fa7e4;
            }
          }
          .icon {
            color: #81ACCF; /* 设置图标的颜色 */
            font-size: 18px; /* 设置图标的字号，可根据需要调整 */
            margin-right: 10px;
          }
          .Pbold{
              font-weight: 900;
          }
    }
    .main-title{
      font-family: 'HuaKangFangYuan';
      font-size: 40px;
      text-align: center;
      margin-bottom: 300px;
    }

    .bodyContainer{
      height: auto;
      width: 100vw;
    }
    .badgeContainer{
            position: relative;
            display: inline-block;
            top: 150px;
            left: 80px;
            text-align: center;
            .bg1-CFAbadge{
                width: 280px;
                height: 280px;
                background-image: url('./assets/bg1-CFAbadge.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
            p{
                margin-top: 50px;
                font-size: 24px;
            }

        }
        .bg1-Email{
            position: relative;
            top: -190px;
            left: 500px;
            width: 700px;
            height: 300px;
            border-style:solid;
            border-width: 1px;
            border-color:#c0c0c0;
            background-image: url('./assets/CFAEmail.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    .main-bg{
        width: 1200px;
        margin: 0 auto;
        .bg-magin-top{
          margin-top:300px
        }
        .bg-magin-top1{
          margin-top:150px
        }
        .p-magin-top{
          margin-top:30px
        }
        .knowledgeContainer{
            position: relative;
            top: 100px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 15px;
            .knowledge{
                width: 390px;
                height: 290px;
                background-repeat: no-repeat;
                background-size: contain;
                .knowledgeIcon{
                    position: relative;
                    top: 30px;
                    left: 40px;
                    width: 53px;
                    height: 53px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .knowledgeTitle{
                    font-size: 22px;
                    padding: 50px 0 10px 35px;
                    font-family: 'HuaKangFangYuan';

                }
                .knowledgeSubTitle{
                    font-size: 18px;
                    padding: 0 0 0 38px;
                    font-family: 'HuaKangFangYuan';
                    color: #666;
                    display: inline-block;
                    &::after {
                        content: '';
                        display: block;
                        position: relative;
                        left: 0;
                        bottom: -10px; /* 调整下划线位置，可根据实际情况调整 */
                        width: 100%; /* 下划线宽度 */
                        height: .10px; /* 下划线厚度 */
                        background-color: #c0c0c0;
                    }
                }
                .knowledgeContent{
                    font-size: 18px;
                    padding: 20px 80px 0 40px;
                }
            }
        }
        .cardContainer{
            margin-top: 60px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card{
                width: 300px;
                height: 400px;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .cardIcon{
                    width: 150px;
                    height: 150px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .cardName{
                    margin-top: 40px;
                }
                .cardContent{
                    padding: 0 40px;
                    margin-top: 30px;
                    color: #666;
                }
        }
        }
      .projectContainer{
        margin-bottom: 130px;
        .title-bar{
          margin-top: 60px;
          font-size: 24px;
          color: #fff;
          padding: 10px 0 10px 50px;
          border-radius: 50px;
          border: 3px solid #FFF;
          background: linear-gradient(90deg, #354AFF 0%, rgba(53, 74, 255, 0.00) 100%);
        }
        .mainContainer{
          width: 100%;
          margin-top: 120px;
          display: flex;
          justify-content: space-between;
          .pj-photo1{
            width: 580px;
            height: 380px;
            margin-left: 80px;
            background-image: url('./assets/project1.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
          .pj-photo2{
            width: 580px;
            height: 380px;
            margin-left: 80px;
            // background-image: url('./assets/project2.png');
            background-repeat: no-repeat;
            background-size: contain;
            }
        .introContainer{
          .intro{
            width: 400px;
            margin-top: 40px;
          }
          .pj-btn{
            text-decoration: none;
            color: #000;
            font-family: 'HuaKangFangYuan';
            font-size: 22px;
            width: 200px;
            height: 55px;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background-color: #FFB901;
            transition: background-color 0.3s;
            &:hover{
              background-color: rgba(255, 185, 1, 0.8);
            }
          }
        }
        }
      }
    }
    .endPage{
        text-align: center;
        position: relative;
        margin: 100px 0 ;
        padding: 5vh 0;
        font-family: 'HuaKangFangYuan';
        color: #999;
    }
  </style>