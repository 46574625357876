<template>
  <div class="page-container">
    <nav class="top-nav">
      <ul class="nav-list">
        <li class="nav-item" :class="{ active: activeTab === 'project' }" @click="setActiveTab('project')">
          <div class="projectPng PngContainer" :class="{ 'is-visible': activeTab === 'project' }"></div>
          <span class="nav-item-text">工作項目</span>
        </li>
        <li class="nav-item" :class="{ active: activeTab === 'practise' }" @click="setActiveTab('practise')">
          <div class="interestPng PngContainer" :class="{ 'is-visible': activeTab === 'practise' }"></div>
          <span class="nav-item-text">其他項目</span>
        </li>
      </ul>
    </nav>

    <!-- 卡片 -->
    <div class="card-grid">
      <router-link v-for="card in cards[activeTab]" :key="card.id" :to="card.link" class="card-link">
        <div class="card" :style="{ 
          transform: `scale(${hoveredCardId === card.id ? 1.3 : 1})`,
          opacity: !isAnyCardHovered || hoveredCardId === card.id ? 1:0.5
          }" 
          @mouseenter="hoveredCardId = card.id"
          @mouseleave="hoveredCardId = null">
        <!-- 卡片內容 -->
        <div class="cardHeader">
          <div class="cardHeaderAvator" :style="{ backgroundImage: 'url(' + card.photo + ')' }"></div>
          <p class="cardHeaderUp">{{card.title}}</p>
          <p class="cardHeaderDown">{{ card.subtitle }}</p>
          <hr class="hr-solid">
          <div class="card-body">
            <div class="card-subContainer">
              <p>{{ card.tag1 }}</p>
              <p>{{card.tag1A}}</p>
            </div>
            <div class="card-subContainer">
              <p>{{ card.tag2 }}</p>
              <p>{{card.tag2A}}</p>
            </div>
            <div class="card-subContainer">
              <p>項目狀態:</p>
              <p>{{card.date}}</p>
            </div>
            <button class="subscribe-btn">查看作品 ></button>
          </div>
        </div>
      </div>
      </router-link>
    </div>

        <!-- 左下角导航栏 -->
        <backBtn @hover-start="overlay=true" @hover-end="overlay= false" /> 
        <div class="overlay" :style="{opacity:overlay ? 1 : 0}"></div>
        <!-- Tips -->
        <div class="tips"></div>
  </div>
</template>

<script>
import backBtn from '@/components/backBtn/index.vue'

export default {
  components: {
      backBtn,
    },
  data() {
    return {
      overlay:false,
      activeTab: 'project',
      hoverScale:1,
      hoveredCardId: null, // 被悬浮的卡片的ID
      cards:{
        project: [
          {
            id: 1,
            rotation:0,
            hover: false,
            title:'創富PK爭霸賽',
            subtitle:'活動策劃',
            tag1:'角色:',
            tag1A:'負責人',
            tag2:'目的:',
            tag2A:'刺激交易增長',
            date:'已完成',
            photo:require('@/assets/cardPhoto/battlePng.png'),
            link: '/ProjectMenu/battleActivity'
          },
          {
            id: 2,
            rotation:0,
            hover: false,
            title:'交易神器',
            subtitle:'智能交易系統開發',
            tag1:'角色:',
            tag1A:'負責人',
            tag2:'目的:',
            tag2A:'提升交易效率',
            date:'已完成',
            photo:require('@/assets/cardPhoto/trading.png'),
            link: '/ProjectMenu/tradingSystem'
          },
          {
            id: 3,
            rotation:0,
            hover: false,
            title:'推廣工具項目',
            subtitle:'提升推廣效率',
            tag1:'角色:',
            tag1A:'負責人',
            tag2:'目的:',
            tag2A:'效率提升',
            date:'已完成',
            photo:require('@/assets/cardPhoto/tools.png'),
            link: '/ProjectMenu/promotionTools'
          },
          {
            id: 4,
            rotation:0,
            hover: false,
            title:'推廣數據項目',
            subtitle:'推動數據化運營',
            tag1:'角色:',
            tag1A:'負責人',
            tag2:'目的:',
            tag2A:'效率提升',
            date:'已完成',
            photo:require('@/assets/cardPhoto/dataPng.png'),
            link: '/ProjectMenu/DataVisualization'
          },
          {
            id: 5,
            rotation:0,
            hover: false,
            title:'RFM用戶分類模型',
            subtitle:'客戶價值分類及數據分析',
            tag1:'角色:',
            tag1A:'負責人',
            tag2:'目的:',
            tag2A:'客戶價值分類',
            date:'已完成',
            photo:require('@/assets/cardPhoto/RFM.png'),
            link: '/ProjectMenu/RFM'
          },
          // ...更多項目卡片
        ],
        practise: [
          {
            id: 1,
            title:'貸款違約預測',
            subtitle:'預測用戶貸款會否違約',
            tag1:'類型:',
            tag1A:'機器學習',
            tag2:'目的:',
            tag2A:'金融風控',
            date:'已完成',
            photo:require('@/assets/cardPhoto/risk.png'),
            link: '/ProjectMenu/loanDefaultForecast'
          },
          {
            id: 2,
            title:'二手車價格預估',
            subtitle:'二手車交易價格預測模型',
            tag1:'類型:',
            tag1A:'機器學習',
            tag2:'目的:',
            tag2A:'價格預測',
            date:'已完成',
            photo:require('@/assets/cardPhoto/SecondHandCar.png'),
            link: '/ProjectMenu/secondHandCarPrediction'
          },
          {
            id: 3,
            title:'淘寶用戶行為分析',
            subtitle:'基於AARRR漏斗模型分析',
            tag1:'類型:',
            tag1A:'數據分析',
            tag2:'目的:',
            tag2A:'行為分析',
            date:'已完成',
            photo:require('@/assets/cardPhoto/taobao.png'),
            link: '/ProjectMenu/taobaoAnalysis'
          },
          {
            id: 4,
            title:'A/B 測試 - 網站轉化率',
            subtitle:'落地頁設計對轉化率的影響',
            tag1:'類型:',
            tag1A:'數據分析',
            tag2:'目的:',
            tag2A:'決策評估',
            date:'已完成',
            photo:require('@/assets/cardPhoto/ABTest.png'),
            link: '/ProjectMenu/ABTest'
          },
        ]
      }
    };
  },
  computed: {
    isAnyCardHovered() {
      return this.hoveredCardId !== null;
    },
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
      sessionStorage.setItem('activeTab', tabName); // 保存選擇的標籤到 sessionStorage
    },
  },
  mounted() {
  // 頁面加載時從 sessionStorage 獲取之前保存的標籤
  const savedTab = sessionStorage.getItem('activeTab');
  if (savedTab) {
    this.activeTab = savedTab; // 設置為保存的標籤
  }
},
};
</script>

<style lang="less" scoped>
.page-container {
  width: 100vw; // 设置为视口宽度
  min-height: 100vh; /* 使用min-height而非height确保至少100%视口高度 */
  background-image: url('./assets/project-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  }
   // 導航欄
  .top-nav .nav-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  list-style: none;

    .nav-item {
      width: 35vh;
      height: 8vh;
      position: relative;
      background-color: #cdcdcd;
      color: #fff;
      padding: 1vh;
      margin: 8vh 4vh;
      border-radius: 1.5vh;
      font-size: 3vh;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease, padding-left 0.3s ease, transform 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2vh;
      z-index: 2;
      // 外部阴影
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19), inset 0 4px 4px rgba(255, 255, 255, 0.5), inset 0 -4px 4px rgba(0, 0, 0, 0.2);

      &.active {
        background-color: #fbea4f;
        color: #000000;
        padding-left: 10vh;
        padding-top: 0.5vh;
        transition: background-color 0.3s ease, color 0.3s ease, padding-left 0.3s ease, transform 0.3s ease;
        // 更深的外部阴影
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), inset 0 4px 4px rgba(255, 255, 255, 0.6), inset 0 -4px 4px rgba(0, 0, 0, 0.3);
      }
      .nav-item-text {
        position: relative;
        z-index: 3;
      }

      .PngContainer {
        position: absolute;
        top: -4.5vh;
        left: 2vh;
        width: 11vh;
        height: 11vh;
        transform: rotate(-20deg);
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease;
        z-index: 1;

        &.is-visible {
          opacity: 1;
        }

        &.projectPng {
          background-image: url('./assets/icon-project.png');
          background-size: cover;
        }

        &.interestPng {
          background-image: url('./assets/icon-interest.png');
          background-size: cover;
        }
      }
      
      &.active .PngContainer.projectPng, &.active .PngContainer.interestPng {
        opacity: 1;
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      &.active::after {
        content: '';
        display: block;
        position: absolute;
        top: 4.5vh;
        left: 17vh;
        height: 10vh;
        width: 10vh;
        background-image: url('./assets/xian.png');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
      }
    }
}

// 卡片主容器
.card-grid {
  display: flex;
  gap: 4vh; /* 卡片之间的间隔 */
  margin-top:8vh;
  justify-content: center;
}
// 卡片样式
.card {
  flex: 1; /* 每张卡片都有相同的灵活性 */
  height: 37vh;
  width: 25vh;
  background-color: rgba(255, 255, 255, 0.7); /* 设置半透明背景 */
  padding: 1.5vh;
  box-sizing: border-box;
  position: relative;
  margin-right: 38px;
  margin-top: 20px;
  border-radius: 20px;
  transition: transform 0.6s ease,opacity 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(8px); /* 增加模糊度以匹配您提供的效果 */
  -webkit-backdrop-filter: blur(8px); /* Safari浏览器兼容性 */

  /* 立体边框效果 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), /* 外阴影 */
              0 2px 6px rgba(0, 0, 0, 0.1), /* 更轻的内阴影 */
              inset 0 1px 2px rgba(255, 255, 255, 0.8), /* 顶部高光 */
              inset 0 -1px 2px rgba(0, 0, 0, 0.2); /* 底部反光 */

  /* 边框 */
  border: solid 3px #f5f5f5; /* 更柔和的边框颜色 */
  /* 确保边框不会干扰内部元素 */
  overflow: hidden;
  /* 用于创建更明显的立体感，模拟光线反射 */
  position: relative;
  z-index: 1;

  &:hover {
    // transform: scale(1.3); // 鼠标悬停时卡片放大
    // transform: scale(1.3);
    background-color: rgba(255, 255, 255, 0.7); // 鼠标悬停时卡片背景颜色淡色透明
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* 增加更大的阴影效果 */
    height: 44vh;

    .subscribe-btn{
      display: block;
    }
  }

}

.card-link {
  text-decoration: none;
}
.cardHeader{
  width: 100%;
  height: 8vh;
  /* margin: 10px 10px; */
  /* overflow: hidden; */
  /* display: flex; */
  /* justify-content: center; */
  .cardHeaderUp{
    margin: 1.5vh 0;
    font-size: 2vh;
    color: black;
  }
  .cardHeaderDown{
    font-size: 1.5vh;
    color: #666;
  }

  // 分割线
  .hr-solid {
    margin-top: 2vh;
    border: 0;
    border-top: 1px solid #a1a1ac;
}
}
.cardHeaderAvator {
  height: 14vh;
  width: 100%;
  border-radius: 1vh;
  background-repeat: no-repeat;
  background-size: contain; /* 确保图片完整地覆盖整个元素 */
  background-position: center; /* 图片居中显示 */
}

.card-body {
  padding: 0 0.5vh;
  text-align: center;
  .card-subContainer{
    display: flex;
    justify-content: space-between;
  }
  p{
    margin-top: 1.8vh;
    color: #666;
    font-size: 1.5vh;
  }
}

.subscribe-btn {
  // height: 3vh;
  // width: 8vh;
  margin: 2.5vh auto;
  padding: 1vh 2vh;
  border: none;
  border-radius: 1vh;
  background-color: #ff5252;
  color: white;
  cursor: pointer;
  display: none;
  transition: background-color 0.3s ease; /* 添加过渡效果 */
  font-size: 1.8vh;
}

.subscribe-btn:hover {
  background-color: #ff4242;
}

.tips{
  position: absolute;
  bottom: 3vh;
  right: 8vh;
  height: 10vh;
  width: 30vh;
  background-image: url('./assets/tips.png');
  background-repeat: no-repeat;
  background-size: contain;
}

// hover遮罩
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none; /*確保遮罩層不干擾下面的元素交互*/
}
</style>
